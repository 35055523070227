import { Dialog, Typography, Grid, CircularProgress } from '@mui/material';
import useSWR from 'swr';
import { CustomList } from '../../Customers/api';

const Details = ({ withdrawal_id, setId }) => {
    const { data, error } = useSWR(['/withdrawal-history-details', { withdrawal_id }]);

    return (
        <Dialog
            open={Boolean(withdrawal_id)}
            onClose={() => setId(null)}
            PaperProps={{ sx: { px: 0, pt: '10px', pb: '20px', maxWidth: '800px' } }}
        >
            {!data && !error && (
                <Grid container>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Typography fontSize="120%" pt={2} px={3}>
                        Details
                    </Typography>
                    {data?.status ? <CustomList object={data?.withdraw_history} lg={5} /> : <Grid container>No data</Grid>}
                    <Grid container alignItems="flex-start">
                        <Grid item xs={6}>
                            <Typography fontSize="120%" pt={2} px={3}>
                                Billing Detail
                            </Typography>
                            {Object.keys(data?.withdrawal_billing_details).length > 0 ? (
                                <CustomList object={data?.withdrawal_billing_details} columns={1} lg={5} />
                            ) : (
                                <Grid container>No data</Grid>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize="120%" pt={2} px={3}>
                                Bank Detail
                            </Typography>
                            {Object.keys(data?.withdrawal_bank_details).length > 0 ? (
                                <CustomList object={data?.withdrawal_bank_details} columns={1} lg={5} />
                            ) : (
                                <Grid container>No data</Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Dialog>
    );
};
export default Details;
