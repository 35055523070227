import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import useSWRInfinite from 'swr/infinite';
import Api, { fetcher } from '../../api';
import { getBrokersKey, LoadingSwitch } from './api';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Delete, Edit } from '../../files/icon';
import { useState } from 'react';
import BrokerDelete from './BrokerDelete';
import { toast } from 'react-toastify';
import ConnectBrokerDialog from './ConnectBrokerDialog';
import { usePermission } from '../common';

const Brokers = () => {
    const {
        state: { user_id }
    } = useLocation();
    const { data, size, setSize, isLoading, mutate } = useSWRInfinite((page) => getBrokersKey(page + 1, { user_id }), Api.post);

    const brokerList = data && data?.[0].data && [].concat(...data.map((pages) => pages.data.data));

    const [open, setOpen] = useState();
    const [editBroker, setEditBroker] = useState();
    const { customers_edit, customers_delete } = usePermission(['customers-edit', 'customers-delete']);

    const loadmore = () => {
        if (size + 1 <= data[data?.length - 1]?.data?.pagination.total_pages && !isLoadingMore) {
            setSize(size + 1);
        }
    };

    const scrollRef = useBottomScrollListener(() => loadmore(), {
        debounce: 200
    });

    const brokerStatusUpdate = async (data) => {
        const response = await fetcher('/broker-update-status', { ...data, user_id });
        if (response.status) {
            await mutate();
            toast.success(response.message);
        } else {
            toast.error(response.massage);
        }
    };

    const isLoadingMore = data?.length !== size && !isLoading;
    return (
        <Grid boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
            <Grid bgcolor="secondary.main" container justifyContent="space-between" p={'12px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Grid item xs="auto">
                    <Typography variant="h6">Brokers({data?.[data?.length - 1]?.data?.pagination?.total_items || 0})</Typography>
                </Grid>
                <Grid item xs></Grid>
                {customers_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setEditBroker(true)}>
                            Add
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer ref={scrollRef} sx={{ maxHeight: '240px', mb: '25px' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Client ID</TableCell>
                            <TableCell>Available Margin</TableCell>
                            <TableCell>Is Login</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Add Date/Time</TableCell>
                            <TableCell>Last Login Time</TableCell>
                            {(customers_delete || customers_edit) && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && brokerList?.length > 0 ? (
                            brokerList?.map((broker) => (
                                <TableRow key={broker.id}>
                                    <TableCell align="left">{broker.name}</TableCell>
                                    <TableCell>{broker.client_id}</TableCell>
                                    <TableCell>{broker.avaliable_balance}</TableCell>
                                    <TableCell>{broker.is_login}</TableCell>
                                    <TableCell>
                                        <LoadingSwitch
                                            checked={broker.status === 'True'}
                                            disabled={!customers_edit}
                                            onChange={(e) => brokerStatusUpdate({ broker_id: broker.id, status: e.target.checked })}
                                        />
                                    </TableCell>
                                    <TableCell>{broker.add_date}</TableCell>
                                    <TableCell>{broker.last_login}</TableCell>
                                    {(customers_delete || customers_edit) && (
                                        <TableCell>
                                            {customers_edit && (
                                                <IconButton disabled={broker.is_o_auth} onClick={() => setEditBroker(broker)}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {customers_delete && (
                                                <IconButton sx={{ ml: '5px' }} onClick={() => setOpen({ user_id, broker_id: broker.id })}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {open && <BrokerDelete data={open} onClose={() => setOpen(null)} mutate={mutate} />}
            {editBroker && (
                <ConnectBrokerDialog
                    broker={editBroker}
                    onClose={() => setEditBroker(null)}
                    connectedListMutation={mutate}
                    user_id={user_id}
                />
            )}
        </Grid>
    );
};

export default Brokers;
