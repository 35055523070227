import { Autocomplete, Button, Dialog, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getPlans, useGetBrokerlist } from './api';
import useSWR from 'swr';
import DatePicker from 'react-multi-date-picker';

const CustomersFilterDialog = ({ open, onCLose, setFilters, filters }) => {
    const { BrokerList, isLoading: brokersLoading } = useGetBrokerlist();
    const { data: plans, error: planError } = useSWR('/premium-membership-plans', getPlans);
    const { data: strategyList, error: strategyError } = useSWR('/admin-strategy-list');
    const [data, setData] = useState(filters);

    return (
        <Dialog open={open} onClose={onCLose} maxWidth="xs" PaperProps={{ sx: { minWidth: '350px', px: '35px' } }}>
            <Typography variant="h6" textAlign="center">
                Filters
            </Typography>
            <Grid item xs={5.5} pt={1.5}>
                <Typography variant="body2">Registration Date </Typography>
                <DatePicker
                    value={data?.dates}
                    range
                    format="DD/MM/YYYY"
                    onChange={(e) => setData((prev) => ({ ...prev, dates: e.map((e) => e.format()) }))}
                    containerStyle={{ width: '100%', background: 'transparent' }}
                    style={{
                        width: '100%',
                        height: '35px',
                        border: '2px solid rgba(115, 103, 240, 0.5)',
                        background: 'rgba(115, 103, 240, 0.06)',
                        color: '#ffffff',
                        fontFamily: 'Givonic',
                        fontSize: '100%'
                    }}
                    mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                        const props = { style: { color: 'white' } };
                        if (isSameDate(today, date)) {
                            props.style = {
                                ...props.style,
                                backgroundColor: '#283046',
                                border: '2px solid rgba(115, 103, 240, 0.5)'
                            };
                        }
                        if (isSameDate(date, selectedDate))
                            props.style = {
                                ...props.style,
                                backgroundColor: '#7367F0'
                            };
                        return props;
                    }}
                    className="custom-calendar"
                    calendarPosition="bottom-center"
                    fixRelativePosition
                />
            </Grid>
            <Grid item xs={5.5} pt={1.5}>
                <Typography variant="body2">Strategy</Typography>
                <Autocomplete
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    onChange={(e, v) => setData((prev) => ({ ...prev, user_strategy: v }))}
                    value={data?.user_strategy}
                    options={strategyList?.data?.map((strategy) => strategy.name) || []}
                    loading={!strategyList && !strategyError}
                />
            </Grid>
            <Grid item xs={5.5} pt={1.5}>
                <Typography variant="body2">Mode</Typography>
                <Autocomplete
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    onChange={(e, v) => {
                        setData((prev) => ({ ...prev, mode: v }));
                    }}
                    value={data?.mode}
                    options={['live', 'paper']}
                />
            </Grid>
            <Grid item xs={5.5} pt={1.5}>
                <Typography variant="body2">Status</Typography>
                <Autocomplete
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    onChange={(e, v) => {
                        setData((prev) => ({ ...prev, user_status: v }));
                    }}
                    value={data?.user_status}
                    options={[true, false]}
                    getOptionLabel={(ot) => (ot ? 'Yes' : 'No')}
                />
            </Grid>
            <Grid item xs={5.5} pt={1.5}>
                <Typography variant="body2">Membership</Typography>
                <Autocomplete
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    onChange={(e, v) => setData((prev) => ({ ...prev, user_membership: v }))}
                    isOptionEqualToValue={(option, value) => option.name === value.name || option.value === value.value}
                    value={data?.user_membership}
                    options={plans?.data?.map((plan) => plan?.title).concat('Free') || []}
                    loading={!plans && !planError}
                />
            </Grid>
            <Grid item xs={5.5} pt={1.5}>
                <Typography variant="body2">Broker</Typography>
                <Autocomplete
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    onChange={(e, v) => {
                        setData((prev) => ({ ...prev, user_broker: v }));
                    }}
                    value={data.user_broker}
                    options={BrokerList?.data || []}
                    loading={brokersLoading}
                    getOptionLabel={(option) => option.name}
                />
            </Grid>

            <Grid container justifyContent="space-between" spacing={2} pt={3.5}>
                <Grid item xs={6}>
                    <Button
                        variant="half"
                        onClick={() => {
                            setFilters({});
                            onCLose();
                        }}
                    >
                        Reset
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={() => {
                            setFilters({ ...data, current_page: 1 });
                            onCLose();
                        }}
                    >
                        Apply Filter
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default CustomersFilterDialog;
