import { Button, Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    clearClosedFilter,
    clearOpenFilter,
    clearOrderbookFilter,
    clearReferralFilter,
    clearSubscriptionFilter,
    clearTradebookFilter,
    clearTradeFilter,
    clearWithdrawFilter
} from '../../store/filter';
import { usePermission } from '../common';

const buttonStyle = {
    background: 'rgba(115, 103, 240, 0.2)',
    border: '2px solid #7367F0',
    borderRadius: '5px'
};

const Navigation = () => {
    const {
        state: { customer_name }
    } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const permission = usePermission([
        'orderbook-view',
        'open-position-view',
        'tradebook-view',
        'transaction-subscriptions-view',
        'transaction-trade-view',
        'transaction-referral-view',
        'transaction-withdraw-view',
        'closed-position-view'
    ]);
    return (
        <Grid container>
            {(permission.orderbook_view ||
                permission.open_position_view ||
                permission.tradebook_view ||
                permission.closed_position_view) && (
                <Grid
                    item
                    xs={5}
                    container
                    columnGap="16px"
                    bgcolor="secondary.main"
                    p="12px"
                    sx={{ borderRadius: '5px', mb: '16px', mr: '16px' }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">Trade</Typography>
                    </Grid>
                    {permission.orderbook_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearOrderbookFilter({ search: customer_name }));
                                    navigate('/Trade/Orders');
                                }}
                            >
                                Orders
                            </Button>
                        </Grid>
                    )}
                    {permission.open_position_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearOpenFilter({ search: customer_name }));
                                    navigate('/Trade/Open');
                                }}
                            >
                                Open
                            </Button>
                        </Grid>
                    )}
                    {permission.closed_position_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearClosedFilter({ search: customer_name }));
                                    navigate('/Trade/Closed');
                                }}
                            >
                                Closed
                            </Button>
                        </Grid>
                    )}
                    {permission.tradebook_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearTradebookFilter({ search: customer_name }));
                                    navigate('/Trade/TradeHistory');
                                }}
                            >
                                Trade History
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            {(permission.transaction_subscriptions_view ||
                permission.transaction_trade_view ||
                permission.transaction_referral_view ||
                permission.transaction_withdraw_view) && (
                <Grid
                    item
                    xs
                    container
                    justifyContent="space-evenly"
                    bgcolor="secondary.main"
                    p="12px"
                    columnGap="16px"
                    sx={{ borderRadius: '5px', mb: '16px' }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">Transactions</Typography>
                    </Grid>
                    {permission.transaction_subscriptions_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearSubscriptionFilter({ search: customer_name }));
                                    navigate('/Transaction/Subscriptions');
                                }}
                            >
                                Subscription
                            </Button>
                        </Grid>
                    )}
                    {permission.transaction_trade_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearTradeFilter({ search: customer_name, payment: '', date_time: '' }));
                                    navigate('/Transaction/Trades');
                                }}
                            >
                                Trade
                            </Button>
                        </Grid>
                    )}
                    {permission.transaction_referral_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearReferralFilter({ search: customer_name }));
                                    navigate('/Transaction/Referrals');
                                }}
                            >
                                Referrals
                            </Button>
                        </Grid>
                    )}
                    {permission.transaction_withdraw_view && (
                        <Grid item xs pt="12px">
                            <Button
                                sx={buttonStyle}
                                onClick={() => {
                                    dispatch(clearWithdrawFilter({ search: customer_name }));
                                    navigate('/Transaction/Withdraw');
                                }}
                            >
                                Withdraw
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};
export default Navigation;
