import { CircularProgress, Grid, Typography } from '@mui/material';
import useSWR from 'swr';
import Extraform from './Extraform';

const Extra = () => {
    const { data, mutate } = useSWR('/extra-settings-info');

    const getRadio = (key) => {
        switch (key) {
            case 'version_control_system':
                return ['Force Update'];
            case 'websocket':
                return ['WS Secaured'];
            case 'miscellaneous':
                return ['App In Review', 'Maintenance Mode'];
            default:
                return [];
        }
    };

    const getURL = (key) => {
        switch (key) {
            case 'version_control_system':
                return '/version-control-settings-update';
            case 'websocket':
                return '/websocket-settings-update';
            case 'miscellaneous':
                return '/miscellaneous-settings-update';
            default:
                return [];
        }
    };

    return (
        <Grid
            item
            container
            xs={12}
            bgcolor="secondary.main"
            borderRadius="5px"
            pt="10px"
            mt="25px"
            boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
        >
            <Grid item xs pl="10px" pb="10px">
                <Typography variant="h6">Extra</Typography>
            </Grid>
            <Grid item xs={12} px="10px" pb="10px">
                {data ? (
                    Object.keys(data?.data)?.map((key, index) => (
                        <Extraform
                            data={data?.data?.[key]}
                            title={key.replaceAll('_', ' ')}
                            radio={getRadio(key)}
                            url={getURL(key)}
                            mutate={mutate}
                        />
                    ))
                ) : (
                    <Grid container>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
export default Extra;
