import { MenuItem } from '@mui/material';
import useSWR from 'swr';

const StrategyFilter = ({ setFilter, Filter, setAnchorEl }) => {
    const { data: strategyList } = useSWR('/admin-strategy-list', { revalidateIfStale: false });
    return (
        <>
            {strategyList ? (
                strategyList?.data
                    ?.concat({
                        id: '123',
                        name: '',
                        code: ''
                    })
                    .map((strategy) => (
                        <>
                            <MenuItem
                                divider={strategy.name !== ''}
                                key={strategy.id}
                                value={strategy.name}
                                disabled={Filter?.strategy_name === strategy.name}
                                onClick={() => {
                                    setFilter({ strategy_name: strategy.name });
                                    setAnchorEl({ date: null, Strategy: null, status: null });
                                }}
                                sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
                            >
                                {strategy.name === '' ? 'All' : strategy.name}
                            </MenuItem>
                        </>
                    ))
            ) : (
                <MenuItem
                    sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
                    disabled={Filter?.strategy_name === 'All'}
                    onClick={() => {
                        setFilter({ strategy_name: 'All' });
                        setAnchorEl({ date: null, Strategy: null, status: null });
                    }}
                >
                    All
                </MenuItem>
            )}
        </>
    );
};
export default StrategyFilter;
