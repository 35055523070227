import { Dialog, Typography, Grid, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import Api from '../../../api';
import { usePermission } from '../../common';
import Button from '../../common/Button';
import { CustomList } from '../../Customers/api';

const Details = ({ invoice_id, setId }) => {
    const { data, error } = useSWR(['/get-invoice-detail', { invoice_id }]);
    const { transaction_invoice_edit } = usePermission(['transaction-invoice-edit']);

    const onDownload = async () => {
        try {
            const response = await Api.post(
                '/download-invoices',
                { invoice_ids: [invoice_id] },
                {
                    responseType: 'arraybuffer'
                }
            );

            const date = new Date();

            const outputFilename = `Invoices ${date.toLocaleString('en-IN')}.pdf`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <Dialog open={Boolean(invoice_id)} onClose={() => setId(null)} PaperProps={{ sx: { padding: 0, pt: 3, pb: 4, maxWidth: '850px' } }}>
            {!data && !error && (
                <Grid container>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Grid container justifyContent="space-between" px={3}>
                        <Grid item xs="auto">
                            <Typography fontSize="120%">{data.customer}</Typography>
                        </Grid>
                        {transaction_invoice_edit && (
                            <Grid item xs="auto">
                                <Button onClick={onDownload}>Invoice</Button>
                            </Grid>
                        )}
                    </Grid>
                    {data?.status ? (
                        <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                            <CustomList object={data?.data?.invoice_data} />
                        </Grid>
                    ) : (
                        <Grid container>No data</Grid>
                    )}
                    <Grid container alignItems="flex-start">
                        <Grid item xs={6}>
                            <Typography fontSize="120%" pt={2} px={3}>
                                Billing Detail
                            </Typography>
                            {Object.keys(data?.data?.billing_detail).length > 0 ? (
                                <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                                    <CustomList object={data?.data?.billing_detail} columns={1} />
                                </Grid>
                            ) : (
                                <Grid container>No data</Grid>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize="120%" pt={2} px="15px">
                                Bank Detail
                            </Typography>
                            {Object.keys(data?.data?.bank_data).length > 0 ? (
                                <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                                    <CustomList object={data?.data?.bank_data} columns={1} pl="15px" lg={5} />
                                </Grid>
                            ) : (
                                <Grid container>No data</Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Dialog>
    );
};
export default Details;
