import { Dialog, Typography, Grid, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { Details as Dtail, EditText } from '../../../files/icon';
import { clearTradebookFilter } from '../../../store/filter';
import { usePermission } from '../../common';
import Button from '../../common/Button';
import { CustomList } from '../../Customers/api';
import EditPayment from './EditPayment';

// const sevenDayBack = (Current) => {
//     const arr = Current.split('-');
//     const days = 7; // Days you want to subtract
//     const date = new Date(arr[2], arr[1] - 1, arr[0]);
//     const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
//     const day = last.getDate();
//     const month = last.getMonth() + 1;
//     const year = last.getFullYear();

//     return `${day}-${month}-${year}`;
// };

const Details = ({ trade_history_id, setId }) => {
    const [editPay, setEditPay] = useState();
    const { data, error, mutate } = useSWR(['/trade-history-details', { trade_history_id }]);
    const { transaction_trade_edit, tradebook_view } = usePermission(['transaction-trade-edit', 'tradebook-view']);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    return (
        <Dialog
            open={Boolean(trade_history_id)}
            onClose={() => setId(null)}
            PaperProps={{ sx: { padding: 0, pt: 3, pb: 4, maxWidth: '800px', width: '800px' } }}
        >
            {!data && !error && (
                <Grid container>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Grid container columnGap="15px" px="30px" pb="10px">
                        <Grid item xs="auto">
                            <Typography fontSize="120%">{data.customer_name}</Typography>
                        </Grid>
                        <Grid item xs></Grid>
                        {transaction_trade_edit && (
                            <Grid item xs="auto">
                                <Button
                                    onClick={() =>
                                        setEditPay({
                                            trading_id: data?.id,
                                            user_id: data?.user_id,
                                            payment_date: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
                                            payment_mode: data?.data?.transaction_detail?.payment_mode || '',
                                            payment_status: data?.data?.trading_history_data?.payment_status || '',
                                            payment: data?.data?.trading_history_data?.payment || '',
                                            due_date: ''
                                        })
                                    }
                                >
                                    <EditText />
                                </Button>
                            </Grid>
                        )}
                        {tradebook_view && (
                            <Grid item xs="auto">
                                <Button
                                    onClick={() => {
                                        dispatch(
                                            clearTradebookFilter({
                                                search: data?.data?.trading_history_data?.customer_phone,
                                                dates_from: data?.data?.trading_history_data?.start_date,
                                                dates_to: data?.data?.trading_history_data?.end_date,
                                                date_time: 'Custom'
                                            })
                                        );
                                        navigate('/Trade/TradeHistory');
                                    }}
                                    startIcon={<Dtail />}
                                >
                                    Details
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {data?.status ? <CustomList object={data?.data?.trading_history_data} lg={5} /> : <Grid container>No data</Grid>}
                    <Typography fontSize="120%" px="30px" pt="15px">
                        Transaction Detail
                    </Typography>
                    {Object.keys(data?.data?.transaction_detail).length > 0 &&
                    data?.data?.trading_history_data.payment_status === 'paid' &&
                    Number(data?.data?.trading_history_data?.payment) > 0 ? (
                        <CustomList object={data?.data?.transaction_detail} lg={5} />
                    ) : (
                        <Grid container>No data</Grid>
                    )}
                </>
            )}
            {editPay && <EditPayment data={editPay} onClose={() => setEditPay()} mutate={mutate} />}
        </Dialog>
    );
};
export default Details;
