import { Dialog, Grid, Typography } from '@mui/material';
import { Warn } from '../../files/icon';
import Button from '../common/Button';

const ConfirmStatus = ({ data, onClose, setValue }) => {
    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px' } }}>
            <Grid container>
                <Warn />
            </Grid>
            <Grid item xs={12} py="10px">
                <Typography textAlign="center">Are you sure?</Typography>
                <Typography textAlign="center" variant="body2" color="text.secondary">
                    You Want To {data.status ? 'Active' : 'Deactive'} This Strategy
                </Typography>
            </Grid>
            <Grid container columnGap="15px">
                <Grid item xs="auto">
                    <Button onClick={setValue} sx={{ px: '15px' }}>
                        Yes
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button variant="outlined" color="error" onClick={onClose}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};
export default ConfirmStatus;
