import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, Grid, Input, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { fetcher } from '../../api';
import AsyncForm from '../common/AsyncForm';

const RewardsEdit = ({ data, onClose, mutate }) => {
    const schema = yup.object().shape({
        ...Object.keys(data).reduce(
            (acc, key) => ({
                ...acc,
                [key]: yup.string().required(`${key} is required`)
            }),
            {}
        )
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({ mode: 'onTouched', resolver: yupResolver(schema), defaultValues: data });

    const onSubmit = async (data) => {
        const response = await fetcher('/rewards-update', data);
        if (response.status) {
            toast.success(response.message);
            onClose();
            mutate();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} maxWidth="xs" PaperProps={{ sx: { maxWidth: '300px', minWidth: '350px' } }}>
            <Typography textAlign="center" variant="h6">
                {Object.values(data).filter((key) => key?.length > 0).length > 0 ? 'Edit' : 'Add'} Rewards
            </Typography>
            <AsyncForm onSubmit={handleSubmit(onSubmit)} gridProps={{ px: 3 }}>
                {Object.keys(data).map((key) => (
                    <Grid key={key}>
                        <Typography pt={2} textTransform="capitalize">
                            {key.replaceAll('_', ' ')}
                        </Typography>
                        <Input {...register(key)} />
                        <Typography variant="body2" color="error">
                            {errors?.[key]?.message}
                        </Typography>
                    </Grid>
                ))}
            </AsyncForm>
        </Dialog>
    );
};
export default RewardsEdit;
