import { Autocomplete, Grid, Input, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { fetcher } from '../../api';
import Button from '../common/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const BankDetails = ({ edit, mutate, setEdit }) => {
    const {
        state: { user_id }
    } = useLocation();

    const schema = yup.object().shape({
        account_no: yup.string().required('Enter your AC no.'),
        bank_name: yup.string().required('Please, select A bank').nullable(),
        account_name: yup.string().required('Enter your AC name.'),
        account_type: yup.string().required('Please, select AC type').nullable(),
        ifsc_code: yup.string().required('Enter your IFSC code')
    });
    const { data: bankList, error: banklistError } = useSWR('/bank-list', {
        revalidateOnFocus: false,
        revalidateIfStale: false
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            ...Object.keys(edit?.bank_details).reduce((acc, cv) => {
                return { ...acc, [cv]: edit?.bank_details?.[cv] || '' };
            }, {})
        }
    });

    const addBankDetail = async (data) => {
        const response = await fetcher('/add-bank-details', { ...data, user_id });

        if (response.status) {
            toast.success(response.message);
            mutate();
            setEdit(null);
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Grid container columnSpacing="15px" px="15px" justifyContent="flex-start" alignItems="flex-start">
            {Object.keys(edit?.bank_details).map((key) => {
                if (key === 'account_type') {
                    return (
                        <Grid item xs={6} minHeight="65px" key={key}>
                            <Typography pt={1} textTransform="capitalize" variant="body2" color="text.secondary">
                                {key.replaceAll('_', ' ')}
                            </Typography>
                            <Controller
                                name={key}
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Autocomplete
                                        renderInput={(params) => (
                                            <TextField {...params} fullWidth error={Boolean(errors?.[key]?.message)} />
                                        )}
                                        onChange={(e, v) => {
                                            onChange(v);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        options={['Saving', 'Current']}
                                    />
                                )}
                            />
                            <Typography variant="body2" color="error">
                                {errors?.[key]?.message}
                            </Typography>
                        </Grid>
                    );
                } else if (key === 'bank_name') {
                    return (
                        <Grid item xs={6} minHeight="65px" key={key}>
                            <Typography pt={1} textTransform="capitalize" variant="body2" color="text.secondary">
                                {key.replaceAll('_', ' ')}
                            </Typography>
                            <Controller
                                name={key}
                                control={control}
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <Autocomplete
                                        renderInput={(params) => (
                                            <TextField {...params} fullWidth error={Boolean(errors?.[key]?.message)} />
                                        )}
                                        onChange={(e, v) => {
                                            onChange(v);
                                        }}
                                        onBlur={onBlur}
                                        value={value}
                                        loading={!bankList && !banklistError}
                                        options={bankList?.data?.map((bank) => bank.name) || []}
                                    />
                                )}
                            />
                            <Typography variant="body2" color="error">
                                {errors?.[key]?.message}
                            </Typography>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid item xs={6} minHeight="65px" key={key}>
                            <Typography pt={1} textTransform="capitalize" variant="body2" color="text.secondary">
                                {key.replaceAll('_', ' ')}
                            </Typography>
                            <Input {...register(key)} error={Boolean(errors?.[key]?.message)} />
                            <Typography variant="body2" color="error">
                                {errors?.[key]?.message}
                            </Typography>
                        </Grid>
                    );
                }
            })}
            <Grid item container pt="10px" xs={12}>
                <Button onClick={handleSubmit(addBankDetail)} sx={{ width: '20%' }}>
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};
export default BankDetails;
