import { Dialog, Grid, Typography } from '@mui/material';
import Button from '../common/Button';
import { toast } from 'react-toastify';
import { fetcher } from '../../api';
import { Warn } from '../../files/icon';

const BrokerDelete = ({ data, onClose, mutate }) => {
    const deleteBroker = async () => {
        const response = await fetcher('customers-broker-update', data);

        if (response.status) {
            mutate();
            toast(response.message);
            onClose();
        } else {
            toast.error(response.message);
        }
    };
    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px' } }}>
            <Grid container>
                <Warn />
            </Grid>
            <Grid item xs={12} py="10px">
                <Typography textAlign="center">Are you sure?</Typography>
                <Typography textAlign="center" variant="body2" color="text.secondary">
                    You Want To Delete This Broker!
                </Typography>
            </Grid>
            <Grid container columnGap="15px">
                <Grid item xs="auto">
                    <Button onClick={deleteBroker} sx={{ px: '15px' }}>
                        Yes, delete it!
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button variant="outlined" color="error" onClick={onClose}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};
export default BrokerDelete;
