import { Dialog, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { fetcher } from '../../../api';
import { Warn } from '../../../files/icon';
import Button from '../../common/Button';

const PlanDelete = ({ data, onClose, mutate, name, url }) => {
    const deleteBroker = async () => {
        const response = await fetcher(url, data);

        if (response.status) {
            mutate();
            toast(response.message);
            onClose();
        } else {
            toast.error(response.message);
        }
    };
    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px' } }}>
            <Grid container>
                <Warn />
            </Grid>
            <Grid item xs={12} py="10px">
                <Typography textAlign="center">Are you sure?</Typography>
                <Typography textAlign="center" variant="body2" color="text.secondary">
                    You Want To Delete This {name}!
                </Typography>
            </Grid>
            <Grid container columnGap="15px">
                <Grid item xs="auto">
                    <Button onClick={deleteBroker} sx={{ px: '15px' }}>
                        Yes, delete it!
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button variant="outlined" color="error" onClick={onClose}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};
export default PlanDelete;
