import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Edit, Expand } from '../../files/icon';
import { usePermission } from '../common';
import { CustomList } from '../Customers/api';
import PaymentGatewayEdit from './PaymentGatewayEdit';

const PaymentGateway = () => {
    const [edit, setEdit] = useState();
    const [expanded, setExpanded] = useState(0);
    const { data, mutate } = useSWR('/payment-gateway-info');
    const { setting_edit } = usePermission(['setting-edit']);
    const obj = (data) => {
        return {
            Name: data?.payment_gateway_name,
            Channel_ID_for_website: data?.channel_id_for_website,
            Website: data?.website,
            Credit_Card: data?.is_creditcard,
            Mode: data?.payment_gateway_mode,
            Industry_Type: data?.industry_type,
            Debit_Card: data?.is_debit_card,
            Merchant_ID: data?.merchant_id,
            Channel_ID_for_mobile: data?.channel_id_for_mobile_app,
            Net_Banking: data?.is_netbanking,
            Merchant_Key: data?.merchant_key,
            Status: data?.status,
            UPI: data?.is_upi,
            Wallet: data?.is_wallet
        };
    };

    return (
        <Grid
            item
            xs={12}
            bgcolor="secondary.main"
            my={'25px'}
            sx={{ borderRadius: '5px' }}
            container
            justifyContent="space-evenly"
            alignItems="flex-start"
            p="12px"
            boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
        >
            <Grid item xs={12} container justifyContent="space-between">
                <Typography variant="h6">Payment Gateway</Typography>
                {setting_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setEdit(true)}>
                            ADD
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                {data ? (
                    data?.data?.map((payment, index) => (
                        <Accordion
                            sx={{ pt: '12px' }}
                            onChange={() => setExpanded((prev) => (prev === index ? '' : index))}
                            expanded={expanded === index}
                            key={index}
                        >
                            <AccordionSummary expandIcon={<Expand />}>
                                <Typography fontSize="110%" textTransform="capitalize">
                                    {payment.payment_gateway_name}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container justifyContent="space-evenly" alignItems="flex-start">
                                    <CustomList object={obj(payment)} columns={3} lg={4.5} />
                                </Grid>
                                {setting_edit && (
                                    <IconButton
                                        sx={{
                                            position: 'absolute',
                                            bottom: 10,
                                            right: 10,
                                            width: '45px',
                                            height: '45px',
                                            background: '#46468A',
                                            '&:hover': {
                                                background: '#46468A'
                                            }
                                        }}
                                        onClick={() => setEdit(payment)}
                                    >
                                        <Edit />
                                    </IconButton>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <CircularProgress />
                )}
                {edit && <PaymentGatewayEdit data={edit} onClose={() => setEdit(null)} mutate={mutate} />}
            </Grid>
        </Grid>
    );
};
export default PaymentGateway;
