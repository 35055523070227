import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, Grid, Input, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { fetcher } from '../../api';
import AsyncForm from '../common/AsyncForm';

const TaxInfoEdit = ({ data, onClose, mutate }) => {
    const schema = yup.object().shape({
        ...Object.keys(data?.data).reduce(
            (acc, key) => ({
                ...acc,
                [key]: yup.string().required(`${key} is required`)
            }),
            {}
        )
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({ mode: 'onTouched', resolver: yupResolver(schema), defaultValues: data.data });

    const onSubmit = async (data) => {
        const response = await fetcher('/update-taxinfo', data);
        if (response.status) {
            toast.success(response.message);
            onClose();
            mutate();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} maxWidth="xs" PaperProps={{ sx: { maxWidth: '300px', minWidth: '350px' } }}>
            <Typography textAlign="center" variant="h6">
                {data?.name}
            </Typography>
            <AsyncForm onSubmit={handleSubmit(onSubmit)} gridProps={{ px: 3 }}>
                {Object.keys(data?.data).map((key) => (
                    <Grid key={key} minHeight="70px">
                        <Typography textTransform="capitalize">{key}</Typography>
                        <Input {...register(key)} />
                        <Typography variant="body2" color="error">
                            {errors?.[key]?.message}
                        </Typography>
                    </Grid>
                ))}
            </AsyncForm>
        </Dialog>
    );
};
export default TaxInfoEdit;
