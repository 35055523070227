import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Dialog, FormControlLabel, Grid, Input, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Api from '../../api';
import Button from '../common/Button';

const StatusEditDialog = ({ order_book_id, onClose, mutate }) => {
    const schema = yup.object().shape({
        status: yup.string().required(),
        reason: yup
            .string()
            .when('status', { is: (status) => status === 'rejected', then: yup.string().required(), otherwise: yup.string() }),
        price: yup
            .string()
            .when('status', { is: (status) => status === 'complete', then: yup.string().required(), otherwise: yup.string() }),
        datetime: yup
            .string()
            .when('status', { is: (status) => status === 'complete', then: yup.string().required(), otherwise: yup.string() })
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: { datetime: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`, send_notification: 'Yes' }
    });

    const updateStatus = async (data) => {
        const { datetime, ...rest } = data;
        const response = await Api.post('orders-book-status-update', { ...rest, datetime: datetime.replaceAll('/', '-'), order_book_id });

        if (response.data.status) {
            toast(response.data.message);
            mutate();
            onClose();
        } else {
            toast(response.data.message);
        }
    };

    return (
        <Dialog open={Boolean(order_book_id)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px' } }}>
            <Typography textAlign={'center'} fontSize={'120%'}>
                Edit Orderbook
            </Typography>
            <Grid px={2}>
                <Grid height="75px">
                    <Typography>Price</Typography>
                    <Input type="number" {...register('price')} />
                    <Typography color="error.main" variant="body2">
                        {errors?.price?.message}
                    </Typography>
                </Grid>
                <Grid height="75px">
                    <Typography>Date / Time</Typography>
                    <Controller
                        control={control}
                        name={'datetime'}
                        render={({ field: { onChange, name, value } }) => (
                            <>
                                <DatePicker
                                    multiple={false}
                                    name={name}
                                    value={value}
                                    format="DD/MM/YYYY HH:mm:ss"
                                    onChange={(date) => {
                                        onChange({ target: { name: name, value: date.format() } });
                                    }}
                                    containerStyle={{ width: '100%', background: 'transparent' }}
                                    style={{
                                        width: '100%',
                                        height: '35px',
                                        border: '2px solid rgba(115, 103, 240, 0.5)',
                                        background: 'rgba(115, 103, 240, 0.06)',
                                        color: '#ffffff',
                                        fontFamily: 'Givonic',
                                        fontSize: '100%'
                                    }}
                                    className="custom-calendar"
                                    mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                        const props = { style: { color: '#d0d2d6' } };
                                        if (isSameDate(today, date)) {
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#283046',
                                                border: '2px solid rgba(115, 103, 240, 0.5)',
                                                background: 'rgba(115, 103, 240, 0.06)'
                                            };
                                        }
                                        if (isSameDate(date, selectedDate))
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#7367F0'
                                            };
                                        return props;
                                    }}
                                    calendarPosition="bottom-center"
                                    plugins={[<TimePicker position="right" />]}
                                />
                            </>
                        )}
                    />
                    <Typography color="error.main" variant="body2">
                        {errors?.datetime?.message}
                    </Typography>
                </Grid>
                <Grid height="75px">
                    <Typography>Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                onChange={(e, v) => onChange(v)}
                                onBlur={onBlur}
                                value={value}
                                options={['complete', 'rejected']}
                            />
                        )}
                    />
                    <Typography color="error.main" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
                <Grid height="95px">
                    <Typography>Reason</Typography>
                    <Input multiline minRows={2} {...register('reason')} />
                    <Typography color="error.main" variant="body2">
                        {errors?.reason?.message}
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item xs="auto">
                        <Typography textTransform="capitalize" variant="body2" color="text.secondary">
                            Send Notification
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Controller
                            control={control}
                            name={'send_notification'}
                            render={({ field }) => (
                                <RadioGroup row {...field} defaultValue="Yes">
                                    <FormControlLabel value="Yes" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio size="small" />} label="No" />
                                </RadioGroup>
                            )}
                        />
                    </Grid>
                </Grid>
                <Button onClick={handleSubmit(updateStatus)} sx={{ display: 'block', width: 'min-content', margin: '20px auto 0px' }}>
                    Update
                </Button>
            </Grid>
        </Dialog>
    );
};
export default StatusEditDialog;
