import { MenuItem } from '@mui/material';

const StatusFilter = ({ setFilter, Filter, setAnchorEl }) => {
    return (
        <>
            <MenuItem
                divider
                disabled={Filter?.status === ''}
                onClick={() => {
                    setFilter({ status: '' });
                    setAnchorEl({ date: null, Strategy: null, status: null });
                }}
                sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
            >
                All
            </MenuItem>
            <MenuItem
                divider
                disabled={Filter?.status === 'complete'}
                onClick={() => {
                    setFilter({ status: 'complete' });
                    setAnchorEl({ date: null, Strategy: null, status: null });
                }}
                sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
            >
                Complete
            </MenuItem>
            <MenuItem
                disabled={Filter?.status === 'rejected'}
                onClick={() => {
                    setFilter({ status: 'rejected' });
                    setAnchorEl({ date: null, Strategy: null, status: null });
                }}
                sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
            >
                Rejected
            </MenuItem>
        </>
    );
};
export default StatusFilter;
