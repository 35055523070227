import { CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Edit } from '../../files/icon';
import { usePermission } from '../common';
import SocketEdit from './SocketEdit';
import Button from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { fetcher } from '../../api';
import { toast } from 'react-toastify';
import { update } from '../../store/reducer';
import qs from 'query-string';

const Socket = () => {
    const [edit, setEdit] = useState();
    const { data, mutate } = useSWR('/socket-info');
    const { data: brokers } = useSWR('/broker-list');
    const { setting_edit } = usePermission(['setting-edit']);
    const dispatch = useDispatch();
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    return (
        <Grid item container xs bgcolor="secondary.main" borderRadius="5px" pt="10px" boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
            <Grid item xs pl="10px" pb="10px">
                <Typography variant="h6">Socket</Typography>
            </Grid>
            {setting_edit && (
                <Grid item xs="auto" pr="10px" pb="10px">
                    <Button sx={{ py: '2px' }} onClick={() => setEdit(true)}>
                        ADD
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                {data ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Broker Name</TableCell>
                                <TableCell>Client ID</TableCell>
                                <TableCell>API Key</TableCell>
                                <TableCell>Login</TableCell>
                                {setting_edit && <TableCell>Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.socket_data.map((socket) => (
                                <TableRow key={socket?.socket_id}>
                                    <TableCell sx={{ textTransform: 'capitalise' }}>{socket?.broker_name}</TableCell>
                                    <TableCell>{socket?.client_id}</TableCell>
                                    <TableCell>{socket?.api_key}</TableCell>
                                    <TableCell>
                                        <Button
                                            fullWidth={false}
                                            sx={{ p: '0px' }}
                                            onClick={async () => {
                                                const broker = await fetcher('/socket-broker-detail-oauth', {
                                                    broker_id: socket.broker_id
                                                });

                                                if (socket.oauth) {
                                                    const query = broker.data.broker_query_params.reduce(
                                                        (acc, key) => ({
                                                            ...acc,
                                                            ...(key.is_need_to_send_in_webview_url && {
                                                                [key.key_name]: key.key_value
                                                            })
                                                        }),
                                                        { broker_id: broker.data.id, user_id }
                                                    );

                                                    setTimeout(() => {
                                                        dispatch(update({ broker_id: query }));
                                                    }, 0);

                                                    const urlData = qs.parseUrl(broker.data.redirect_url);

                                                    const link = document.createElement('a');
                                                    link.href = `${urlData.url}?${qs.stringify({ ...urlData.query, ...query })}`;
                                                    link.click();
                                                } else {
                                                    const response = await fetcher('/socket-update', {
                                                        broker_id: broker.data.id,
                                                        ...broker.data.broker_query_params.reduce(
                                                            (acc, key) => ({
                                                                ...acc,
                                                                ...{ [key.key_name]: key.key_value }
                                                            }),
                                                            {}
                                                        )
                                                    });

                                                    if (response.status) {
                                                        mutate();
                                                        toast.success(response.message);
                                                    } else {
                                                        toast.error(response.message);
                                                    }
                                                }
                                            }}
                                        >
                                            Login
                                        </Button>
                                    </TableCell>
                                    {setting_edit && (
                                        <TableCell>
                                            <IconButton onClick={() => setEdit(socket)}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Grid container>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
            {edit && <SocketEdit data={edit} onClose={() => setEdit(null)} mutate={mutate} brokers={brokers} />}
        </Grid>
    );
};
export default Socket;
