import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Delete, Edit } from '../files/icon';
import AddBanner from '../components/more/Banner/AddBanner';
import PlanDelete from '../components/more/subscription/PlanDelete';
import { usePermission } from '../components/common';

const Banner = () => {
    const { data, error, mutate } = useSWR('/slider-list');
    const [add, setAdd] = useState();
    const [deleteBanner, setDeleteBanner] = useState();
    const { banner_delete, banner_edit } = usePermission(['banner-edit', 'banner-delete']);
    return (
        <>
            <Grid
                bgcolor="secondary.main"
                gap="12px"
                container
                justifyContent="space-around"
                px="12px"
                py="8px"
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography variant="h6">Banner({data?.data?.length || 0})</Typography>
                </Grid>
                <Grid item xs></Grid>
                {banner_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setAdd(true)}>
                            Add Banner
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 50px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Title</TableCell>
                            <TableCell>Thumb Image</TableCell>
                            <TableCell align="left">Link</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Dashboard</TableCell>
                            <TableCell>Is Video</TableCell>
                            <TableCell>Status</TableCell>
                            {(banner_delete || banner_edit) && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.data?.length > 0 ? (
                            data?.data?.map((banner) => (
                                <TableRow key={banner.id}>
                                    <TableCell align="left">
                                        {`${banner.title.slice(0, 23)}${banner.title.length > 23 ? '...' : ''}`}
                                    </TableCell>
                                    <TableCell>
                                        {banner.image.length > 0 ? (
                                            <Avatar
                                                src={banner.image}
                                                variant="square"
                                                sx={{ height: '100%', minWidth: '100px', margin: '0 auto' }}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Link href={banner.link} target="_blank">
                                            {`${banner.link.slice(0, 23)}${banner.link.length > 23 ? '...' : ''}`}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{banner.type}</TableCell>
                                    <TableCell>{banner.show_in_dashboard ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{banner.is_video ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{banner.status ? 'Yes' : 'No'}</TableCell>
                                    {(banner_delete || banner_edit) && (
                                        <TableCell>
                                            {banner_edit && (
                                                <IconButton sx={{ mr: '5px' }} onClick={() => setAdd(banner)}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {banner_delete && (
                                                <IconButton onClick={() => setDeleteBanner({ slider_id: banner.id })}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : !data && !error ? (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {add && <AddBanner data={add} onClose={() => setAdd(null)} mutate={mutate} />}
            {deleteBanner && (
                <PlanDelete data={deleteBanner} onClose={() => setDeleteBanner(null)} mutate={mutate} url="/slider-delete" name="Banner" />
            )}
        </>
    );
};
export default Banner;
