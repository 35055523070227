import {
    Avatar,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    Pagination,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFilters } from '../components/Trade/api';
import { getColor } from '../components/Customers/api';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../components/common';
import StrategyFilter from '../components/Trade/StrategyFilter';
import DateFilter from '../components/Trade/DateFilter';
import Search from '../components/common/Search';
import { Export, ClosePosition, ClosePosition2 } from '../files/icon';
import { ReactComponent as MarketUp } from '../files/icon/MarketUp.svg';
import { ReactComponent as MarketDown } from '../files/icon/MarketDown.svg';
import { useDispatch, useSelector } from 'react-redux';
import BrokerFilter from '../components/Trade/BrokerFilter';
import Button from '../components/common/Button';
import Api from '../api';
import { toast } from 'react-toastify';
import CloseP0sition from '../components/Trade/ClosePOsition';
import useSWR from 'swr';
import { Fragment } from 'react';
import Marquee from 'react-fast-marquee';
import { applyOpenFilter, clearOpenFilter } from '../store/filter';

const Position = () => {
    const mode = useSelector((state) => state.reducer.mode);
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.open);
    const { open_position_edit } = usePermission(['open-position-edit']);
    const navigate = useNavigate();
    const [ltp, setLtp] = useState();
    const [close, setClose] = useState();
    const [show, setShow] = useState();
    const [anhcorEl, setAnchorEl] = useState({ date: null, Strategy: null });
    const { data: tickerList } = useSWR(['/ticker-list', { user_id }]);
    const {
        data: openPositions,
        rest,
        totalRecords,
        isLoading,
        paginationProp,
        mutate
    } = usePagination('/open-position-list', getFilters(Filter));
    const { data: socketWise } = useSWR(['/socket-open-position-list', getFilters(Filter)]);
    const { data: total } = useSWR(['/closedopen-position', getFilters(Filter)]);
    useEffect(() => {
        if (openPositions) {
            var socket = new WebSocket(rest?.wssData?.ws_url || 'wss://stream.algosuccess.xyz');
            var onBeforeUnload = () => {
                if (socket?.readyState === 1) {
                    socket?.close();
                }
            };
            window.addEventListener('beforeunload', onBeforeUnload);
            socket.onopen = () => {
                socket.send(
                    JSON.stringify({
                        mode,
                        user_id: rest?.wssData?.user_id || '62a08a5e30e7890d50a250e1'
                    })
                );
                socket.onmessage = (e) => {
                    const data = JSON.parse(e.data);
                    setLtp((prev) => {
                        if (tickerList?.data?.map((ticker) => ticker.token).includes(data?.token)) {
                            return {
                                ...prev,
                                [data?.token]: data?.ltp.toFixed(data?.exchange_type === 13 ? 4 : 2),
                                [`${data?.token}cng`]: (data?.ltp - data?.close_price).toFixed(data?.exchange_type === 13 ? 4 : 2),
                                [`${data?.token}nc`]: (((data?.ltp - data?.close_price) * 100) / data?.close_price).toFixed(
                                    data?.exchange_type === 13 ? 4 : 3
                                )
                            };
                        } else {
                            return { ...prev, [data?.token]: data?.ltp };
                        }
                    });
                };
            };
        }
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload);
            if (socket?.readyState === 1) {
                socket?.close();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openPositions]);

    const Floating = socketWise?.socket_wise_data
        ?.map((token) => {
            if (token.type === 'SELL') {
                return token.avg_price - (ltp?.[token?.socket_token] * token.qty || token.ltp);
            }
            if (token.type === 'BUY') {
                return (ltp?.[token?.socket_token] * token.qty || token.ltp) - token.avg_price;
            }
            return 0;
        })
        .reduce((acc, cv) => acc + Number(cv), 0);

    const setFilter = (data) => {
        dispatch(applyOpenFilter(data));
    };

    const onExport = async () => {
        try {
            const response = await Api.post('/open-position-export', getFilters(Filter), { responseType: 'arraybuffer' });
            const date = new Date();
            const outputFilename = `openPosition ${date.toLocaleString('en-IN')}.xlsx`;
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <>
            {tickerList && tickerList?.data?.length > 0 && (
                <Grid position="fixed" top="67px" ml="min(25% , 375px)" width="min(750px , 50%)">
                    <Marquee gradient={false} speed={65}>
                        <Grid container wrap="nowrap">
                            {tickerList?.data?.map((ticker, index) => (
                                <Fragment key={index}>
                                    <Typography noWrap variant="body2" pl="10px" color="primary">
                                        {ticker.symbol_name}
                                    </Typography>
                                    <Typography noWrap variant="body2" textAlign="center" width="70px">{`${
                                        ltp?.[ticker.token] || '0.00'
                                    }`}</Typography>
                                    <Typography noWrap variant="body2">
                                        {ltp?.[`${ticker.token}cng`] ? ltp?.[`${ticker.token}cng`] > 0 ? <MarketUp /> : <MarketDown /> : ''}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        variant="body2"
                                        textAlign="center"
                                        width="130px"
                                        color={getColor(ltp?.[`${ticker.token}cng`] || '0.00')}
                                    >
                                        {`${ltp?.[`${ticker.token}cng`] || '0.00'} (${ltp?.[`${ticker.token}nc`] || '0.00'}%)`}
                                    </Typography>
                                    <Typography component="span" noWrap variant="body2" color="primary">
                                        |
                                    </Typography>
                                </Fragment>
                            ))}
                        </Grid>
                    </Marquee>
                </Grid>
            )}
            <Grid bgcolor="secondary.main" container p={'12px'} columnGap="12px" sx={{ borderRadius: '5px 5px 0 0' }}>
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Open({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'open') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearOpenFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                {open_position_edit && (
                    <Grid item xs="auto">
                        <Switch size="small" checked={show} onChange={() => setShow((prev) => !prev)} />
                    </Grid>
                )}
                {mode === 'live' && (
                    <Grid item xs="auto">
                        <Button
                            id="status"
                            aria-haspopup="true"
                            onClick={(e) => setAnchorEl(Boolean(anhcorEl?.broker) ? {} : { date: null, broker: e.currentTarget })}
                            sx={{ py: '1px' }}
                        >
                            Broker
                        </Button>
                        <Menu
                            id="status"
                            open={Boolean(anhcorEl?.broker)}
                            anchorEl={anhcorEl?.broker}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            onClose={() => setAnchorEl({})}
                            PaperProps={{ sx: { width: 'min-content' } }}
                        >
                            <BrokerFilter setAnchorEl={setAnchorEl} setFilter={setFilter} Filter={Filter} />
                        </Menu>
                    </Grid>
                )}
                <Grid item xs="auto">
                    <Button
                        id="Strategy"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(
                                Boolean(anhcorEl.Strategy) ? { date: null, Strategy: null } : { date: null, Strategy: e.currentTarget }
                            )
                        }
                        sx={{ py: '1px' }}
                    >
                        Strategy
                    </Button>
                    <Menu
                        id="Strategy"
                        open={Boolean(anhcorEl.Strategy)}
                        anchorEl={anhcorEl.Strategy}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null })}
                        PaperProps={{ sx: { width: '205px' } }}
                    >
                        <StrategyFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(Boolean(anhcorEl.date) ? { date: null, Strategy: null } : { date: e.currentTarget, Strategy: null })
                        }
                        sx={{ py: '1px' }}
                    >
                        Date/Time
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null })}
                        PaperProps={{ sx: { width: '400px' } }}
                    >
                        <DateFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                {open_position_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={onExport}>
                            <Export />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 100px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ zIndex: 2 }}>
                                Name
                            </TableCell>
                            <TableCell>Date/Time</TableCell>
                            <TableCell align="left">Strategy</TableCell>
                            <TableCell align="left">Symbol</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Avg. Price</TableCell>
                            <TableCell sx={{ width: '100px' }}>LTP</TableCell>
                            <TableCell sx={{ width: '100px' }}>Gain/Loss</TableCell>
                            {mode === 'live' && <TableCell>Broker</TableCell>}
                            {show && <TableCell>Close</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {openPositions && openPositions?.length > 0 ? (
                            openPositions?.map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: order.user_id, customer_name: order.customer_phone }
                                                })
                                            }
                                        >
                                            {order.customer}
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        <span style={{ display: 'block' }}>{order.date_time?.split(' ')[0]}</span>
                                        <Typography component="span" variant="caption" color="#878C96">
                                            {order.date_time?.split(' ')[1]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Tooltip title={order.strategy_name}>
                                            <span>{order.strategy_code}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="left" sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        <span style={{ display: 'block' }}>{order.title}</span>
                                        <Typography component="span" variant="caption" color="#878C96">
                                            {order.subtitle}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(order.action) }}>{order.action}</TableCell>
                                    <TableCell>{`${order.quantity}(${order.quantity / order.lotsize})`}</TableCell>
                                    <TableCell>{order.action === 'SELL' ? order.sell : order.buy}</TableCell>
                                    <TableCell>{rupeeFormat(ltp?.[order.exchange_tokens] || order.ltp)}</TableCell>
                                    <TableCell
                                        sx={{
                                            color: getColor(
                                                String(
                                                    order.action === 'SELL'
                                                        ? rupeeFormat(
                                                              (Number(order.sell) -
                                                                  (Number(ltp?.[order.exchange_tokens]) || Number(order.ltp))) *
                                                                  Number(order.quantity)
                                                          ) || 0
                                                        : rupeeFormat(
                                                              ((Number(ltp?.[order.exchange_tokens]) || Number(order.ltp)) -
                                                                  Number(order.buy)) *
                                                                  Number(order.quantity)
                                                          ) || 0
                                                )
                                            )
                                        }}
                                    >
                                        {order.action === 'SELL'
                                            ? rupeeFormat(
                                                  (Number(order.sell) - (Number(ltp?.[order.exchange_tokens]) || Number(order.ltp))) *
                                                      Number(order.quantity)
                                              ) || 0
                                            : rupeeFormat(
                                                  ((Number(ltp?.[order.exchange_tokens]) || Number(order.ltp)) - Number(order.buy)) *
                                                      Number(order.quantity)
                                              ) || 0}
                                    </TableCell>
                                    {mode === 'live' && (
                                        <TableCell>
                                            <Avatar
                                                src={order.broker_logo}
                                                variant="square"
                                                sx={{ display: 'block', margin: '0 auto', height: '28px', width: '28px' }}
                                            />
                                        </TableCell>
                                    )}
                                    {show && (
                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    setClose({
                                                        trade_book_id: order.id,
                                                        broker: false,
                                                        qty: order.quantity,
                                                        price: ltp?.[order.exchange_tokens] || order.ltp
                                                    })
                                                }
                                            >
                                                <ClosePosition />
                                            </IconButton>
                                            {mode === 'live' && (
                                                <IconButton
                                                    sx={{ ml: '5px' }}
                                                    onClick={() => setClose({ trade_book_id: order.id, broker: true, qty: order.quantity })}
                                                >
                                                    <ClosePosition2 />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={11}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter sx={{ position: 'sticky', bottom: 1, left: 0 }}>
                        <TableRow>
                            <TableCell colSpan={10} sx={{ padding: '0 12px' }}></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Grid container bgcolor="background.default" width="100%" columnGap={'16px'} sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}>
                {paginationProp.count > 1 && (
                    <Grid item xs="auto">
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Typography
                        bgcolor="secondary.main"
                        borderRadius="5px"
                        width="750px"
                        noWrap
                        px="15px"
                        py="2px"
                        sx={{ float: 'right', border: (theme) => `1px solid ${theme.palette.primary.main}` }}
                    >
                        <Typography component="span" px="5px">
                            Net P&L :
                        </Typography>
                        <Typography
                            sx={{ display: 'inline-block', width: '20%' }}
                            component="span"
                            color={getColor(rupeeFormat((Floating || 0) + Number(total?.sum || 0)))}
                        >
                            {rupeeFormat((Floating || 0) + Number(total?.sum || 0))}
                        </Typography>
                        <Typography component="span" px="5px">
                            Booked P&L :
                        </Typography>
                        <Typography
                            sx={{ display: 'inline-block', width: '20%' }}
                            component="span"
                            color={getColor(rupeeFormat(Number(total?.sum || 0)))}
                        >
                            {rupeeFormat(Number(total?.sum || 0))}
                        </Typography>
                        <Typography component="span" px="5px">
                            Floating P&L :
                        </Typography>
                        <Typography component="span" color={getColor(rupeeFormat(Floating || 0))} sx={{ display: 'inline-block' }}>
                            {rupeeFormat(Floating || 0)}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            {close && <CloseP0sition data={close} onClose={() => setClose(null)} mutate={mutate} />}
        </>
    );
};
export default Position;
