import { IconButton, Input, InputAdornment } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { SearchIcon } from '../../files/icon';

const Search = ({ setFilter, filter = '' }) => {
    const [value, setValue] = useState(filter);

    useEffect(() => {
        setValue(filter);
    }, [filter]);

    // const onChange = (e) => {
    //     setValue(e.target.value);
    //     const debounceFunc = debounce(() => {
    //         setFilter((prev) => ({ ...prev, search: e.target.value, current_page: 1 }));
    //     }, 1000);
    //     if (new RegExp('^[a-zA-Z0-9+@ ]*$').test(e.target.value)) {
    //         debounceFunc();
    //         setError(false);
    //     } else {
    //         debounceFunc.flush();
    //         setError(true);
    //     }
    // };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                setFilter({ search: value, current_page: 1 });
            }}
        >
            <Input
                sx={{
                    height: '28px',
                    bgcolor: 'background.default',
                    border: '2px solid rgba(115, 103, 240, 0.5)',
                    background: 'rgba(115, 103, 240, 0.06)',
                    width: '170px'
                }}
                startAdornment={
                    <IconButton type="submit">
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    </IconButton>
                }
                value={value}
                onChange={(e) => new RegExp('^[a-zA-Z0-9+@ ]*$').test(e.target.value) && setValue(e.target.value)}
                placeholder="Search"
            />
        </form>
    );
};
export default Search;
