import { Autocomplete, Dialog, Grid, Input, TextField, Typography } from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';

const AddType = ({ data, onClose, mutate }) => {
    const schema = yup.object().shape({
        name: yup.string().required(),
        status: yup.boolean().required()
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: typeof data === 'boolean' ? {} : data
    });

    const submit = async (input) => {
        const response = await fetcher('/type-add', input);

        if (response?.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '350px', px: '40px' } }}>
            <Typography textAlign="center" variant="h6">
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Type
            </Typography>
            <AsyncForm onSubmit={handleSubmit(submit)}>
                <Grid minHeight="70px">
                    <Typography variant="body2">Name</Typography>
                    <Input error={Boolean(errors?.name?.message)} {...register('name')} />
                    <Typography color="error" variant="body2">
                        {errors?.name?.message}
                    </Typography>
                </Grid>
                <Grid minHeight="70px">
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={[true, false]}
                                getOptionLabel={(option) => (option ? 'Active' : 'Deactive')}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.status?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
            </AsyncForm>
        </Dialog>
    );
};
export default AddType;
