import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import NoRouteMatch from './pages/NoRouteMatch';

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                {window.location.pathname.includes('1707-coretus') ? (
                    <BrowserRouter basename="/1707-coretus">
                        <App />
                    </BrowserRouter>
                ) : (
                    <NoRouteMatch />
                )}
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
