import {
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Export, EyeFilled, Filled, Import, Pending } from '../../../files/icon';
import Details from './Details';
import Button from '../../common/Button';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../../common';
import FileInputDialog from './FileInputDialog';
import { getFilters } from '../../Trade/api';
import Search from '../../common/Search';
import DateFilter from '../../Trade/DateFilter';
import { getColor } from '../../Customers/api';
import Api from '../../../api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { applygstFilter, cleargstFilter } from '../../../store/filter';

const getKey = (key) => {
    if (key === 'filled') {
        return <Filled />;
    }
    if (key === 'pending' || key === 'Pending') {
        return <Pending />;
    }
};

const GST = () => {
    const navigate = useNavigate();
    const { transaction_gst_edit } = usePermission(['transaction-gst-edit']);
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.gst);
    const [invoice_id, setId] = useState(null);
    const [anhcorEl, setAnchorEl] = useState({});
    const [FileUpload, setFileUpload] = useState(false);

    const { data: GSTinfo, totalRecords, isLoading, paginationProp, rest, mutate } = usePagination('/gst-info', getFilters(Filter));

    const onExport = async () => {
        try {
            const response = await Api.post('/gst-export', getFilters(Filter), {
                responseType: 'arraybuffer'
            });

            const date = new Date();

            const outputFilename = `GSTinfo ${date.toLocaleString('en-IN')}.xlsx`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const setFilter = (data) => {
        dispatch(applygstFilter(data));
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Typography fontSize={'120%'}>GST({totalRecords || 0})</Typography>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'gst') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(cleargstFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, status: null })}
                        PaperProps={{ sx: { width: '125px' } }}
                    >
                        {['', 'filled', 'pending'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.status === mode}
                                onClick={() => {
                                    setFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.date) ? {} : { date: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Date
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '400px' } }}
                    >
                        <DateFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.type) ? {} : { type: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Service Type
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.type)}
                        anchorEl={anhcorEl?.type}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, type: null })}
                        PaperProps={{ sx: { width: '125px' } }}
                    >
                        {['', 'Trading', 'Membership', 'Demat'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.service_type === mode}
                                onClick={() => {
                                    setFilter({ service_type: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {transaction_gst_edit && (
                    <>
                        <Grid item xs="auto">
                            <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={() => setFileUpload(true)}>
                                <Import />
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={onExport}>
                                <Export />
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            <TableContainer sx={{ height: 'calc(100% - 49px)' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Invoice No</TableCell>
                            <TableCell>Invoice Date</TableCell>
                            <TableCell align="left">Service</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Gross Amount</TableCell>
                            <TableCell>GST</TableCell>
                            <TableCell>Net Amount</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {GSTinfo && GSTinfo?.length > 0 ? (
                            GSTinfo?.map((gstInfo) => (
                                <TableRow key={gstInfo.id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: gstInfo.user_id, customer_name: gstInfo.customer_phone }
                                                })
                                            }
                                        >
                                            {gstInfo.customer_name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{gstInfo.invoice_no}</TableCell>
                                    <TableCell>{gstInfo.invoice_date.split(' ')[0]}</TableCell>
                                    <TableCell align="left" sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        {`${gstInfo.service_type} - ${gstInfo.service_name}`}
                                    </TableCell>
                                    <TableCell>{getKey(gstInfo.gst_status)}</TableCell>
                                    <TableCell>{rupeeFormat(gstInfo.gross_amount)}</TableCell>
                                    <TableCell>{rupeeFormat(gstInfo.gst)}</TableCell>
                                    <TableCell>{rupeeFormat(gstInfo.net_amount)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setId(gstInfo.id)}>
                                            <EyeFilled />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={9}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter sx={{ position: 'sticky', bottom: 0.5 }}>
                        <TableRow>
                            <TableCell colSpan={5} sx={{ padding: '0 16px' }}>
                                {paginationProp.count > 1 && (
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        bgcolor="background.default"
                                        sx={{ borderRadius: '0 0 5px 5px' }}
                                    >
                                        <Pagination
                                            {...paginationProp}
                                            color="primary"
                                            onChange={(e, v) => setFilter({ current_page: v })}
                                        />
                                    </Grid>
                                )}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '5px 0px 0px 5px',
                                    borderRight: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_gross_amount || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_gross_amount || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_gst || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_gst || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '0px 5px 5px 0px',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_net_amount || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_net_amount || 0)}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            {invoice_id && <Details invoice_id={invoice_id} setId={setId} />}
            {FileUpload && <FileInputDialog open={FileUpload} onClose={() => setFileUpload(false)} mutate={mutate} />}
        </>
    );
};

export default GST;
