import { ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './pages/PrivateRoute';
import SignIn from './pages/SignIn';
import Theme from './files/theme';
import CssBaseline from '@mui/material/CssBaseline';
import Main from './pages/Main';
import Customers from './pages/Customers';
import Brokers from './pages/Brokers';
import Strategies from './pages/Strategies';
import Users from './pages/Users';
import Settings from './pages/Settings';
import useSWR, { SWRConfig } from 'swr';
import CustomersDetali from './components/Customers/CustomersDetail';
import Orderbook from './pages/Orderbook';
import Position from './pages/Position';
import TradeBook from './pages/TradeBook';
import Membership from './components/Transaction/Membership';
import Trade from './components/Transaction/Trade';
import Refferal from './components/Transaction/Refferal';
import Withdraw from './components/Transaction/Withdraw';
import ProfitLoss from './components/Transaction/ProfitLoss';
import GST from './components/Transaction/GST';
// import TDS from './components/Transaction/TDS';
import Invoice from './components/Transaction/Invoice';
import NoRouteMatch from './pages/NoRouteMatch';
import StrategyDetails from './pages/StrategyDetails';
import Userpnl from './components/Transaction/Userpnl';
import Subscription from './pages/Subscription';
import Groups from './pages/Groups';
import Banner from './pages/Banner';
import Promocodes from './pages/Promocodes';
import RolesPage from './pages/RolesPage';
import { usePermission } from './components/common';
import { useEffect } from 'react';
import { swrFetcher } from './api';
import { useDispatch, useSelector } from 'react-redux';
import { update } from './store/reducer';
import Ticker from './pages/Ticker';
import UserLogin from './pages/UserLogin';
import Closed from './pages/Closed';
import Oauth from './pages/Oauth';

const App = () => {
    const {
        customers_view,
        broker_view,
        strategies_view,
        orderbook_view,
        open_position_view,
        tradebook_view,
        transaction_subscriptions_view,
        transaction_trade_view,
        transaction_referral_view,
        transaction_withdraw_view,
        transaction_gst_view,
        transaction_pnl_view,
        transaction_invoice_view,
        transaction_user_pnl_view,
        subscriptions_view,
        setting_view,
        users_view,
        banner_view,
        marketing_group_view,
        marketing_promocodes_view,
        ticker_view,
        user_login_view,
        closed_position_view
    } = usePermission([
        'customers-view',
        'orderbook-view',
        'broker-view',
        'strategies-view',
        'open-position-view',
        'tradebook-view',
        'transaction-subscriptions-view',
        'transaction-trade-view',
        'transaction-referral-view',
        'transaction-withdraw-view',
        'transaction-gst-view',
        'transaction-pnl-view',
        'transaction-invoice-view',
        'transaction-user-pnl-view',
        'subscriptions-view',
        'setting-view',
        'users-view',
        'banner-view',
        'marketing-group-view',
        'marketing-promocodes-view',
        'ticker-view',
        'user-login-view',
        'closed-position-view'
    ]);
    const userData = useSelector((state) => state?.reducer?.data);
    const { data } = useSWR(userData?._id && ['/dashboard-users-detail', { user_id: userData?._id }], swrFetcher, {
        revalidateIfStale: false,
        dedupingInterval: 10000000
    });
    const dispatch = useDispatch();
    useEffect(() => {
        if (data && data?.status) {
            dispatch(update(data));
        }
    }, [dispatch, data]);

    return (
        <SWRConfig value={{ revalidateOnFocus: false, revalidateFirstPage: false, fetcher: swrFetcher }}>
            <ThemeProvider theme={Theme}>
                <CssBaseline enableColorScheme />
                <Routes>
                    <Route path="/signin" element={<SignIn />} />
                    <Route element={<PrivateRoute />}>
                        <Route element={<Main />}>
                            <Route index path="/" element={<Dashboard />} />
                            {customers_view && <Route path="/customers" element={<Customers />} />}
                            {customers_view && <Route path="/customers/details" element={<CustomersDetali />} />}
                            {broker_view && <Route path="/broker" element={<Brokers />} />}
                            {strategies_view && <Route path="/strategies" element={<Strategies />} />}
                            {strategies_view && <Route path="/strategies/details" element={<StrategyDetails />} />}
                            {orderbook_view && <Route path="/Trade/Orders" element={<Orderbook />} />}
                            {open_position_view && <Route path="/Trade/Open" element={<Position />} />}
                            {tradebook_view && <Route path="/Trade/TradeHistory" element={<TradeBook />} />}
                            {closed_position_view && <Route path="/Trade/Closed" element={<Closed />} />}
                            {transaction_subscriptions_view && <Route path="/Transaction/Subscriptions" element={<Membership />} />}
                            {transaction_trade_view && <Route path="/Transaction/Trades" element={<Trade />} />}
                            {transaction_referral_view && <Route path="/Transaction/Referrals" element={<Refferal />} />}
                            {transaction_withdraw_view && <Route path="/Transaction/Withdraw" element={<Withdraw />} />}
                            {transaction_gst_view && <Route path="/Transaction/GST" element={<GST />} />}
                            {/* <Route path="/Transaction/TDS" element={<TDS />} /> */}
                            {transaction_pnl_view && <Route path="/Transaction/ProfitLoss" element={<ProfitLoss />} />}
                            {transaction_invoice_view && <Route path="/Transaction/Invoice" element={<Invoice />} />}
                            {transaction_user_pnl_view && <Route path="/Transaction/Userp&l" element={<Userpnl />} />}
                            {setting_view && <Route path="/settings" element={<Settings />} />}
                            {subscriptions_view && <Route path="/subscription" element={<Subscription />} />}
                            {users_view && <Route path="/Users/Roles" element={<RolesPage />} />}
                            {users_view && userData?.role?.role_name === 'Admin' && <Route path="/Users" element={<Users />} />}
                            {marketing_group_view && <Route path="/Marketing/Groups" element={<Groups />} />}
                            {marketing_promocodes_view && <Route path="/Marketing/PromoCodes" element={<Promocodes />} />}
                            {banner_view && <Route path="/More/Banner" element={<Banner />} />}
                            {ticker_view && <Route path="/More/Ticker" element={<Ticker />} />}
                            {user_login_view && <Route path="/Transaction/UsersLogin" element={<UserLogin />} />}
                        </Route>
                    </Route>
                    <Route path="/oauth" element={<Oauth />} />
                    <Route path="*" element={<NoRouteMatch />} />
                </Routes>
                <ToastContainer position="top-right" autoClose={8000} theme="dark" />
            </ThemeProvider>
        </SWRConfig>
    );
};

export default App;
