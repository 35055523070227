import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { Calendar } from 'react-multi-date-picker';

const buttonStyle = { py: '8px', borderRadius: '0px', '&.Mui-disabled': { background: '#46468A', color: 'white' } };

const DateFilter = ({ setFilter, Filter, setAnchorEl, customFilters = true, defaultObj = { search: '' } }) => {
    const [data, setData] = useState(Filter);

    return (
        <Grid container>
            {customFilters && (
                <Grid item xs>
                    <Button
                        variant="header"
                        disabled={data?.date_time === ''}
                        onClick={() => {
                            setFilter({
                                date_time: '',
                                dates_from: '',
                                dates_to: '',
                                current_page: 1
                            });
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                        sx={buttonStyle}
                    >
                        All
                    </Button>
                    <Button
                        variant="header"
                        disabled={data?.date_time === 'Today'}
                        onClick={() => {
                            setFilter({ date_time: 'Today', dates_from: '', dates_to: '', current_page: 1 });
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                        sx={buttonStyle}
                    >
                        Today
                    </Button>
                    <Button
                        variant="header"
                        disabled={data?.date_time === 'Yesterday'}
                        onClick={() => {
                            setFilter({ date_time: 'Yesterday', dates_from: '', dates_to: '', current_page: 1 });
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                        sx={buttonStyle}
                    >
                        Yesterday
                    </Button>
                    <Button
                        variant="header"
                        disabled={data?.date_time === 'This Week'}
                        onClick={() => {
                            setFilter({ date_time: 'This Week', dates_from: '', dates_to: '', current_page: 1 });
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                        sx={buttonStyle}
                    >
                        This Week
                    </Button>
                    <Button
                        variant="header"
                        disabled={data?.date_time === 'Last Week'}
                        onClick={() => {
                            setFilter({ date_time: 'Last Week', dates_from: '', dates_to: '', current_page: 1 });
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                        sx={buttonStyle}
                    >
                        Last Week
                    </Button>
                    <Button
                        variant="header"
                        disabled={data?.date_time === 'This Month'}
                        onClick={() => {
                            setFilter({ date_time: 'This Month', dates_from: '', dates_to: '', current_page: 1 });
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                        sx={buttonStyle}
                    >
                        This Month
                    </Button>
                    <Button
                        variant="header"
                        disabled={data?.date_time === 'Custom'}
                        onClick={() => setData({ date_time: 'Custom', current_page: 1 })}
                        sx={buttonStyle}
                    >
                        Custom
                    </Button>
                </Grid>
            )}
            <Grid item xs="auto">
                <Calendar
                    range
                    format="DD-MM-YYYY"
                    className="custom-calendar"
                    mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                        const props = { style: { color: '#d0d2d6' } };
                        if (isSameDate(today, date)) {
                            props.style = {
                                ...props.style,
                                backgroundColor: '#283046',
                                border: '2px solid rgba(115, 103, 240, 0.5)'
                            };
                        }
                        if (isSameDate(date, selectedDate))
                            props.style = {
                                ...props.style,
                                backgroundColor: '#7367F0'
                            };
                        return props;
                    }}
                    onChange={(e) =>
                        setData((prev) => ({
                            ...prev,
                            dates_from: e[0].format(),
                            dates_to: e?.[1]?.format(),
                            date_time: 'Custom',
                            current_page: 1
                        }))
                    }
                    value={[data?.dates_from, data?.dates_to]}
                />
            </Grid>
            <Grid item xs={7} container columnGap="16px">
                <Grid item xs>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setFilter({ dates_from: '', dates_to: '', date_time: '' });
                            setData({});
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                    >
                        Reset
                    </Button>
                </Grid>
                <Grid item xs>
                    <Button
                        onClick={() => {
                            setFilter(data);
                            setAnchorEl({ date: null, Strategy: null });
                        }}
                    >
                        Apply
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default DateFilter;
