import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { matchFilters, usePagination, usePermission } from '../components/common';
import Search from '../components/common/Search';
import { getKeyStyles } from '../components/Customers/api';
import PlanDelete from '../components/more/subscription/PlanDelete';
import EditUsers from '../components/more/Users/EditUsers';
import PermissionView from '../components/more/Users/PermissionView';
import { Delete, Edit } from '../files/icon';
import { applyUsersFilter, clearUsersFilter } from '../store/filter';

const Users = () => {
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.users);
    const { data: Promocodes, totalRecords, isLoading, paginationProp, mutate } = usePagination('/users-list', Filter);
    const [add, setAdd] = useState();
    const [view, setView] = useState();
    const [deleteUser, setDeleteUser] = useState();
    const navigate = useNavigate();
    const { users_edit, users_delete } = usePermission(['users-edit', 'users-delete']);

    const setFilter = (data) => {
        dispatch(applyUsersFilter(data));
    };

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                columnGap="12px"
                container
                justifyContent="flex-start"
                p={'12px'}
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Users({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'users') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearUsersFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                {users_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => navigate('/Users/Roles')}>
                            Roles
                        </Button>
                    </Grid>
                )}
                {users_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setAdd(true)}>
                            Add User
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 53px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Status</TableCell>
                            {users_edit && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Promocodes && Promocodes?.length > 0 ? (
                            Promocodes?.map((group) => (
                                <TableRow key={group.user_id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                setView({
                                                    name: group.role.name,
                                                    permission: group.role.permissions
                                                        .map((per) => per.type)
                                                        .filter((per, index, arr) => arr.indexOf(per) === index)
                                                        .reduce(
                                                            (acc, cv) => ({
                                                                ...acc,
                                                                [cv]: group.role.permissions
                                                                    .filter((per) => per.type === cv)
                                                                    .map((cv) => cv.name)
                                                            }),
                                                            {}
                                                        )
                                                })
                                            }
                                        >
                                            {group.name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{group.email}</TableCell>
                                    <TableCell>{group.role.name}</TableCell>
                                    <TableCell>{getKeyStyles(group.status)}</TableCell>
                                    {(users_edit || users_delete) && (
                                        <TableCell>
                                            {users_edit && (
                                                <IconButton onClick={() => setAdd(group)}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {users_delete && group?.user_type?.toLowerCase() !== 'super admin' && (
                                                <IconButton sx={{ ml: '10px' }} onClick={() => setDeleteUser({ user_id: group.user_id })}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>
            {add && <EditUsers data={add} onClose={() => setAdd(null)} mutate={mutate} />}
            {view && <PermissionView data={view} onClose={() => setView(null)} />}
            {deleteUser && (
                <PlanDelete data={deleteUser} onClose={() => setDeleteUser(null)} mutate={mutate} url="/delete-user" name="User" />
            )}
        </>
    );
};

export default Users;
