import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { usePermission } from '../components/common';
import { getKeyStyles } from '../components/Customers/api';
import PlanDelete from '../components/more/subscription/PlanDelete';
import AddTicker from '../components/more/ticker/AddTicker';
import { Delete, Edit } from '../files/icon';

const Ticker = () => {
    const { data, isLoading, mutate } = useSWR('ticker-info');
    const [add, setAdd] = useState();
    const [deleteGroup, setDeleteGroup] = useState();
    const { ticker_delete, ticker_edit } = usePermission(['ticker-edit', 'ticker-delete']);

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                columnGap="12px"
                container
                justifyContent="flex-start"
                p={'12px'}
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Ticker({data?.ticker_info?.length || 0})</Typography>
                </Grid>
                <Grid item xs></Grid>
                {ticker_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setAdd(true)}>
                            Add Ticker
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 53px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Symbol Name</TableCell>
                            <TableCell>Symbol Token</TableCell>
                            <TableCell>Broker</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Order</TableCell>
                            <TableCell>Exchange</TableCell>
                            {(ticker_edit || ticker_delete) && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.ticker_info?.length > 0 ? (
                            data?.ticker_info?.map((group) => (
                                <TableRow key={group.ticker_id}>
                                    <TableCell align="left">{group.symbol_name}</TableCell>
                                    <TableCell>{group.symbol_token}</TableCell>
                                    <TableCell>
                                        <Avatar
                                            src={group?.broker?.logo}
                                            variant="square"
                                            sx={{
                                                height: '28px',
                                                width: '28px',
                                                margin: '0 auto'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{getKeyStyles(group.status)}</TableCell>
                                    <TableCell>{group.order}</TableCell>
                                    <TableCell>{group.exchange}</TableCell>
                                    {(ticker_edit || ticker_delete) && (
                                        <TableCell>
                                            {ticker_edit && (
                                                <IconButton sx={{ mr: ticker_delete ? '5px' : '' }} onClick={() => setAdd(group)}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {ticker_delete && (
                                                <IconButton onClick={() => setDeleteGroup({ ticker_id: group.ticker_id })}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {add && <AddTicker data={add} onClose={() => setAdd(null)} mutate={mutate} length={data?.ticker_info?.length} />}
            {deleteGroup && (
                <PlanDelete
                    data={deleteGroup}
                    onClose={() => setDeleteGroup(null)}
                    mutate={mutate}
                    url="/ticker-info-delete"
                    name="Ticker"
                />
            )}
        </>
    );
};

export default Ticker;
