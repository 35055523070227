import { Autocomplete, Dialog, Grid, IconButton, Input, InputAdornment, TextField, Typography } from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Eye } from '../../../files/icon';
import useSWR from 'swr';

const EditUsers = ({ data, onClose, mutate }) => {
    const [show, setShow] = useState(false);
    const { data: Types, error } = useSWR('/roles-list');
    const schema = yup.object().shape({
        name: yup.string().required('Name is required').min(2, 'min 2 charater'),
        email: yup.string().required('Please enter email').email(),
        password:
            typeof data === 'boolean'
                ? yup
                      .string()
                      .required('Please enter password')
                      .min(8, 'min 8 character')
                      .matches(
                          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                          'Your password must contain Uppercase,Digit & Special Characters.'
                      )
                : yup.string().when({
                      is: (value) => value?.length > 0,
                      then: yup
                          .string()
                          .min(8, 'min 8 character')
                          .matches(
                              /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                              'Your password must contain Uppercase,Digit & Special Characters.'
                          ),
                      otherwise: yup.string()
                  }),
        role: yup.object().required().nullable(),
        user_type: yup.string().required(),
        status: yup.boolean().required()
    });

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        watch
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: typeof data === 'boolean' ? {} : { roles: {}, ...data }
    });

    const submit = async (input) => {
        const { role, password, ...rest } = input;
        const response = await fetcher('/users-update', {
            ...rest,
            role_id: role.role_id,
            ...(password?.length > 0 && { password })
        });

        if (response?.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    const getCapitalize = (option) => {
        const str = option.split('_');

        return str.map((opt) => opt.charAt(0).toUpperCase() + opt.slice(1)).join(' ');
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px', px: '40px' } }}>
            <Typography textAlign="center" variant="h6">
                Edit User
            </Typography>
            <AsyncForm onSubmit={handleSubmit(submit)}>
                <Grid minHeight="70px">
                    <Typography>Name</Typography>
                    <Input {...register('name')} error={Boolean(errors?.name?.message)} />
                    <Typography variant="body2" color="error">
                        {errors?.name?.message}
                    </Typography>
                </Grid>
                <Grid minHeight="70px">
                    <Typography>Email</Typography>
                    <Input {...register('email')} error={Boolean(errors?.email?.message)} />
                    <Typography variant="body2" color="error">
                        {errors?.email?.message}
                    </Typography>
                </Grid>
                <Grid minHeight="70px">
                    <Typography>Password</Typography>
                    <Input
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShow((prev) => (prev ? false : true))}>
                                    <Eye />
                                </IconButton>
                            </InputAdornment>
                        }
                        type={show ? 'text' : 'password'}
                        {...register('password')}
                        error={Boolean(errors?.password?.message)}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.password?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Role</Typography>
                    <Controller
                        control={control}
                        name="role"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                renderInput={(params) => <TextField {...params} fullWidth error={Boolean(errors?.role?.message)} />}
                                onChange={(e, v) => onChange(v)}
                                onBlur={onBlur}
                                value={value}
                                options={Types?.data || []}
                                loading={!Types && !error}
                                getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, value) => option.role_id === value.role_id}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.role?.message}
                    </Typography>
                </Grid>
                <Grid minHeight="70px">
                    <Typography>User Type</Typography>
                    <Controller
                        control={control}
                        name="user_type"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                renderInput={(params) => <TextField {...params} fullWidth error={Boolean(errors?.user_type?.message)} />}
                                onChange={(e, v) => onChange(v)}
                                onBlur={onBlur}
                                value={value}
                                options={['admin', 'sub_admin']}
                                loading={!Types && !error}
                                getOptionLabel={(option) => getCapitalize(option)}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.user_type?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => <TextField {...params} error={Boolean(errors?.status?.message)} />}
                                onBlur={onBlur}
                                disabled={watch('user_type')?.toLowerCase() === 'super admin'}
                                value={value}
                                options={[true, false]}
                                getOptionLabel={(option) => (option ? 'Active' : 'Deactivate')}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
            </AsyncForm>
        </Dialog>
    );
};
export default EditUsers;
