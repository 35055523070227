import { Autocomplete, Grid, Input, TextField, Typography } from '@mui/material';
import { useCallback, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import Api, { fetcher } from '../../api';
import Button from '../common/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const BIllingDetail = ({ edit, mutate, setEdit }) => {
    const {
        state: { user_id }
    } = useLocation();
    const [citiesList, setCitieslist] = useState(null);

    const { data: statelist, error: statelistError } = useSWR('/states-list', {
        revalidateOnFocus: false,
        revalidateIfStale: false
    });

    const getCities = async (data) => {
        if (data && data.state_id) {
            const response = await Api.post('/cities-list', data);
            if (response.data.status) {
                setCitieslist(response?.data?.data);
                setValue('city', data.city);
            }
        } else {
            setCitieslist(null);
        }
    };

    const schema = yup.object().shape({
        name: yup.string().required('Please, enter your name'),
        company_name: yup.string(),
        address_line_1: yup.string().required('Please, enter your address'),
        address_line_2: yup.string(),
        state: yup.object().required('Please, select a state').nullable(),
        city: yup.object().required('Please, select a city').nullable(),
        pincode: yup.string().required('Please, enter pincode'),
        GST: yup.string()
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            ...Object.keys(edit?.billing_details).reduce((acc, cv) => {
                return { ...acc, [cv]: edit?.billing_details?.[cv] === 'N/A' ? '' : edit?.billing_details?.[cv] || '' };
            }, {})
        }
    });

    const callback = useCallback(getCities, [setValue]);
    useEffect(() => {
        if (edit?.billing_details?.state && statelist) {
            callback({ state_id: edit?.billing_details?.state?.id, city: edit?.billing_details?.city });
        }
    }, [edit, callback, statelist]);

    const addBilligDetail = async (data) => {
        const { state, city, GST, ...rest } = data;
        const response = await fetcher('/add-billing-details', { state_id: state.id, city_id: city.id, gst_no: GST, ...rest, user_id });

        if (response?.status) {
            toast.success(response.message);
            mutate();
            setEdit(null);
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Grid container columnSpacing="15px" px="15px" pt="10px" alignItems="flex-start">
            {Object.keys(edit?.billing_details).map((key) => {
                if (key === 'state') {
                    return (
                        <Grid item xs={6} minHeight="65px" key={key}>
                            <Typography textTransform="capitalize" variant="body2" color="text.secondary">
                                {key.replaceAll('_', ' ')}
                            </Typography>
                            <Controller
                                control={control}
                                name={key}
                                render={({ field: { onChange, onBlur, name, value } }) => (
                                    <Autocomplete
                                        onBlur={onBlur}
                                        onChange={(e, v) => {
                                            onChange(v);
                                            getCities({ state_id: v?.id, city: { id: '', city: '' }, key: true });
                                        }}
                                        options={
                                            statelist?.data.concat({
                                                id: '',
                                                state: '',
                                                gst_code: ''
                                            }) || []
                                        }
                                        value={value}
                                        getOptionLabel={(option) => option.state || ''}
                                        renderInput={(params) => (
                                            <TextField {...params} name={name} error={Boolean(errors?.[key]?.message)} />
                                        )}
                                        loading={Boolean(statelist || statelistError)}
                                        loadingText={<Typography>Loading...</Typography>}
                                        isOptionEqualToValue={(option, value) => option.id === value.id || value === ''}
                                        clearIcon={null}
                                    />
                                )}
                            />
                            <Typography variant="body2" color="error">
                                {errors?.[key]?.message}
                            </Typography>
                        </Grid>
                    );
                } else if (key === 'city') {
                    return (
                        <Grid item xs={6} minHeight="65px" key={key}>
                            <Typography textTransform="capitalize" variant="body2" color="text.secondary">
                                {key.replaceAll('_', ' ')}
                            </Typography>
                            <Controller
                                control={control}
                                name={key}
                                render={({ field: { onChange, onBlur, name, value } }) => (
                                    <Autocomplete
                                        onBlur={onBlur}
                                        onChange={(e, v) => onChange(v)}
                                        value={value}
                                        options={citiesList?.concat({ id: '', city: '' }) || []}
                                        getOptionLabel={(option) => option.city || ''}
                                        renderInput={(params) => <TextField {...params} error={Boolean(errors?.[key]?.message)} />}
                                        noOptionsText={<Typography>select state</Typography>}
                                        isOptionEqualToValue={(option, value) => option.id === value.id || value === ''}
                                        clearIcon={null}
                                    />
                                )}
                            />
                            <Typography variant="body2" color="error">
                                {errors?.[key]?.message}
                            </Typography>
                        </Grid>
                    );
                } else {
                    return (
                        <Grid item xs={6} minHeight="65px" key={key}>
                            <Typography textTransform="capitalize" variant="body2" color="text.secondary">
                                {key.replaceAll('_', ' ')}
                            </Typography>
                            <Input {...register(key)} error={Boolean(errors?.[key]?.message)} />
                            <Typography variant="body2" color="error">
                                {errors?.[key]?.message}
                            </Typography>
                        </Grid>
                    );
                }
            })}
            <Grid item container xs={12}>
                <Button onClick={handleSubmit(addBilligDetail)} sx={{ width: '20%' }}>
                    Update
                </Button>
            </Grid>
        </Grid>
    );
};
export default BIllingDetail;
