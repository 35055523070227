import {
    Button,
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { getKeyStyles } from '../components/Customers/api';
import { matchFilters, rupeeFormat, usePagination } from '../components/common';
import Search from '../components/common/Search';
import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { applyUserloginFilter, clearUserloginFilter } from '../store/filter';

const UserLogin = () => {
    const dispatch = useDispatch();
    const filter = useSelector((state) => state.filter.userlogin);
    const [anhcorEl, setAnchorEl] = useState({});
    const navigate = useNavigate();
    const { data: brokers } = useSWR('/get-broker-list');
    const { data, totalRecords, isLoading, paginationProp } = usePagination('/user-login', filter);

    const setFilter = (data) => {
        dispatch(applyUserloginFilter(data));
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Users Login({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(filter, 'userlogin') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearUserloginFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.broker) ? {} : { broker: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Broker
                    </Button>
                    <Menu
                        open={Boolean(anhcorEl?.broker)}
                        anchorEl={anhcorEl?.broker}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        {brokers?.data
                            ?.filter((broker) => broker.status)
                            ?.map((broker) => (
                                <MenuItem
                                    divider={broker.name !== 'All'}
                                    key={broker.id}
                                    value={broker.name}
                                    disabled={filter?.broker === broker.id}
                                    onClick={() => {
                                        setFilter({ broker: broker.id });
                                        setAnchorEl({});
                                    }}
                                    sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
                                >
                                    {broker.name}
                                </MenuItem>
                            ))}
                        <MenuItem
                            value={''}
                            disabled={filter?.broker === ''}
                            onClick={() => {
                                setFilter({ broker: '' });
                                setAnchorEl({});
                            }}
                            sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
                        >
                            All
                        </MenuItem>
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.payment) ? {} : { payment: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        open={Boolean(anhcorEl?.payment)}
                        anchorEl={anhcorEl?.payment}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        {[true, false, ''].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={filter?.status === mode}
                                onClick={() => {
                                    setFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode ? 'Active' : 'Deactive'}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 52px - ${paginationProp.count > 1 ? 42 : 0}px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Broker Name</TableCell>
                            <TableCell>Client ID</TableCell>
                            <TableCell>Available Margin</TableCell>
                            <TableCell>Is Login</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Update Time </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.length > 0 ? (
                            data?.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: user.user_id, customer_name: user.phone }
                                                })
                                            }
                                        >
                                            {user.customer}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">{user.name}</TableCell>
                                    <TableCell>{user.client_id}</TableCell>
                                    <TableCell>{rupeeFormat(user.avaliable_balance)}</TableCell>
                                    <TableCell
                                        sx={{
                                            textTransform: 'capitalize',
                                            color: user.is_login ? 'plus.main' : 'error.main'
                                        }}
                                    >
                                        {String(user.is_login)}
                                    </TableCell>
                                    <TableCell>{getKeyStyles(user.status)}</TableCell>
                                    <TableCell>{user.last_login}</TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={11}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>
        </>
    );
};

export default UserLogin;
