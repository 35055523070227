//react
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { toast } from 'react-toastify';

//mui
import CircularProgress from '@mui/material/CircularProgress';
import useSWR from 'swr';
import { Box } from '@mui/material';

const Oauth = () => {
    const data = useSelector((state) => state?.reducer);
    const { search } = useLocation();

    const paramsData = qs.parse(search);

    const { data: swrData } = useSWR([
        '/socket-update',
        // '/user-add-auth-broker',
        {
            user_id: data?.user_detail?._id,
            ...data?.broker_id,
            redirection_data: JSON.stringify({
                ...paramsData,
                user_id: data?.user_detail?._id,
                ...data?.broker_id
            })
        }
    ]);

    const toastS = () => {
        toast.success(swrData.message);

        return <Navigate to="/settings" />;
    };

    return swrData?.status ? (
        toastS()
    ) : (
        <Box position="absolute" top={0} left={0} right={0} bottom={0} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>
    );
};

export default Oauth;
