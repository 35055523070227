//mui
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//chart
import { Bar, BarChart, Cell, Tooltip, XAxis } from 'recharts';
import { barChartData } from '.';

import ScrollContainer from 'react-indiana-drag-scroll';

const BarChart2 = ({ data, loading = false, height = 170, bgcolor = '', sx = { overflowX: 'auto' } }) => {
    return (
        <ScrollContainer vertical={false}>
            {data?.length > 0 ? (
                <Grid style={{ whiteSpace: 'nowrap' }}>
                    <BarChart width={data.length * 20} height={height} data={barChartData(data)}>
                        <Bar dataKey="value" barSize={12}>
                            {barChartData(data).map((entry, index) => (
                                <Cell fill={entry.color} key={`cell-${index}`} spacing={2.5} />
                            ))}
                        </Bar>
                        <XAxis
                            dataKey="date"
                            minTickGap={0}
                            tickSize={4}
                            tick={{ fontSize: '11px', stroke: '#ffffffcc', strokeWidth: 0.1 }}
                            tickLine={false}
                        />
                        <Tooltip
                            cursor={{ fill: 'transparent' }}
                            contentStyle={{ backgroundColor: '#283046', border: 'none', color: 'white', padding: '0' }}
                            itemStyle={{ color: 'white', padding: '2px 7px 1px' }}
                            labelStyle={{ display: 'none' }}
                            formatter={(value, name, props) => [props.payload.color === '#EA5455' ? `-${value}` : value, '']}
                            separator={''}
                        />
                    </BarChart>
                </Grid>
            ) : loading ? (
                <Grid container>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container pt={`${(height - 40) / 2}px`}>
                    <Typography variant="h4">No data</Typography>
                </Grid>
            )}
        </ScrollContainer>
    );
};
export default BarChart2;
