import {
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Download, Export, EyeFilled, Paid, Unpaid } from '../../../files/icon';
import Api from '../../../api';
import Details from './Details';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../../common';
import Search from '../../common/Search';
import { getColor } from '../../Customers/api';
import { getFilters } from '../../Trade/api';
import { useDispatch, useSelector } from 'react-redux';
import { applyInvoiceFilter, clearInvoiceFilter } from '../../../store/filter';

export const getKey = (key) => {
    if (key === 'paid') {
        return <Paid />;
    }
    if (key === 'unpaid') {
        return <Unpaid />;
    }
};

const Invoice = () => {
    const navigate = useNavigate();
    const { transaction_invoice_edit } = usePermission(['transaction-invoice-edit']);
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.invoice);
    const [selected, setSelected] = useState([]);
    const [invoice_id, setId] = useState(null);
    const { data: tradeList, totalRecords, isLoading, paginationProp, rest } = usePagination('/invoices-list', getFilters(Filter));

    const selectAll = (e) => {
        if (e.target.checked) {
            setSelected(tradeList?.map((invoice) => invoice.id));
        } else {
            setSelected([]);
        }
    };

    const onExport = async () => {
        try {
            const response = await Api.post('/invoices-export', getFilters(Filter), {
                responseType: 'arraybuffer'
            });

            const date = new Date();

            const outputFilename = `Invoice ${date.toLocaleString('en-IN')}.xls`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const setFilter = (data) => {
        dispatch(applyInvoiceFilter(data));
    };

    const onDownload = async (data) => {
        try {
            const response = await Api.post(
                '/download-invoices',
                { invoice_ids: data },
                {
                    responseType: 'arraybuffer'
                }
            );

            const date = new Date();

            const outputFilename = `Invoices ${date.toLocaleString('en-IN')}.${data?.length < 2 ? 'pdf' : 'zip'}`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Typography fontSize={'120%'}>Invoices({totalRecords || 0})</Typography>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'invoice') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearInvoiceFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                {transaction_invoice_edit && (
                    <>
                        <Grid item xs="auto">
                            <Button sx={{ py: '1px' }} onClick={() => onDownload(selected)}>
                                Download
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={onExport}>
                                <Export />
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            <TableContainer sx={{ height: 'calc(100% - 49px)' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {transaction_invoice_edit && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selected.length === tradeList?.length && tradeList?.length > 0}
                                        onChange={selectAll}
                                    />
                                </TableCell>
                            )}
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Invoice Date</TableCell>
                            <TableCell>Invoice No.</TableCell>
                            <TableCell align="left">Service</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>GST</TableCell>
                            <TableCell>Payment</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ marginBottom: '12px' }}>
                        {tradeList && tradeList?.length > 0 ? (
                            tradeList?.map((trade) => (
                                <TableRow key={trade.id}>
                                    {transaction_invoice_edit && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selected.includes(trade.id) || false}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelected((prev) => [...prev, trade.id]);
                                                    } else {
                                                        setSelected((prev) => prev.filter((id) => id !== trade.id));
                                                    }
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: trade.user_id, customer_name: trade.customer_phone }
                                                })
                                            }
                                        >
                                            {trade.customer}
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ py: '12px' }}>{trade.invoice_date}</TableCell>
                                    <TableCell>{trade.invoice_no}</TableCell>
                                    <TableCell align="left" sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        {`${trade.service_type} - ${trade.service_subtype}`}
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.price)) }}>{rupeeFormat(trade.price)}</TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.gst)) }}>{rupeeFormat(trade.gst)}</TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.payment)) }}>{rupeeFormat(trade.payment)}</TableCell>

                                    <TableCell>
                                        {transaction_invoice_edit && (
                                            <Button variant="header" fullWidth={false} onClick={() => onDownload([trade.id])}>
                                                <Download />
                                            </Button>
                                        )}

                                        <IconButton onClick={() => setId(trade.id)} sx={{ pl: '10px' }}>
                                            <EyeFilled />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={13}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={13}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter sx={{ position: 'sticky', bottom: 0, left: 0 }}>
                        <TableRow>
                            <TableCell colSpan={5} sx={{ padding: '0 16px' }}>
                                {paginationProp.count > 1 && (
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        bgcolor="background.default"
                                        sx={{ borderRadius: '0 0 5px 5px' }}
                                    >
                                        <Pagination
                                            {...paginationProp}
                                            color="primary"
                                            onChange={(e, v) => setFilter({ current_page: v })}
                                        />
                                    </Grid>
                                )}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '5px 0px 0px 5px',
                                    borderRight: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_price || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_price || 0)}
                            </TableCell>

                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_gst || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_gst || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '0px 5px 5px 0px',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_payment || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_payment || 0)}
                            </TableCell>
                            {transaction_invoice_edit && <TableCell></TableCell>}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            {invoice_id && <Details invoice_id={invoice_id} setId={setId} />}
        </>
    );
};

export default Invoice;
