import { CircularProgress, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { Bar, BarChart, Cell, Tooltip, XAxis } from 'recharts';
import useSWR from 'swr';
import { rupeeFormat } from '.';
import { getColor, Stastics } from '../Customers/api';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <Grid bgcolor="secondary.main" px="10px" pb="7px" pt="7px" borderRadius="5px" boxShadow={5}>
                {payload.map((item) => (
                    <Typography variant="body2" color={item.payload.color} key={item.name}>
                        {rupeeFormat(item.value)}
                    </Typography>
                ))}
            </Grid>
        );
    }

    return null;
};

const getLineChartData = (data) => {
    return data
        ?.sort((a, b) => {
            const [firstD, firstM, firstY] = a[0].split('-');
            const [secondD, secondM, secondY] = b[0].split('-');
            return new Date(firstY, firstM - 1, firstD) - new Date(secondY, secondM - 1, secondD);
        })
        ?.map((bar) => ({ date: bar[0], value: Math.abs(bar[1]), color: bar[1] > 0 ? '#49D617' : '#EA5455' }))
        .reverse();
};

const BarChart1 = ({ url, title, theme, theme2, applyToChart }) => {
    const [Filter, setFilter] = useState({ month: 'Last 30 Days', year: String(new Date().getFullYear()) });
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    const { data: chart } = useSWR(['/chart-list', { user_id, year: Filter.year }], { revalidateIfStale: false });
    const { data, error } = useSWR([url, { user_id, ...Filter }]);

    return (
        <Grid sx={{ width: '100%', borderRadius: '5px' }} p="15px" bgcolor="secondary.main" boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
            <Grid container columnGap="25px">
                <Grid item xs={6} bgcolor="background.default" p="10px" borderRadius="5px">
                    <Grid container columnGap="15px">
                        <Grid item xs="auto">
                            <Select
                                variant="standard"
                                value={Filter?.month}
                                onChange={(e, v) => {
                                    setFilter((prev) => ({ ...prev, month: e.target.value }));
                                }}
                                fullWidth={false}
                                defaultValue={data?.default_data?.month || 'Last 30 Days'}
                                sx={{ border: 'none', bgcolor: 'secondary.main' }}
                            >
                                {chart ? (
                                    chart?.month_data?.map((month) => (
                                        <MenuItem key={month} value={month}>
                                            {month}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value={'Last 30 Days'}>Last 30 Days</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs="auto">
                            <Select
                                variant="standard"
                                value={Filter?.year}
                                onChange={(e, v) => {
                                    setFilter((prev) => ({ ...prev, year: e.target.value }));
                                }}
                                fullWidth={false}
                                defaultValue={data?.default_data?.year || '2022'}
                                sx={{ border: 'none', bgcolor: 'secondary.main' }}
                            >
                                {chart ? (
                                    chart?.year_data?.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value={String(new Date().getFullYear())}>{String(new Date().getFullYear())}</MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs></Grid>
                        {Object.keys(data?.data?.headers_data || {}).map((key) => (
                            <Grid item xs="auto" key={key}>
                                <Typography textAlign="right" textTransform="capitalize">
                                    {key}
                                </Typography>
                                <Typography color={getColor(data?.data?.headers_data[key])} textAlign="right">
                                    {rupeeFormat(data?.data?.headers_data[key])}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container={data?.length > 0 ? false : true}>
                        {data?.data?.chart_data?.length > 0 ? (
                            <ScrollContainer vertical={false} style={{ width: '100%', height: '150px' }}>
                                <BarChart
                                    data={getLineChartData(data?.data?.chart_data)}
                                    maxBarSize={12}
                                    width={getLineChartData(data?.data?.chart_data).length * 30}
                                    height={150}
                                >
                                    <Bar dataKey="value">
                                        {getLineChartData(data?.data?.chart_data).map((entry, index) => (
                                            <Cell fill={applyToChart ? theme : entry.color} key={`cell-${index}`} spacing={2} />
                                        ))}
                                    </Bar>
                                    <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(242, 242, 242, 0.1)' }} />
                                    <XAxis
                                        dataKey="date"
                                        tickFormatter={(prev) => {
                                            return prev?.substring(0, 2) || '';
                                        }}
                                        tickSize={4}
                                        tick={{ fontSize: '11px', stroke: '#ffffffcc', strokeWidth: 0.1 }}
                                        tickLine={false}
                                    />
                                </BarChart>
                            </ScrollContainer>
                        ) : !data && !error ? (
                            <CircularProgress />
                        ) : (
                            <Typography textAlign="center" variant="h3" pb={1}>
                                No Data
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Grid container spacing="20px">
                        {data &&
                            Object.keys(data?.data?.statistics_data).map((key) => (
                                <Grid key={key} item xs={3}>
                                    <Stastics name={key.replaceAll('_', ' ')} value={data?.data?.statistics_data[key]} />
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default BarChart1;
