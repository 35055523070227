import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Edit } from '../../../files/icon';
import { getKeyStyles } from '../../Customers/api';
import AddPermission from './AddPermission';

const Permissions = () => {
    const [edit, setEdit] = useState();
    const { data, error, mutate } = useSWR('/permission-list');
    return (
        <>
            <Grid container bgcolor="secondary.main" p="10px" borderRadius="5px 5px 0px 0px">
                <Grid item xs>
                    <Typography variant="h6">Permissions</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Button sx={{ py: '2px' }} onClick={() => setEdit(true)}>
                        ADD
                    </Button>
                </Grid>
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 53px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.data?.length > 0 ? (
                            data?.data.map((permission) => (
                                <TableRow key={permission.permission_id}>
                                    <TableCell align="left">{permission.name}</TableCell>
                                    <TableCell>{permission.type.name}</TableCell>
                                    <TableCell>{permission.code}</TableCell>
                                    <TableCell>{getKeyStyles(permission.status)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setEdit(permission)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : !data && !error ? (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>No data</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {edit && <AddPermission data={edit} onClose={() => setEdit(null)} mutate={mutate} />}
        </>
    );
};
export default Permissions;
