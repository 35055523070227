import { Complete, Rejected } from '../../files/icon';

export const getFilters = (data) => {
    const { date_time, dates_from, dates_to, ...rest } = data;

    return {
        current_page: 1,
        item_per_page: 50,
        ...rest,
        ...(dates_from && {
            dates_from: dates_from.replaceAll('/', '-'),
            dates_to: dates_from.replaceAll('/', '-')
        }),
        ...(dates_to && {
            dates_to: dates_to.replaceAll('/', '-')
        }),
        date_time
    };
};

export const getOrderKey = (key) => {
    if (key === 'rejected') {
        return <Rejected />;
    }
    if (key === 'complete') {
        return <Complete />;
    }
};
