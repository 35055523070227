import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { EditText, Expand, Home, Active, Expired } from '../../files/icon';
import { usePermission } from '../common';
import AddSubscription from './AddSubscription';
import { CustomList } from './api';
import DetailsEditModal from './DetailsEditModal';

const setExpiryDate = (startDate) => {
    const [date, time] = startDate.split(' ');
    const [day, month, year] = date.split('-');
    const [hour, minutes, second] = time.split(':');
    const dateObj = new Date(year, month - 1, day, hour, minutes, second);
    const nowObj = new Date();

    return dateObj.getTime() > nowObj.getTime() ? startDate.replaceAll('-', '/') : '';
};

const Details = () => {
    const {
        state: { user_id }
    } = useLocation();
    const navigate = useNavigate();
    const [edit, setEdit] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [expanded, setExpanded] = useState(0);
    const { data, error, mutate } = useSWR(['/customers-details', { user_id }]);
    const { customers_edit } = usePermission(['customers-edit']);

    return (
        <>
            {!data && !error && (
                <Grid container py={'16px'}>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Breadcrumbs sx={{ pb: '3px' }}>
                        <IconButton onClick={() => navigate('/')} size="small" sx={{ padding: 0, pb: '4px' }}>
                            <Home />
                        </IconButton>
                        <Button
                            variant="header"
                            sx={{ color: (theme) => theme.palette.primary.main }}
                            onClick={() => navigate('/customers')}
                        >
                            Customers
                        </Button>
                        <Button variant="header" sx={{ color: (theme) => theme.palette.primary.main }} disabled={true}>
                            {data?.name}
                        </Button>
                    </Breadcrumbs>
                    <Grid
                        container
                        alignItems={'start'}
                        bgcolor="secondary.main"
                        sx={{ borderRadius: '5px' }}
                        boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                    >
                        <Grid item xs={12} columnGap="12px" container p="12px" sx={{ borderRadius: '5px' }}>
                            <Grid item xs="auto">
                                <Typography variant="h6" display="inline-block">
                                    {data?.name}
                                </Typography>
                                <Typography variant="body2" pt="8px" display="inline-block" pl="5px">
                                    {`(${data?.registration_date})`}
                                </Typography>
                            </Grid>
                            <Grid item xs></Grid>
                            <Grid item xs="auto">
                                <Typography variant="body2" color="text.secondary" display="inline-block">
                                    Subscription Expiry :
                                </Typography>
                                <Typography variant="body2" pt="8px" pl="5px" display="inline-block">
                                    {data?.subscription_expiry_date}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto" pt="10px">
                                {data?.subscription === 'Active' ? <Active /> : <Expired />}
                            </Grid>
                            {customers_edit && (
                                <Grid item xs="auto">
                                    <Button
                                        sx={{ py: '4px' }}
                                        onClick={() =>
                                            setSubscription({
                                                id: data?.id,
                                                start_date: setExpiryDate(data?.subscription_expiry_date)
                                            })
                                        }
                                    >
                                        Add Subscription
                                    </Button>
                                </Grid>
                            )}
                            {customers_edit && (
                                <Grid item xs="auto">
                                    <Button onClick={() => setEdit(data || {})}>
                                        <EditText />
                                    </Button>
                                </Grid>
                            )}
                        </Grid>

                        <Grid item md sx={{ borderRadius: '5px' }} pb={'12px'}>
                            <CustomList
                                object={{
                                    ...Object.keys(data?.data)
                                        .slice(0, 2)
                                        .reduce((acc, cv) => ({ ...acc, [cv]: data?.data[cv] }), {}),
                                    ...Object.keys(data?.data)
                                        .slice(3, Object.keys(data?.data).length)
                                        .reduce((acc, cv) => ({ ...acc, [cv]: data?.data[cv] }), {})
                                }}
                                columns={1}
                            />
                            <Grid container pl="30px" py="5px" bgcolor="#1F273B">
                                <Grid item xs={5} lg={4} color="text.secondary" textTransform="capitalize">
                                    <Typography variant="body2">Referred by</Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography component="span" pr="12px">
                                        :
                                    </Typography>
                                    <Button
                                        disabled={data?.data?.referred_by === 'N/A'}
                                        onClick={() =>
                                            navigate('/customers/details', {
                                                state: { user_id: data?.referred_id, customer_name: data?.referred_phone }
                                            })
                                        }
                                        fullWidth={false}
                                        sx={{ py: '0px', '&.Mui-disabled': { color: 'text.primary' } }}
                                        variant="header"
                                    >
                                        {data?.data?.referred_by}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md bgcolor="secondary.main" pb="12px" ml="10px" sx={{ borderRadius: '5px' }}>
                            {['billing_details', 'bank_details', 'referral_billing_details'].map((key, index) => (
                                <Accordion
                                    onChange={() => setExpanded((prev) => (prev === index ? '' : index))}
                                    expanded={expanded === index}
                                    key={index}
                                    sx={{ pt: '5px' }}
                                >
                                    <AccordionSummary
                                        expandIcon={<Expand />}
                                        sx={{
                                            background: '#161D31',
                                            borderRadius: '2px',
                                            maxHeight: '34px',
                                            alignItems: 'center',
                                            minHeight: '34px',
                                            '& .Mui-expanded': {
                                                maxHeight: '34px',
                                                alignItems: 'center',
                                                minHeight: '34px'
                                            }
                                        }}
                                    >
                                        <Typography fontSize="110%" textTransform="capitalize">
                                            {key.replaceAll('_', ' ')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container justifyContent="space-evenly" alignItems="flex-start">
                                            {Object.keys(data?.[key]).length > 0 ? (
                                                <CustomList object={data?.[key]} columns={1} />
                                            ) : (
                                                <Typography py="5px" variant="h6">
                                                    No data
                                                </Typography>
                                            )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Grid>

                        {edit && (
                            <DetailsEditModal
                                edit={{
                                    ...edit,
                                    ...(Object.keys(data?.bank_details).length === 0 && {
                                        bank_details: {
                                            bank_name: '',
                                            account_no: '',
                                            account_name: '',
                                            account_type: '',
                                            ifsc_code: ''
                                        }
                                    }),
                                    ...(Object.keys(data?.billing_details).length === 0 && {
                                        billing_details: {
                                            name: '',
                                            company_name: '',
                                            GST: '',
                                            address_line_1: '',
                                            address_line_2: '',
                                            pincode: '',
                                            state: null,
                                            city: null
                                        }
                                    }),
                                    ...(Object.keys(data?.referral_billing_details).length === 0 && {
                                        referral_billing_details: {
                                            name: '',
                                            company_name: '',
                                            pan_no: '',
                                            aadhar_no: '',
                                            address_line_1: '',
                                            address_line_2: '',
                                            pincode: '',
                                            state: null,
                                            city: null
                                        }
                                    })
                                }}
                                setEdit={setEdit}
                                mutate={mutate}
                            />
                        )}
                        {subscription && <AddSubscription data={subscription} onClose={() => setSubscription()} mutate={mutate} />}
                    </Grid>
                </>
            )}
        </>
    );
};

export default Details;
