import { Grid } from '@mui/material';
import Permission from '../components/more/Roles/Permission';
import Roles from '../components/more/Roles/Roles';
import Type from '../components/more/Roles/Type';

const RolesPage = () => {
    return (
        <>
            <Grid container height="calc(50% - 7px)" gap="15px" pb="7px" alignItems="flex-start">
                <Grid item xs borderRadius="5px" height="100%">
                    <Roles />
                </Grid>
                <Grid item xs height="100%">
                    <Type />
                </Grid>
            </Grid>
            <Grid height="calc(50% - 7px)" pt="7px">
                <Permission />
            </Grid>
        </>
    );
};
export default RolesPage;
