import { Button, CircularProgress, Grid, Input, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AsyncForm from '../common/AsyncForm';
import useSWR from 'swr';
import { fetcher } from '../../api';
import { toast } from 'react-toastify';
import { usePermission } from '../common';

const Policies = () => {
    const [active, setActive] = useState({ title: 'Terms and Conditions', description: '' });
    const { data, isValidating, mutate } = useSWR(['/pages-data', { title: active.title }]);
    const { setting_edit } = usePermission(['setting-edit']);

    useEffect(() => {
        data && data?.pages_data?.[0] && setActive(data?.pages_data?.[0]);
    }, [data]);

    const submit = async () => {
        if (active?.description.length > 0) {
            const response = await fetcher('/pages-data-update', active);

            if (response.status) {
                mutate();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        } else {
            toast.error('Please enter page detail');
        }
    };

    return (
        <Grid item xs={12} mt={'25px'} bgcolor="secondary.main" borderRadius="5px" p="10px" boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
            <Typography variant="h6">Policies</Typography>
            <Grid container columnGap={'12px'} py={'10px'} justifyContent="flex-start">
                <Grid item xs="auto">
                    <Button
                        variant={active?.title === 'Terms and Conditions' ? 'text' : 'outlined'}
                        onClick={() => setActive({ title: 'Terms and Conditions', description: '' })}
                    >
                        Term & Conditions
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant={active?.title === 'Privacy Policy' ? 'text' : 'outlined'}
                        onClick={() => setActive({ title: 'Privacy Policy', description: '' })}
                    >
                        Privacy Policy
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant={active?.title === 'Disclaimer' ? 'text' : 'outlined'}
                        onClick={() => setActive({ title: 'Disclaimer', description: '' })}
                    >
                        Disclaimer
                    </Button>
                </Grid>
            </Grid>
            <AsyncForm
                onSubmit={submit}
                buttonProps={{ fullWidth: false, disabled: !setting_edit, ...(!setting_edit && { sx: { display: 'none' } }) }}
            >
                {isValidating ? (
                    <Grid container bgcolor="background.default" sx={{ height: '469px' }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Input
                        value={active?.description}
                        multiline
                        minRows={20}
                        maxRows={20}
                        onChange={(e) => setActive((prev) => ({ ...prev, description: e.target.value }))}
                        sx={{ border: 'none', background: (theme) => theme.palette.background.default }}
                    />
                )}
            </AsyncForm>
        </Grid>
    );
};
export default Policies;
