import { Button, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Edit } from '../../files/icon';
import { usePermission } from '../common';
import TaxInfoEdit from './TaxInfoEdit';

const Taxinfo = () => {
    const { data, mutate } = useSWR('/taxinfo-list');
    const [edit, setEdit] = useState();
    const { setting_edit } = usePermission(['setting-edit']);
    return (
        <>
            <Grid item container xs bgcolor="secondary.main" borderRadius="5px" pt="10px" boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
                <Grid item xs pl="10px" pb="10px">
                    <Typography variant="h6">GST</Typography>
                </Grid>
                <Grid item xs></Grid>
                {setting_edit && (
                    <Grid item xs="auto" pr="10px" pb="10px">
                        <Button sx={{ py: '2px' }} onClick={() => setEdit({ data: { name: '', rate: '' }, name: 'Add GST' })}>
                            ADD GST
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {data ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Rate</TableCell>
                                    {setting_edit && <TableCell>Action</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(data?.taxinfo)
                                    .filter((key) => key.includes('gst'))
                                    .map((key) => (
                                        <TableRow key={key}>
                                            <TableCell sx={{ textTransform: 'uppercase' }}>{key.replace('_rate', '')}</TableCell>
                                            <TableCell>{data?.taxinfo?.[key]}</TableCell>
                                            {setting_edit && (
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            setEdit({ data: { name: key, rate: data?.taxinfo?.[key] }, name: 'Edit GST' })
                                                        }
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Grid container>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item container xs bgcolor="secondary.main" borderRadius="5px" pt="10px" boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
                <Grid item xs pl="10px" pb="10px">
                    <Typography variant="h6">TDS</Typography>
                </Grid>
                {setting_edit && (
                    <Grid item xs="auto" pr="10px" pb="10px">
                        <Button sx={{ py: '2px' }} onClick={() => setEdit({ data: { name: '', rate: '' }, name: 'Add TDS' })}>
                            ADD TDS
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {data ? (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Rate</TableCell>
                                    {setting_edit && <TableCell>Action</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(data?.taxinfo)
                                    .filter((key) => key.includes('tds'))
                                    .map((key) => (
                                        <TableRow key={key}>
                                            <TableCell sx={{ textTransform: 'uppercase' }}>{key.replace('_rate', '')}</TableCell>
                                            <TableCell>{data?.taxinfo?.[key]}</TableCell>
                                            {setting_edit && (
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() =>
                                                            setEdit({ data: { name: key, rate: data?.taxinfo?.[key] }, name: 'Edit TDS' })
                                                        }
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Grid container>
                            <CircularProgress />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {edit && <TaxInfoEdit data={edit} onClose={() => setEdit(null)} mutate={mutate} />}
        </>
    );
};
export default Taxinfo;
