import { Autocomplete, Dialog, FormControlLabel, Grid, Input, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';
import Api from '../../api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import Button from '../common/Button';
import { toast } from 'react-toastify';
import { createFilterOptions } from '@mui/material/Autocomplete';
import SwitchEdited from '../common/SwitchEdited';
import ConfirmStatus from './ConfirmStatus';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.name} ${option.phone}`,
    ignoreAccents: true,
    ignoreCase: true,
    limit: 10,
    trim: true
});

const StrategyAddDialog = ({ strategyDetail, setStrategyDetail, mutate }) => {
    const { data, mutate: editStrate } = useSWR(
        typeof strategyDetail === 'string' ? ['/edit-strategy', { strategy_id: strategyDetail }] : ''
    );
    const { data: users, isLoading } = useSWR('/strategy-users', { revalidateIfStale: false });
    const [state, setState] = useState();

    const keys = useMemo(
        () => [
            'name',
            'code',
            'created_by',
            'min_capital',
            'exchange',
            'segment',
            'instrument',
            'standard_lot_quantity',
            'max_lot_multiplyer',
            'max_lots',
            'strategy_type',
            'duration_delta',
            'start_day',
            'days_delta',
            'start_time'
        ],
        []
    );

    const schema = yup.object().shape({
        ...keys.reduce(
            (acc, key) => ({
                ...acc,
                [key]: yup
                    .string()
                    .required(`${key.replaceAll('per_', '').replaceAll('_', ' ')} is required`)
                    .nullable()
            }),
            {}
        ),
        duration_delta: yup.number().required().min(0, 'duration delta must be positive'),
        days_delta: yup.number().required().min(0, 'days delta must be positive'),
        is_public:
            typeof strategyDetail === 'string' ? yup.string().nullable() : yup.string().required('this field is required').nullable(),
        user_list: yup.array().when('is_public', {
            is: (is_public) => is_public === 'No',
            then: yup.array().required(),
            otherwise: yup.array()
        }),
        is_send: yup.string().required('this field is required').nullable()
    });

    const {
        register,
        formState: { errors },
        control,
        watch,
        reset,
        handleSubmit,
        setValue
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            is_public: 'No',
            created_by: 'Coretus',
            instrument: 'Bank Nifty',
            standard_lot_quantity: 25,
            status: true,
            exchange: 'NSE',
            segment: 'NFO',
            max_lot_multiplyer: 36,
            max_lots: 100,
            strategy_type: 'intraday',
            start_day: 'All',
            start_time: '09:10:00',
            duration_delta: 0,
            days_delta: 0,
            user_list: [],
            is_send: 'No'
        }
    });

    useEffect(() => {
        if (data?.data) {
            let values = { ...data?.data };
            values.user_list = data?.data?.['user_list'].map((id) => users?.user_data?.find((user) => user.id === id) || {});
            reset(values);
        }
    }, [data, keys, reset, users]);

    const addStrategy = async (data) => {
        const { user_list, code, ...rest } = data;

        const response = await Api.post('/add-strategy', {
            ...rest,
            user_list: user_list?.map((user) => user.id),
            ...(typeof strategyDetail === 'boolean' ? { code } : { strategy_id: strategyDetail, code })
        });

        if (response.data.status) {
            toast(response.data.message);
            setStrategyDetail(null);
            mutate();
            editStrate();
        } else {
            toast.error(response.data.message);
        }
    };

    const getKey = (key = '') => {
        if (key === 'standard_lot_quantity') {
            return 'Standard Lotsize';
        }
        if (key === 'max_lot_multiplyer') {
            return 'Max Lots Per Trade';
        }
        if (key === 'max_lots') {
            return 'Max Live Lots';
        }
        return key;
    };

    const getOption = (key) => {
        if (key === 'strategy_type') {
            return ['intraday', 'weekly', 'monthly', 'carry_forward'];
        }
        if (key === 'exchange') {
            return ['NSE', 'BSE', 'MCX'];
        }
        if (key === 'segment') {
            return ['NFO', 'CDS'];
        }
        if (key === 'start_day') {
            return ['All', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        }
        return [];
    };

    const getCapitalize = (option) => {
        const str = option.split('_');

        return str.map((opt) => opt.charAt(0).toUpperCase() + opt.slice(1)).join(' ');
    };

    return (
        <>
            <Dialog open={Boolean(strategyDetail)} onClose={() => setStrategyDetail(null)} PaperProps={{ sx: { position: 'relative' } }}>
                <Typography variant="h6" textAlign="center" pb={1}>
                    {typeof strategyDetail === 'string' ? 'Edit' : 'Add'} Strategy Details
                </Typography>
                <Grid container sx={{ position: 'absolute', top: '10px', width: '170px', right: '10px' }}>
                    <Typography color={watch('status') ? '' : 'error.main'} fontWeight={700}>
                        Deactive
                    </Typography>

                    <SwitchEdited
                        color="plus"
                        checked={watch('status')}
                        onChange={(e, v) => {
                            setState({ status: v });
                        }}
                    />

                    <Typography color={watch('status') ? 'plus.main' : ''} fontWeight={700}>
                        Active
                    </Typography>
                </Grid>
                <Grid container justifyContent="space-between" alignItems="flex-start" px={3}>
                    {keys.map((key) => {
                        if (['exchange', 'segment', 'strategy_type', 'start_day'].includes(key)) {
                            return (
                                <Grid item xs={5.5} height="78px" key={key}>
                                    <Typography textTransform="capitalize" variant="body2">
                                        {getKey(key).replaceAll('per_', '').replaceAll('_', ' ')}
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name={key}
                                        render={({ field: { onChange, onBlur, value } }) => (
                                            <Autocomplete
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                onChange={(e, v) => onChange(v)}
                                                onBlur={onBlur}
                                                value={value}
                                                options={getOption(key)}
                                                getOptionLabel={(option) =>
                                                    ['strategy_type', 'start_day'].includes(key) ? getCapitalize(option) : option
                                                }
                                            />
                                        )}
                                    />
                                    <Typography variant="body2" color="error">
                                        {errors?.[key]?.message}
                                    </Typography>
                                </Grid>
                            );
                        } else if (key === 'start_time') {
                            return (
                                <Grid item xs={5.5} height="78px" key={key}>
                                    <Typography textTransform="capitalize" variant="body2">
                                        {getKey(key).replaceAll('per_', '').replaceAll('_', ' ')}
                                    </Typography>
                                    <Controller
                                        control={control}
                                        name={key}
                                        render={({ field: { onChange, name, value } }) => (
                                            <>
                                                <DatePicker
                                                    disableDayPicker
                                                    name={name}
                                                    value={
                                                        new DateObject({
                                                            hour: value?.split(':')[0],
                                                            minute: value?.split(':')[1],
                                                            second: value?.split(':')[2]
                                                        })
                                                    }
                                                    format="HH:mm:ss"
                                                    plugins={[<TimePicker />]}
                                                    onChange={(date) => {
                                                        onChange({ target: { name: name, value: date.format() } });
                                                    }}
                                                    containerStyle={{ width: '100%', background: 'transparent' }}
                                                    style={{
                                                        width: '100%',
                                                        height: '35px',
                                                        border: errors?.start_time?.message
                                                            ? '2px solid #EA5455'
                                                            : '2px solid rgba(115, 103, 240, 0.5)',
                                                        background: 'rgba(115, 103, 240, 0.06)',
                                                        color: '#ffffff',
                                                        fontFamily: 'Givonic',
                                                        fontSize: '100%'
                                                    }}
                                                    className="custom-calendar"
                                                    calendarPosition="bottom-center"
                                                />
                                            </>
                                        )}
                                    />
                                    <Typography variant="body2" color="error">
                                        {errors?.[key]?.message}
                                    </Typography>
                                </Grid>
                            );
                        } else {
                            return (
                                <Grid item xs={5.5} height="78px" key={key}>
                                    <Typography textTransform="capitalize" variant="body2">
                                        {getKey(key).replaceAll('per_', '').replaceAll('_', ' ')}
                                    </Typography>
                                    <Input
                                        {...register(key, {
                                            ...(key === 'code' && {
                                                onChange: (e) => {
                                                    e.target.value = e.target.value.toUpperCase();
                                                    return e;
                                                }
                                            })
                                        })}
                                        disabled={typeof strategyDetail === 'string' && key === 'code'}
                                        {...([
                                            'standard_lot_quantity',
                                            'max_lot_multiplyer',
                                            'max_lots',
                                            'min_capital',
                                            'days_delta',
                                            'duration_delta'
                                        ].includes(key) && {
                                            type: 'number'
                                        })}
                                    />
                                    <Typography variant="body2" color="error">
                                        {errors?.[key]?.message}
                                    </Typography>
                                </Grid>
                            );
                        }
                    })}
                    <Grid item xs={5.5} mb="16px">
                        <Typography textTransform="capitalize" variant="body2">
                            Send Notification
                        </Typography>
                        <Controller
                            control={control}
                            name={'is_send'}
                            defaultValue={'No'}
                            render={({ field }) => (
                                <RadioGroup row {...field}>
                                    <FormControlLabel value="Yes" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio size="small" />} label="No" />
                                </RadioGroup>
                            )}
                        />
                        <Typography variant="body2" color="error">
                            {errors?.is_send?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs={5.5} mb="16px">
                        <Typography textTransform="capitalize" variant="body2">
                            Public
                        </Typography>
                        <Controller
                            control={control}
                            name={'is_public'}
                            render={({ field }) => (
                                <RadioGroup row {...field}>
                                    <FormControlLabel value="Yes" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio size="small" />} label="No" />
                                </RadioGroup>
                            )}
                        />
                        <Typography variant="body2" color="error">
                            {errors?.is_public?.message}
                        </Typography>
                        {watch('is_public') === 'No' && (
                            <>
                                <Controller
                                    control={control}
                                    name={'user_list'}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Autocomplete
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            multiple
                                            onChange={(e, v) => onChange(v)}
                                            onBlur={onBlur}
                                            value={value}
                                            filterOptions={filterOptions}
                                            options={users?.user_data || []}
                                            renderOption={(props, option) => (
                                                <li {...props} key={option.id}>
                                                    {option.name}
                                                </li>
                                            )}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            loading={isLoading}
                                        />
                                    )}
                                />
                                <Typography variant="body2" color="error">
                                    {errors?.user_list?.message}
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Button onClick={handleSubmit(addStrategy)} sx={{ width: '12%', margin: '0 auto', dispaly: 'block' }}>
                    Save
                </Button>
            </Dialog>
            {state && (
                <ConfirmStatus
                    data={state}
                    setValue={async () => {
                        setValue('status', state.status);
                        await handleSubmit(addStrategy)();
                        setState(null);
                    }}
                    onClose={() => setState(null)}
                />
            )}
        </>
    );
};

export default StrategyAddDialog;
