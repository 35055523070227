import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { Fragment, useState } from 'react';
import { fetcher } from '../../api';
import { Edit } from '../../files/icon';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../common/Button';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { usePermission } from '../common';

const Extraform = ({ data, title, radio = [], url, mutate }) => {
    const [edit, setEdit] = useState(false);
    const { setting_edit } = usePermission(['setting-edit']);
    const schema = yup
        .object()
        .shape(Object.keys(data).reduce((acc, cv) => ({ ...acc, [cv]: yup.string().required(`${cv} is required`) }), {}));

    const { register, control, reset, handleSubmit } = useForm({ mode: 'onTouched', resolver: yupResolver(schema), defaultValues: data });

    const submit = async (data) => {
        const send = Object.keys(data).reduce((acc, cv) => ({ ...acc, [cv.toLowerCase().replaceAll(' ', '_')]: data[cv] }), {});
        const response = await fetcher(url, send);

        if (response.status) {
            mutate();
            setEdit(false);
            toast(response.message);
        } else {
            toast.error(response.message);
        }
    };

    useEffect(() => {
        reset(data);
    }, [data, reset]);

    return (
        <Accordion sx={{ pt: '12px' }} expanded={true}>
            <AccordionSummary
                expandIcon={
                    setting_edit ? (
                        edit ? (
                            <Button sx={{ transform: 'rotate(180deg)' }} onClick={handleSubmit(submit)}>
                                Save
                            </Button>
                        ) : (
                            <IconButton onClick={() => setEdit(true)} sx={{ transform: 'rotate(180deg)' }}>
                                <Edit />
                            </IconButton>
                        )
                    ) : (
                        ''
                    )
                }
            >
                <Typography fontSize="110%" textTransform="capitalize">
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justifyContent="flex-start" alignItems="stretch">
                    {Object.keys(data).map((key1, index1) => (
                        <Fragment key={index1}>
                            <Grid item xs={2.1} py="9px" pl="25px" bgcolor={[3, 2, 7, 6].includes(index1) ? '#1F273B' : ''}>
                                {key1}
                            </Grid>
                            <Grid item xs={0.1} py="9px" bgcolor={[3, 2, 7, 6].includes(index1) ? '#1F273B' : ''}>
                                :
                            </Grid>
                            <Grid item xs={3.8} py="5px" pr="25px" bgcolor={[3, 2, 7, 6].includes(index1) ? '#1F273B' : ''}>
                                {radio.includes(key1) ? (
                                    <Controller
                                        control={control}
                                        name={key1}
                                        render={({ field }) => (
                                            <RadioGroup row {...field}>
                                                <FormControlLabel
                                                    value="Yes"
                                                    control={<Radio size="small" disabled={!edit} />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel value="No" control={<Radio size="small" disabled={!edit} />} label="No" />
                                            </RadioGroup>
                                        )}
                                    />
                                ) : (
                                    <Input
                                        readOnly={!edit}
                                        {...register(key1)}
                                        {...(!edit && { sx: { border: 'none', bgcolor: 'transparent' } })}
                                    />
                                )}
                            </Grid>
                        </Fragment>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
export default Extraform;
