import { Dialog, Typography, Grid, CircularProgress } from '@mui/material';
import useSWR from 'swr';
import { CustomList } from '../../Customers/api';

const Details = ({ membership_id, setId }) => {
    const { data, error } = useSWR(['/membership-details', { membership_id }]);

    return (
        <Dialog
            open={Boolean(membership_id)}
            onClose={() => setId(null)}
            PaperProps={{ sx: { padding: 0, pt: 3, pb: 4, maxWidth: '800px' } }}
        >
            {!data && !error && (
                <Grid container>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Typography fontSize="120%" px={3}>
                        {data?.customer}
                    </Typography>
                    {data?.status ? (
                        <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                            <CustomList object={data?.data?.membership_detail} lg={5} />
                        </Grid>
                    ) : (
                        <Grid container>No data</Grid>
                    )}
                    <Typography fontSize="120%" pt={2} px={3}>
                        Transaction Detail
                    </Typography>
                    {Object.keys(data?.data?.transaction_detail).length > 0 ? (
                        <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                            <CustomList object={data?.data?.transaction_detail} lg={5} />
                        </Grid>
                    ) : (
                        <Grid container>No data</Grid>
                    )}
                </>
            )}
        </Dialog>
    );
};
export default Details;
