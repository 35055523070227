import {
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Active, Edit, Expired, Export, EyeFilled, Upcoming } from '../../../files/icon';
import Api from '../../../api';
import Details from './Details';
import Button from '../../common/Button';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../../common';
import DateFilter from '../../Trade/DateFilter';
import { getPlans } from '../../Customers/api';
import useSWR from 'swr';
import Search from '../../common/Search';
import EditSubscription from './EditSubscription';
import { getFilters } from '../../Trade/api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { applySubscriptionFilter, clearSubscriptionFilter } from '../../../store/filter';

const getStatus = (status) => {
    if (status === 'Upcoming') {
        return <Upcoming />;
    }
    if (status === 'Active') {
        return <Active />;
    }
    if (status === 'Expired') {
        return <Expired />;
    }
};

const Membership = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.subscription);
    const [membershipId, setId] = useState(null);
    const [anhcorEl, setAnchorEl] = useState({});
    const { data: membership, totalRecords, isLoading, paginationProp, mutate } = usePagination('/membership-list', getFilters(Filter));
    const { data: plans } = useSWR('/premium-membership-plans', getPlans);

    const { transaction_subscriptions_edit } = usePermission(['transaction-subscriptions-edit']);

    const onExport = async () => {
        try {
            const response = await Api.post('/membership-export', getFilters(Filter), { responseType: 'arraybuffer' });

            const date = new Date();

            const outputFilename = `MembershipData ${date.toLocaleString('en-IN')}.xlsx`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const setFilter = (data) => {
        dispatch(applySubscriptionFilter(data));
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Typography fontSize={'120%'}>Subscriptions({totalRecords || 0})</Typography>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'subscription') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearSubscriptionFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(Boolean(anhcorEl?.date) ? {} : { date: e.currentTarget, payment: null, membership: null })
                        }
                        sx={{ py: '1px' }}
                    >
                        Date
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null, membership: null })}
                        PaperProps={{ sx: { width: '400px' } }}
                    >
                        <DateFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.payment) ? {} : { payment: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Payment Mode
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.payment)}
                        anchorEl={anhcorEl?.payment}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, payment: null })}
                        PaperProps={{ sx: { width: '125px' } }}
                    >
                        {['Apple Pay', 'UPI', 'Net Banking', 'Debit Card', 'Credit Card', 'Paytm Wallet'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.payment_mode === mode}
                                onClick={() => {
                                    setFilter({ payment_mode: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(Boolean(anhcorEl?.payment) ? {} : { date: null, payment: null, membership: e.currentTarget })
                        }
                        sx={{ py: '1px' }}
                    >
                        Subscription
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.membership)}
                        anchorEl={anhcorEl?.membership}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '125px' } }}
                    >
                        {plans ? (
                            plans?.data
                                ?.map((plan) => plan?.title)
                                .concat(['Free', 'All'])
                                .map((plan) => (
                                    <MenuItem
                                        key={plan}
                                        disabled={Filter?.membership_name === plan}
                                        onClick={() => {
                                            setFilter({ membership_name: plan });
                                            setAnchorEl({});
                                        }}
                                        sx={{
                                            py: '8px',
                                            borderRadius: '0px',
                                            '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                        }}
                                    >
                                        {plan === '' ? 'All' : plan}
                                    </MenuItem>
                                ))
                        ) : (
                            <Grid m="0 auto">
                                <CircularProgress />
                            </Grid>
                        )}
                    </Menu>
                </Grid>
                {transaction_subscriptions_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={onExport}>
                            <Export />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 49px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ zIndex: 2 }}>
                                Name
                            </TableCell>
                            <TableCell>Purchase Date</TableCell>
                            <TableCell>Subscription</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Start Date </TableCell>
                            <TableCell>Expiry Date</TableCell>
                            <TableCell>status</TableCell>
                            <TableCell sx={{ zIndex: 2 }}>action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {membership && membership?.length > 0 ? (
                            membership?.map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: order.user_id, customer_name: order.customer_phone }
                                                })
                                            }
                                        >
                                            {order.customer}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{order.purchase_date}</TableCell>
                                    <TableCell>{order.name}</TableCell>
                                    <TableCell>{rupeeFormat(order.price)}</TableCell>
                                    <TableCell>{order.start_date}</TableCell>
                                    <TableCell>{order.expiry_date}</TableCell>
                                    <TableCell>{getStatus(order.status)}</TableCell>
                                    <TableCell>
                                        {transaction_subscriptions_edit && (
                                            <IconButton
                                                onClick={() =>
                                                    setId({
                                                        membership_id: order.id,
                                                        purchase_date: order.purchase_date,
                                                        start_date: order.start_date,
                                                        expiry_date: order.expiry_date,
                                                        payment_mode: order.payment_mode
                                                    })
                                                }
                                                sx={{ pr: '10px' }}
                                            >
                                                <Edit />
                                            </IconButton>
                                        )}

                                        <IconButton onClick={() => setId(order.id)}>
                                            <EyeFilled />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={13}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={13}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>
            {membershipId && typeof membershipId == 'string' && <Details membership_id={membershipId} setId={setId} />}
            {membershipId && typeof membershipId == 'object' && (
                <EditSubscription membershipId={membershipId} onClose={() => setId(null)} mutate={mutate} />
            )}
        </>
    );
};

export default Membership;
