import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchFilters, usePagination, usePermission } from '../components/common';
import Search from '../components/common/Search';
import { getKeyStyles } from '../components/Customers/api';
import AddPromocodes from '../components/more/Promocodes/AddPromocodes';
import PlanDelete from '../components/more/subscription/PlanDelete';
import DateFilter from '../components/Trade/DateFilter';
import { Delete, Edit } from '../files/icon';
import { applyPromocodeFilter, clearPromocodeFilter } from '../store/filter';

const getFilters = (data) => {
    return {
        ...data,
        ...(data?.dates_from && {
            dates_from: data?.dates_from.replaceAll('/', '-'),
            dates_to: data?.dates_from.replaceAll('/', '-')
        }),
        ...(data?.dates_to && {
            dates_to: data?.dates_to.replaceAll('/', '-')
        })
    };
};

const Promocodes = () => {
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.promocode);
    const [anhcorEl, setAnchorEl] = useState({});
    const { data: Promocodes, totalRecords, isLoading, paginationProp, mutate } = usePagination('/get-promocodes-list', getFilters(Filter));
    const [add, setAdd] = useState();
    const [deleteGroup, setDeleteGroup] = useState();
    const { marketing_promocodes_delete, marketing_promocodes_edit } = usePermission([
        'marketing-promocodes-edit',
        'marketing-promocodes-delete'
    ]);

    const setFilter = (data) => {
        dispatch(applyPromocodeFilter(data));
    };

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                columnGap="12px"
                container
                justifyContent="flex-start"
                p={'12px'}
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Promo Codes({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'promocode') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearPromocodeFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(Boolean(anhcorEl.date) ? { date: null, Strategy: null } : { date: e.currentTarget, Strategy: null })
                        }
                        sx={{ py: '1px' }}
                    >
                        Date/Time
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null })}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        <DateFilter
                            Filter={Filter}
                            setFilter={setFilter}
                            setAnchorEl={setAnchorEl}
                            customFilters={false}
                            defaultObj={{ status: '', search: '' }}
                        />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="status"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { date: null, status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        id="status"
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        {[true, false, ''].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.status === mode}
                                onClick={() => {
                                    setFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode ? 'Active' : 'Deactive'}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {marketing_promocodes_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setAdd(true)}>
                            Add Promo Code
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 53px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>Expiry Date</TableCell>
                            <TableCell>Group</TableCell>
                            <TableCell>Status</TableCell>
                            {(marketing_promocodes_delete || marketing_promocodes_edit) && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Promocodes && Promocodes?.length > 0 ? (
                            Promocodes?.map((group) => (
                                <TableRow key={group.id}>
                                    <TableCell align="left">{group.name}</TableCell>
                                    <TableCell>{group.code}</TableCell>
                                    <TableCell>{`${group.is_percentage ? '' : '₹ '}${group.discount_amount}${
                                        group.is_percentage ? '%' : ''
                                    }`}</TableCell>
                                    <TableCell>{group.schedule_from}</TableCell>
                                    <TableCell>{group.schedule_to}</TableCell>
                                    <TableCell>{group.groups.map((group) => group.name).join(',')}</TableCell>
                                    <TableCell>{getKeyStyles(group.status)}</TableCell>
                                    {(marketing_promocodes_delete || marketing_promocodes_edit) && (
                                        <TableCell>
                                            {marketing_promocodes_edit && (
                                                <IconButton
                                                    sx={{ mr: marketing_promocodes_delete ? '5px' : '' }}
                                                    onClick={() => setAdd({ ...group, is_percentage: group.is_percentage ? '%' : '₹' })}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {marketing_promocodes_delete && (
                                                <IconButton onClick={() => setDeleteGroup({ promocode_id: group.id })}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>
            {add && <AddPromocodes data={add} onClose={() => setAdd(null)} mutate={mutate} />}
            {deleteGroup && (
                <PlanDelete
                    data={deleteGroup}
                    onClose={() => setDeleteGroup(null)}
                    mutate={mutate}
                    url="/delete-promocode"
                    name="Promocode"
                />
            )}
        </>
    );
};

export default Promocodes;
