import {
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Complete, Export, Inprocess, Rejected } from '../../../files/icon';
import Button from '../../common/Button';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../../common';
import { getFilters } from '../../Trade/api';
import Search from '../../common/Search';
import DateFilter from '../../Trade/DateFilter';
import Api from '../../../api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { applyReferralFilter, clearReferralFilter } from '../../../store/filter';

const getKey = (key) => {
    if (key === 'In Process') {
        return <Inprocess />;
    }
    if (key === 'Rejected') {
        return <Rejected />;
    }
    if (key === 'Completed') {
        return <Complete />;
    }
};

const Reffered = () => {
    const { transaction_refferal_edit } = usePermission(['transaction-refferal-edit']);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.referral);
    const [anhcorEl, setAnchorEl] = useState({});
    const {
        data: RefferdList,
        totalRecords,
        isLoading,
        paginationProp
    } = usePagination('/referral-earning-history-list', getFilters(Filter));

    const onExport = async () => {
        try {
            const response = await Api.post('/referral-export', getFilters(Filter), { responseType: 'arraybuffer' });

            const date = new Date();

            const outputFilename = `Refferal ${date.toLocaleString('en-IN')}.xlsx`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const setFilter = (data) => {
        dispatch(applyReferralFilter(data));
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Typography fontSize={'120%'}>Referral Customer Details({totalRecords || 0})</Typography>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'referral') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearReferralFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>

                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, status: null })}
                        PaperProps={{ sx: { width: '125px' } }}
                    >
                        {['', 'complete', 'rejected', 'in_process'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.status === mode}
                                onClick={() => {
                                    setFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.date) ? {} : { date: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Date
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '400px' } }}
                    >
                        <DateFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.type) ? {} : { type: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Service Type
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.type)}
                        anchorEl={anhcorEl?.type}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, type: null })}
                        PaperProps={{ sx: { width: '125px' } }}
                    >
                        {['', 'trading', 'membership', 'demat'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.type === mode}
                                onClick={() => {
                                    setFilter({ type: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {transaction_refferal_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={onExport}>
                            <Export />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: 'calc(100% - 49px)' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Referral Name </TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total Earning</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {RefferdList && RefferdList?.length > 0 ? (
                            RefferdList?.map((reffer) => (
                                <TableRow key={reffer.id}>
                                    <TableCell>
                                        {reffer.referral_name === 'N/A' ? (
                                            'N/A'
                                        ) : (
                                            <Button
                                                fullWidth={false}
                                                variant="header"
                                                onClick={() =>
                                                    navigate('/customers/details', {
                                                        state: { user_id: reffer.referral_user_id, customer_name: reffer.referral_phone }
                                                    })
                                                }
                                            >
                                                {reffer.referral_name}
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: reffer.user_id, customer_name: reffer.customer_phone }
                                                })
                                            }
                                        >
                                            {reffer.customer_name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{reffer.date.split(' ')[0]}</TableCell>
                                    <TableCell>{rupeeFormat(reffer.total_earning)}</TableCell>
                                    <TableCell>{getKey(reffer.status)}</TableCell>
                                    <TableCell>{reffer.type}</TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={13}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={13}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>
        </>
    );
};

export default Reffered;
