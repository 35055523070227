import Grid from '@mui/material/Grid';
import Company from '../components/settings/Company';
import PaymentGateway from '../components/settings/PaymentGateway';
import Taxinfo from '../components/settings/Taxinfo';
import Rewards from '../components/settings/Rewards';
import Socket from '../components/settings/Socket';
import Policies from '../components/settings/Policies';
import Extra from '../components/settings/Extra';

const Settings = () => {
    return (
        <Grid container justifyContent="space-between" alignItems="flex-start" columnGap="16px">
            <Company />
            <Taxinfo />
            <PaymentGateway />
            <Rewards />
            <Socket />
            <Extra />
            <Policies />
        </Grid>
    );
};

export default Settings;
