import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Edit } from '../../../files/icon';
import { getKeyStyles } from '../../Customers/api';
import AddType from './AddType';

const Types = () => {
    const [edit, setEdit] = useState();
    const { data, error, mutate } = useSWR('/type-list');
    return (
        <>
            <Grid container bgcolor="secondary.main" p="10px" borderRadius="5px 5px 0px 0px">
                <Grid item xs>
                    <Typography variant="h6">Type</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Button sx={{ py: '2px' }} onClick={() => setEdit(true)}>
                        ADD
                    </Button>
                </Grid>
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 53px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.data?.length > 0 ? (
                            data?.data.map((type) => (
                                <TableRow key={type.type_id}>
                                    <TableCell align="left">{type.name}</TableCell>
                                    <TableCell>{getKeyStyles(type.status)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setEdit(type)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : !data && !error ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3}>No data</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {edit && <AddType data={edit} onClose={() => setEdit(null)} mutate={mutate} />}
        </>
    );
};
export default Types;
