import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { forwardRef } from 'react';
import { useState } from 'react';

const Button = forwardRef(({ children, onClick, disabled, ...rest }, ref) => {
    const [loading, setLoading] = useState(false);
    const onclick = async (e, v) => {
        setLoading(true);
        onClick && (await onClick(e, v));
        setLoading(false);
    };

    return (
        <MuiButton onClick={onclick} disabled={disabled || loading} {...rest} ref={ref}>
            {loading ? <CircularProgress size={25} /> : children}
        </MuiButton>
    );
});

export default Button;
