import { CircularProgress, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { XAxis, Tooltip, BarChart, Bar, Cell } from 'recharts';
import useSWR from 'swr';
// import { ReactComponent as ChartItems } from '../../files/icon/ChartItems.svg';
import { rupeeFormat } from '../common';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Grid bgcolor="secondary.main" px="10px" pb="7px" pt="7px" borderRadius="5px" boxShadow={5}>
                <Typography variant="body2">{label}</Typography>
                {payload.map((item) => (
                    <Typography key={item.name} variant="body2">
                        {item.name} :{' '}
                        <Typography component="span" color={item.dataKey === 'Current P&L' ? item.payload.color : item.color}>
                            {rupeeFormat(item.value)}
                        </Typography>
                    </Typography>
                ))}
            </Grid>
        );
    }

    return null;
};

const Trading = () => {
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    const [Filter, setFilter] = useState({ year: new Date().getFullYear() });
    const { data: chart } = useSWR(['/chart-list', { user_id, year: Filter.year }], { revalidateIfStale: false });
    const { data, error, isValidating } = useSWR(['/trading-yearly-chart-data', Filter]);

    return (
        <>
            <Grid container>
                <Grid item xs="auto">
                    <Typography variant="h6">Trading</Typography>
                </Grid>
                <Grid item xs></Grid>
                {isValidating && (
                    <Grid item xs="auto">
                        <CircularProgress size={20} />
                    </Grid>
                )}
            </Grid>
            <Grid bgcolor="background.default" px="10px" borderRadius="5px" container alignItems="flex-start" pt="10px" gap="10px">
                <Grid item xs="auto">
                    <Select
                        variant="standard"
                        value={Filter?.year}
                        onChange={(e, v) => {
                            setFilter((prev) => ({ ...prev, year: e.target.value }));
                        }}
                        fullWidth={false}
                        defaultValue={data?.default_data?.year || '2022'}
                        sx={{ border: 'none', bgcolor: 'secondary.main' }}
                    >
                        {chart ? (
                            chart?.year_data?.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem value={String(new Date().getFullYear())}>{String(new Date().getFullYear())}</MenuItem>
                        )}
                    </Select>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs={12}>
                    {data?.trading_data?.length > 0 ? (
                        <ScrollContainer vertical={false}>
                            <BarChart
                                data={data?.trading_data
                                    ?.map((week) => ({
                                        ...week,
                                        'Current P&L': Math.abs(week['Current P&L']),
                                        'Carry On': Math.abs(week['Carry On']),
                                        color: week['Current P&L'] < 0 ? '#EA5455' : '#49D617'
                                    }))
                                    .reverse()}
                                height={200}
                                width={data?.trading_data.length * 40}
                            >
                                <Bar dataKey="Carry On" fill="#EA5455" />
                                <Bar dataKey="Current P&L">
                                    {data?.trading_data
                                        ?.map((week) => ({
                                            ...week,
                                            'Current P&L': Math.abs(week['Current P&L']),
                                            'Carry On': Math.abs(week['Carry On']),
                                            color: week['Current P&L'] < 0 ? '#EA5455' : '#28C700'
                                        }))
                                        .reverse()
                                        .map((entry, index) => (
                                            <Cell fill={entry.color} key={`cell-${index}`} spacing={2.5} />
                                        ))}
                                </Bar>
                                <XAxis
                                    dataKey="week"
                                    tickFormatter={(prev) => {
                                        return prev.replaceAll('Week ', '').replaceAll('(2022)', '').replaceAll('(2023)', '') || '';
                                    }}
                                    tickSize={4}
                                    tick={{ fontSize: '11px', stroke: '#ffffffcc', strokeWidth: 0.1 }}
                                    tickLine={false}
                                />
                                <Tooltip cursor={{ fill: 'rgba(242, 242, 242, 0.1)' }} content={<CustomTooltip />} />
                            </BarChart>
                        </ScrollContainer>
                    ) : // <ResponsiveContainer aspect={3}>
                    //     <LineChart width={730} height={250} data={data?.trading_data}>
                    //         <XAxis
                    //             dataKey="week"
                    //             tickFormatter={(prev) => {
                    //                 return prev.replaceAll('Week ', '').replaceAll('(2022)', '') || '' || '';
                    //             }}
                    //             minTickGap={0}
                    //             tickSize={4}
                    //             tick={{ fontSize: '11px' }}
                    //             tickLine={false}
                    //         />
                    //         <Tooltip cursor={false} content={<CustomTooltip />} />
                    //         <Line type="monotone" strokeWidth={2} dot={false} activeDot={false} dataKey="Carry On" stroke="#28C700" />
                    //         <Line
                    //             type="monotone"
                    //             strokeWidth={2}
                    //             dot={false}
                    //             activeDot={false}
                    //             dataKey="Current P&L"
                    //             stroke="#EA5455"
                    //         />
                    //     </LineChart>
                    // </ResponsiveContainer>
                    !data && !error ? (
                        <CircularProgress />
                    ) : (
                        <Typography variant="h6" textAlign="center">
                            No Data
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
export default Trading;
