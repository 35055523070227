import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Edit } from '../../../files/icon';
import { getKeyStyles } from '../../Customers/api';
import AddRoles from './AddRoles';

const Roles = () => {
    const [edit, setEdit] = useState();
    const { data, mutate, isValidating, isLoading } = useSWR('/roles-list');

    return (
        <>
            <Grid container bgcolor="secondary.main" p="10px" borderRadius="5px 5px 0px 0px">
                <Grid item xs>
                    <Typography variant="h6">Roles</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Button disabled={!data} sx={{ py: '2px' }} onClick={() => setEdit({ rewards_name: '', rewards: '' })}>
                        ADD
                    </Button>
                </Grid>
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 50px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && !isValidating && data?.data?.length > 0 ? (
                            data?.data.map((role) => (
                                <TableRow key={role.role_id}>
                                    <TableCell align="left">{role.name}</TableCell>
                                    <TableCell>{getKeyStyles(role.status)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => setEdit(role)}>
                                            <Edit />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : isLoading || isValidating ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3}>No data</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {edit && <AddRoles data={edit} onClose={() => setEdit(null)} mutate={mutate} permission_list={data?.permission_list} />}
        </>
    );
};
export default Roles;
