import { Button, CircularProgress, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import useSWR from 'swr';
import { Edit } from '../../files/icon';
import { usePermission } from '../common';
import RewardsEdit from './RewardsEdit';

const Rewards = () => {
    const [edit, setEdit] = useState();
    const { data, mutate } = useSWR('/rewards-info');
    const { setting_edit } = usePermission(['setting-edit']);
    return (
        <Grid item container xs={4} bgcolor="secondary.main" borderRadius="5px" pt="10px" boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
            <Grid item xs pl="10px" pb="10px">
                <Typography variant="h6">Rewards</Typography>
            </Grid>
            {setting_edit && (
                <Grid item xs="auto" pr="10px" pb="10px">
                    <Button sx={{ py: '2px' }} onClick={() => setEdit({ rewards_name: '', rewards: '' })}>
                        ADD
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                {data ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" sx={{ paddingRight: '2px' }}>
                                    Name
                                </TableCell>
                                <TableCell>Rewards</TableCell>
                                {setting_edit && <TableCell>Action</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(data?.rewards_data).map((key) => (
                                <TableRow key={key}>
                                    <TableCell sx={{ textTransform: 'uppercase', paddingRight: '2px' }} align="left">
                                        {key.replaceAll('_', ' ')}
                                    </TableCell>
                                    <TableCell>{data?.rewards_data?.[key]}</TableCell>
                                    {setting_edit && (
                                        <TableCell>
                                            <IconButton onClick={() => setEdit({ rewards_name: key, rewards: data?.rewards_data?.[key] })}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Grid container>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
            {edit && <RewardsEdit data={edit} onClose={() => setEdit(null)} mutate={mutate} />}
        </Grid>
    );
};
export default Rewards;
