import { useSelector } from 'react-redux';
import Brokers from './Brokers';
import Details from './Details';
import Navigation from './Navigation';
import Referedusers from './RefferedUser';
import Stastics from './Stastics';
import Strategies from './Strategies';

const CustomersDetail = () => {
    const mode = useSelector((state) => state.reducer.mode);
    return (
        <>
            <Details />
            <Stastics />
            <Strategies />
            {mode === 'live' && <Brokers />}
            <Navigation />
            <Referedusers />
        </>
    );
};

export default CustomersDetail;
