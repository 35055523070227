import { Dialog, Grid, IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { DropFiles } from '../../../files/icon';
import Api from '../../../api';

const FileInputDialog = ({ open, onClose, mutate }) => {
    const uploadFile = async (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);

            const response = await Api.post('/gst-import', formData);

            if (response.data.status) {
                toast(response.data.message);
                onClose();
                mutate();
            } else {
                toast.error(response.data.message);
            }
        } else {
            toast.error('Please, select excel file');
        }
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <Grid
                container
                bgcolor="background.default"
                py={3}
                my={7}
                mx="15%"
                width="70%"
                border={(theme) => `1px dashed ${theme.palette.primary.main}`}
            >
                <IconButton color="primary" aria-label="upload picture" component="label">
                    <input hidden accept=".xlsx" type="file" onChange={uploadFile} onDrop={uploadFile} />
                    <DropFiles />
                </IconButton>
            </Grid>
        </Dialog>
    );
};
export default FileInputDialog;
