import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { ReactComponent as True } from '../../../files/icon/True.svg';
import { ReactComponent as False } from '../../../files/icon/False.svg';

const PermissionView = ({ data, onClose }) => {
    return (
        <Dialog open={Boolean(data)} onClose={onClose} maxWidth="md" PaperProps={{ sx: { minWidth: '800px', px: '0px' } }}>
            <Typography textAlign="center" variant="h6" pb="5px">
                {data?.name}
            </Typography>
            <TableContainer sx={{ height: 'calc(100% - 30px)' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ pl: '5%', width: '30%' }}>
                                Name
                            </TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(data?.permission).map((per) => (
                            <TableRow>
                                <TableCell align="left" sx={{ pl: '5%', width: '30%', textTransform: 'capitalize' }}>
                                    {per.replaceAll('-', ' ')}
                                </TableCell>
                                <TableCell sx={{ pt: '9px', pb: '4px' }}>
                                    {data?.permission?.[per]?.filter((el) => el.includes('View')).length > 0 ? <True /> : <False />}
                                </TableCell>
                                <TableCell sx={{ pt: '9px', pb: '4px' }}>
                                    {data?.permission?.[per]?.filter((el) => el.includes('Edit')).length > 0 ? <True /> : <False />}
                                </TableCell>
                                <TableCell sx={{ pt: '9px', pb: '4px' }}>
                                    {data?.permission?.[per]?.filter((el) => el.includes('Delete')).length > 0 ? <True /> : <False />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* {Object.keys(data?.permission).map((per, index) => (
                <Grid container gap="15px" alignItems="flex-start" key={per} bgcolor={index % 2 ? '' : '#1F273B'} py="5px" px="25px">
                    <Grid item xs={3}>
                        <Typography color="#ffffff" textTransform="capitalize" noWrap variant="body2">
                            {per.replaceAll('-', ' ')}
                        </Typography>
                    </Grid>
                    <Grid item xs container spacing="5px" justifyContent="flex-start">
                        {data?.permission?.[per].map((key) => (
                            <Grid item xs={4} variant="body2" key={key}>
                                <Typography color="#B4B7BD" variant="body2">
                                    {key}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))} */}
            {/* {Object.keys(data?.permission).map((per, index) => (
                <Grid container gap="15px" alignItems="flex-start" key={per} bgcolor={index % 2 ? '' : '#1F273B'} py="5px" px="25px">
                    <Grid item xs={5}>
                        <Typography color="#ffffff" textTransform="capitalize" noWrap variant="body2">
                            {per.replaceAll('-', ' ')}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        {data?.permission?.[per].map((key) => (
                            <Typography color="#B4B7BD" variant="body2">
                                {key}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            ))} */}
        </Dialog>
    );
};
export default PermissionView;
