import { Autocomplete, Button, Checkbox, Dialog, FormControlLabel, Grid, Input, TextField, Typography } from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';
import { useState } from 'react';

const AddRoles = ({ data, onClose, mutate, permission_list }) => {
    const [permission, setPermission] = useState(data?.permission || []);
    const [active, setActive] = useState('customers');
    const schema = yup.object().shape({
        name: yup.string().required(),
        status: yup.boolean().required()
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: typeof data === 'boolean' ? {} : { name: data?.name, status: data?.status }
    });

    const submit = async (input) => {
        const response = await fetcher('/roles-add', {
            ...input,
            permission_ids: permission.map((per) => per.permission_id),
            role_id: data?.role_id
        });

        if (response?.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose}>
            <Typography textAlign="center" variant="h6">
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Roles
            </Typography>
            <AsyncForm
                onSubmit={handleSubmit(submit)}
                gridProps={{ container: true, columnSpacing: '25px', px: 3, alignItems: 'flex-start' }}
            >
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Name</Typography>
                    <Input error={Boolean(errors?.name?.message)} {...register('name')} />
                    <Typography color="error" variant="body2">
                        {errors?.name?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={[true, false]}
                                getOptionLabel={(option) => (option ? 'Active' : 'Deactive')}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.status?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid border="2px solid rgba(115, 103, 240, 0.5)" borderRadius="5px" py="7px" px="15px" height="350px" overflow="auto">
                        <Grid container>
                            <Grid item xs="auto">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                e.target.checked ? setPermission(permission_list) : setPermission([]);
                                            }}
                                            checked={permission.length === permission_list.length}
                                        />
                                    }
                                    label=""
                                />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    variant="header"
                                    onClick={(e) => {
                                        permission.length > 0 ? setPermission([]) : setPermission(permission_list);
                                    }}
                                    sx={{ justifyContent: 'flex-start' }}
                                >
                                    All
                                </Button>
                            </Grid>
                        </Grid>
                        {permission_list
                            ?.map((permission) => permission.type)
                            .filter((permission, index, arr) => arr.indexOf(permission) === index)
                            .map((type) => (
                                <Grid container key={type}>
                                    <Grid item xs="auto">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => {
                                                        e.target.checked
                                                            ? setPermission((prev) => {
                                                                  const array = [
                                                                      ...prev,
                                                                      ...permission_list?.filter((per) => per.type === type)
                                                                  ];
                                                                  return array.filter(
                                                                      (per, index) =>
                                                                          array.findIndex(
                                                                              (obj) => obj.permission_id === per.permission_id
                                                                          ) === index
                                                                  );
                                                              })
                                                            : setPermission((prev) => prev.filter((per) => per.type !== type));
                                                    }}
                                                    checked={permission?.map((permission) => permission.type).includes(type)}
                                                />
                                            }
                                            label=""
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <Button variant="header" onClick={(e) => setActive(type)} sx={{ justifyContent: 'flex-start' }}>
                                            {type}
                                        </Button>
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid border="2px solid rgba(115, 103, 240, 0.5)" borderRadius="5px" py="7px" px="15px" height="350px" overflow="auto">
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            e.target.checked
                                                ? setPermission((prev) => {
                                                      const array = [...prev, ...permission_list?.filter((per) => per.type === active)];
                                                      return array.filter(
                                                          (per, index) =>
                                                              array.findIndex((obj) => obj.permission_id === per.permission_id) === index
                                                      );
                                                  })
                                                : setPermission((prev) => prev.filter((per) => per.type !== active));
                                        }}
                                        checked={
                                            permission.filter((per) => per.type === active).length ===
                                            permission_list.filter((per) => per.type === active).length
                                        }
                                    />
                                }
                                label="All"
                            />
                        </div>
                        {permission_list
                            .filter((permission, index, arr) => permission.type === active)
                            .map((type) => (
                                <div key={type.permission_id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setPermission((prev) => [...prev, type])
                                                        : setPermission((prev) =>
                                                              prev.filter((per) => per.permission_id !== type.permission_id)
                                                          );
                                                }}
                                                checked={permission.map((per) => per.permission_id).includes(type.permission_id)}
                                            />
                                        }
                                        label={type.name}
                                    />
                                </div>
                            ))}
                    </Grid>
                </Grid>
            </AsyncForm>
        </Dialog>
    );
};
export default AddRoles;
