import { Autocomplete, Dialog, Grid, TextField, Typography } from '@mui/material';
import DatePicker from 'react-multi-date-picker';
import Button from '../../common/Button';
import { useForm, Controller } from 'react-hook-form';
import Api from '../../../api';
import { toast } from 'react-toastify';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

const EditSubscription = ({ membershipId, onClose, mutate }) => {
    const { control, handleSubmit } = useForm({
        mode: 'onTouched',
        defaultValues: {
            membership_id: membershipId.membership_id,
            purchase_date: membershipId.purchase_date,
            start_date: membershipId.start_date,
            expiry_date: membershipId.expiry_date,
            payment_mode: membershipId.payment_mode
        }
    });

    const updateSubscription = async (data) => {
        const { membership_id, purchase_date, start_date, expiry_date, payment_mode } = data;
        const response = await Api.post('edit-membership', {
            membership_id,
            payment_mode,
            purchase_date: purchase_date.replaceAll('/', '-'),
            start_date: start_date.replaceAll('/', '-'),
            expiry_date: expiry_date.replaceAll('/', '-')
        });
        if (response?.data?.status) {
            toast(response.data.message);
            onClose();
            mutate();
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <Dialog open={Boolean(membershipId)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px' } }}>
            <Typography textAlign="center" fontSize="120%">
                Edit Subscriptions
            </Typography>
            <Grid px={2}>
                {['purchase_date', 'start_date', 'payment_mode', 'expiry_date'].map((key) => {
                    if (key === 'payment_mode') {
                        return (
                            <Grid key={key}>
                                <Typography pt={2}>{key.replaceAll('_', ' ')}</Typography>
                                <Controller
                                    control={control}
                                    name={key}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Autocomplete
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            onChange={(e, v) => onChange(v)}
                                            onBlur={onBlur}
                                            value={value}
                                            options={['Apple Pay', 'UPI', 'Net Banking', 'Debit Card', 'Credit Card', 'Paytm Wallet']}
                                        />
                                    )}
                                />
                            </Grid>
                        );
                    }

                    return (
                        <Grid key={key}>
                            <Typography pt={2}>{key.replaceAll('_', ' ')}</Typography>
                            <Controller
                                control={control}
                                name={key}
                                render={({ field: { onChange, name, value } }) => (
                                    <>
                                        <DatePicker
                                            multiple={false}
                                            name={name}
                                            value={value}
                                            format="DD/MM/YYYY HH:mm:ss"
                                            onChange={(date) => {
                                                onChange({ target: { name: key, value: date.format() } });
                                            }}
                                            containerStyle={{ width: '100%', background: 'transparent' }}
                                            style={{
                                                width: '100%',
                                                height: '35px',
                                                border: '2px solid rgba(115, 103, 240, 0.5)',
                                                background: 'rgba(115, 103, 240, 0.06)',
                                                color: '#ffffff',
                                                fontFamily: 'Givonic',
                                                fontSize: '100%'
                                            }}
                                            className="custom-calendar"
                                            mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                                const props = { style: { color: '#d0d2d6' } };
                                                if (isSameDate(today, date)) {
                                                    props.style = {
                                                        ...props.style,
                                                        backgroundColor: '#283046',
                                                        border: '2px solid rgba(115, 103, 240, 0.5)'
                                                    };
                                                }
                                                if (isSameDate(date, selectedDate))
                                                    props.style = {
                                                        ...props.style,
                                                        backgroundColor: '#7367F0'
                                                    };
                                                return props;
                                            }}
                                            calendarPosition="bottom-center"
                                            plugins={[<TimePicker position="right" />]}
                                        />
                                    </>
                                )}
                            />
                            {/* <DatePicker
                                multiple={false}
                                onChange={(date) => {
                                    onChange({ target: { name: key, value: date.format() } });
                                }}
                                {...rest}
                            /> */}
                        </Grid>
                    );
                })}
                <Button sx={{ display: 'block', width: '15%', margin: '0 auto', mt: 3 }} onClick={handleSubmit(updateSubscription)}>
                    Update
                </Button>
            </Grid>
        </Dialog>
    );
};
export default EditSubscription;
