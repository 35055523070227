import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Dialog,
    FormControlLabel,
    Grid,
    Input,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../api';
import Button from '../common/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Expand } from '../../files/icon';
import BIllingDetail from './BIllingDetail';
import BankDetails from './BankDetails';
import RefferalBillingDetail from './RefferalBillingDetail';

const DetailsEditModal = ({ edit, setEdit, mutate }) => {
    const {
        state: { user_id }
    } = useLocation();
    const editCustomer = ['profit_share', 'status', 'is_partner', 'referral_share'];
    const [expanded, setExpanded] = useState('Billing');

    const schema = yup.object().shape({});

    const { register, watch, handleSubmit, control } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            ...editCustomer.reduce((acc, cv) => {
                if (cv === 'profit_share' || cv === 'referral_share') {
                    return { ...acc, [cv]: Number(edit?.data?.[cv].replaceAll('%', '')) };
                }
                return { ...acc, [cv]: edit?.data?.[cv] || '' };
            }, {})
        }
    });

    const onSubmit = async (data) => {
        const response = await Api.post('/customers-update', {
            user_id,
            ...data
        });

        if (response.data.status) {
            toast(response.data.message);
            setEdit(null);
            mutate();
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <Dialog open={Boolean(edit)} onClose={() => setEdit(null)}>
            <Grid container columnSpacing="15px" px="30px" pt="15px" justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <Typography textAlign="center" variant="h6" color="#ffffff">
                        Edit Customer Details
                    </Typography>
                </Grid>
                {editCustomer.map((key) => {
                    if (key === 'status') {
                        return (
                            <Grid item xs={6} minHeight="65px" key={key}>
                                <Typography pt={1} textTransform="capitalize" variant="body2" color="text.secondary">
                                    {key.replaceAll('_', ' ')}
                                </Typography>
                                <Controller
                                    name={key}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Autocomplete
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            onChange={(e, v) => {
                                                onChange(v);
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            options={['Active', 'Deactivate']}
                                        />
                                    )}
                                />
                            </Grid>
                        );
                    } else if (key === 'referral_share') {
                        if (watch('is_partner') === 'No') {
                            return '';
                        }
                        return (
                            <Grid item xs={6} minHeight="65px" key={key}>
                                <Typography pt={1} textTransform="capitalize" variant="body2" color="text.secondary">
                                    Referral Share (%)
                                </Typography>
                                <Input type={'number'} {...register(key)} />
                            </Grid>
                        );
                    } else if (key === 'is_partner') {
                        return (
                            <Grid item xs={6} minHeight="65px" key={key}>
                                <Typography pt={1} textTransform="capitalize" variant="body2" color="text.secondary">
                                    Partner
                                </Typography>
                                <Controller
                                    control={control}
                                    name={'is_partner'}
                                    render={({ field }) => (
                                        <RadioGroup row {...field}>
                                            <FormControlLabel value="Yes" control={<Radio size="small" />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio size="small" />} label="No" />
                                        </RadioGroup>
                                    )}
                                />
                            </Grid>
                        );
                    } else {
                        return (
                            <Grid item xs={6} minHeight="65px" key={key}>
                                <Typography pt={1} textTransform="capitalize" variant="body2" color="text.secondary">
                                    {key.replaceAll('_', ' ')}
                                </Typography>
                                <Input type={key === 'profit_share' ? 'number' : 'text'} {...register(key)} />
                            </Grid>
                        );
                    }
                })}
            </Grid>
            <Grid container pt={2}>
                <Button onClick={handleSubmit(onSubmit)} sx={{ width: '20%' }}>
                    Update
                </Button>
            </Grid>
            <Accordion
                sx={{ pt: '12px', px: 2, '&::before': { backgroundColor: 'transparent' } }}
                onChange={() => setExpanded((prev) => (prev === 'Billing' ? '' : 'Billing'))}
                expanded={expanded === 'Billing'}
            >
                <AccordionSummary
                    expandIcon={<Expand />}
                    sx={{
                        background: '#161D31',
                        borderRadius: '2px',
                        maxHeight: '34px',
                        alignItems: 'center',
                        minHeight: '34px',
                        '& .Mui-expanded': {
                            maxHeight: '34px',
                            alignItems: 'center',
                            minHeight: '34px'
                        }
                    }}
                >
                    <Typography fontSize="110%" textTransform="capitalize">
                        Billing Details
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BIllingDetail edit={edit} mutate={mutate} setEdit={setEdit} />
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={{ pt: '12px', px: 2, '&::before': { backgroundColor: 'transparent' } }}
                onChange={() => setExpanded((prev) => (prev === 'Bank' ? '' : 'Bank'))}
                expanded={expanded === 'Bank'}
            >
                <AccordionSummary
                    expandIcon={<Expand />}
                    sx={{
                        background: '#161D31',
                        borderRadius: '2px',
                        maxHeight: '34px',
                        alignItems: 'center',
                        minHeight: '34px',
                        '& .Mui-expanded': {
                            maxHeight: '34px',
                            alignItems: 'center',
                            minHeight: '34px'
                        }
                    }}
                >
                    <Typography fontSize="110%" textTransform="capitalize">
                        Bank Details
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <BankDetails edit={edit} mutate={mutate} setEdit={setEdit} />
                </AccordionDetails>
            </Accordion>
            {watch('is_partner') === 'Yes' && (
                <Accordion
                    sx={{ pt: '12px', px: 2, '&::before': { backgroundColor: 'transparent' } }}
                    onChange={() => setExpanded((prev) => (prev === 'Referral' ? '' : 'Referral'))}
                    expanded={expanded === 'Referral'}
                >
                    <AccordionSummary
                        expandIcon={<Expand />}
                        sx={{
                            background: '#161D31',
                            borderRadius: '2px',
                            maxHeight: '34px',
                            alignItems: 'center',
                            minHeight: '34px',
                            '& .Mui-expanded': {
                                maxHeight: '34px',
                                alignItems: 'center',
                                minHeight: '34px'
                            }
                        }}
                    >
                        <Typography fontSize="110%" textTransform="capitalize">
                            Referral Billing Details
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RefferalBillingDetail edit={edit} mutate={mutate} setEdit={setEdit} />
                    </AccordionDetails>
                </Accordion>
            )}
        </Dialog>
    );
};

export default DetailsEditModal;
