import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useSWR from 'swr';
import { CustomList } from '../Customers/api';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { Edit } from '../../files/icon';
import CompanyEdit from './CompanyEdit';
import { usePermission } from '../common';

const Company = () => {
    const { data, mutate } = useSWR('/company-info');
    const [edit, setEdit] = useState();
    const { setting_edit } = usePermission(['setting-edit']);
    return (
        <>
            <Grid
                item
                xs={12}
                bgcolor="secondary.main"
                mb="25px"
                pb="20px"
                sx={{ borderRadius: '5px' }}
                container
                justifyContent="space-evenly"
                alignItems="flex-start"
                boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
            >
                <Grid item xs={12} py="12px" px="12px" container justifyContent="space-between">
                    <Typography variant="h6">Company</Typography>
                    {setting_edit && (
                        <IconButton onClick={() => setEdit(data?.companyinfo)}>
                            <Edit />
                        </IconButton>
                    )}
                </Grid>
                {data ? (
                    <CustomList object={data?.companyinfo} lg={2} />
                ) : (
                    <Grid>
                        <CircularProgress />
                    </Grid>
                )}
            </Grid>
            {edit && <CompanyEdit data={edit} onClose={() => setEdit(null)} mutate={mutate} />}
        </>
    );
};
export default Company;
