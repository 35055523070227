import qs from 'query-string';
import Api from '../../api';
import useSWR from 'swr';
import { store } from '../../store';
import { Active, Deactive, Expired, Upcoming } from '../../files/icon';
import { CircularProgress, Grid, Switch, Typography } from '@mui/material';
import { rupeeFormat } from '../common';
import { useState } from 'react';

const brokerlist = async () => {
    const response = await Api.post('/broker-list');
    return response.data;
};

export const useGetBrokerlist = () => {
    const { data, error, mutate } = useSWR('/broker-list', brokerlist, { revalidateOnFocus: false });

    return { BrokerList: data, isLoading: !data && !error, BrokerListMutation: mutate };
};

export const strategyList = async () => {
    const user_id = store.getState().data?._id;
    const response = await Api.post('/strategy-list', { user_id });
    return response.data;
};

export const getPlans = async () => {
    const user_id = store.getState().reducer.data?._id;
    const response = await Api.post('/premium-membership-plans', { user_id });
    return response.data;
};

export const applyFilters = (data) => {
    const { user_broker, dates, ...rest } = data;

    return {
        ...rest,
        user_broker: user_broker?.id,
        dates_from: dates?.[0].replaceAll('/', '-'),
        dates_to: dates?.[1]?.replaceAll('/', '-') || dates?.[0].replaceAll('/', '-')
    };
};

export const getKeyStyles = (key) => {
    if (key === 'Active' || key === 'True' || key === true || key === 'complete') {
        return <Active />;
    }
    if (key === 'Deactivate' || key === 'False' || key === false || key === 'rejected') {
        return <Deactive />;
    }
    if (key === 'Expired') {
        return <Expired />;
    }
    if (key === 'Upcoming') {
        return <Upcoming />;
    }
};

export const getCustomersDetail = async (user_id) => {
    const response = await Api.post('/customers-details', { user_id });

    return response.data;
};

export const getCustomersStastics = async (user_id, mode) => {
    const response = await Api.post('/customers-statistics-detail', { user_id, mode }).catch((err) => err.data);

    return response.data;
};

export const getColor = (data = '0') => {
    const string = String(data);
    if (string.includes('-') || string === 'SELL') {
        return 'error.main';
    } else if (string === '0.00' || string === '0.00 %' || string === '0.00%' || string === '0') {
        return 'text.primary';
    } else {
        return 'plus.main';
    }
};

export const getCustomersStrategyKey = (page, filters) => {
    const string = qs.stringify({ current_page: page, item_per_page: 5, ...filters });
    return `/customers-strategy-list?${string}`;
};

export const getCustomersBroker = async (user_id) => {
    const response = await Api.post('/customers-broker-list', { user_id });

    return response.data;
};

export const getCustomersRefferedKey = (page, filters) => {
    const string = qs.stringify({ current_page: page, item_per_page: 10, ...filters });
    return `/customers-referral-users-list?${string}`;
};

// export const performance = (object = {}, length = 7, stripped, px = 0, py, maxXs, keyxs) => {
//     if (Object.keys(object).length > length) {
//         return Array.from(Array(Math.ceil(Object.keys(object).length / length)).keys()).map((key, index) => (
//             <Grid container item xs={maxXs || 12 / Math.ceil(Object.keys(object).length / length)} key={index}>
//                 {Object.keys(object)
//                     .slice(key * length, key * length + length)
//                     .map((key, index) => (
//                         <ListItems
//                             key={key}
//                             keyxs={keyxs}
//                             background={index % 2 && stripped ? '#1F273B' : ''}
//                             px={px}
//                             py={py}
//                             name={key.replaceAll('percentage', '(%)').replaceAll('rs', '(₹)').replaceAll('_per', '/').replaceAll('_', ' ')}
//                             Value={object[key]}
//                         />
//                     ))}
//             </Grid>
//         ));
//     }
//     return Object.keys(object).map((key, index) => (
//         <ListItems
//             key={key}
//             keyxs={keyxs}
//             px={px}
//             py={py}
//             background={index % 2 && stripped ? '#1F273B' : ''}
//             name={key.replaceAll('percentage', '(%)').replaceAll('_', ' ')}
//             Value={typeof object[key] === 'object' ? object[key].city || object[key].state || 'N/A' : object[key]}
//         />
//     ));
// };

export const CustomList = ({ object = {}, columns = 2, px, pl = '30px', lg = 4 }) => {
    if (Object.keys(object).length < 1) {
        return;
    }

    return (
        <>
            {Array.from(Array(Math.ceil(Object.keys(object).length / columns)).keys()).map((key, index) => {
                return (
                    <Grid
                        container
                        justifyContent="flex-start"
                        py="5px"
                        px={px}
                        pl={pl}
                        key={key}
                        bgcolor={index % 2 === 0 ? '#1F273B' : ''}
                    >
                        {Object.keys(object)
                            .slice(columns * key, columns * key + columns)
                            .map((arr, index) => (
                                <Grid container item xs={12 / columns} key={index} flexWrap={'nowrap'}>
                                    <Grid item xs={5} lg={lg} color="text.secondary" textTransform="capitalize">
                                        <Typography variant="body2">
                                            {arr
                                                .replaceAll('percentage', '(%)')
                                                .replaceAll('rs', '(₹)')
                                                .replaceAll('_per', '/')
                                                .replaceAll('_', ' ')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <Typography pr="10px">:</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" pr="10px">
                                            {String(
                                                typeof object[arr] === 'object'
                                                    ? object[arr]?.city || object[arr]?.state || 'N/A'
                                                    : object[arr]
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                );
            })}
        </>
    );
};

export const getStyle = (string = '0') => {
    if (string.includes('-') || string === 'SELL') {
        return {
            color: 'error.main',
            background: '#3A2434',
            borderTop: '1px solid #F1416C',
            py: '10px',
            borderRadius: '0px 0px 5px 5px',
            boxShadow: '5px 5px 5px 1px rgba(0, 0, 0, 0.25)'
        };
    } else if (string === '0.00' || string === '0.00 %' || string === '0.00%') {
        return {
            color: 'text.primary',
            bgcolor: 'background.default',
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            py: '10px',
            borderRadius: '0px 0px 5px 5px',
            boxShadow: '5px 5px 5px 1px rgba(0, 0, 0, 0.25)'
        };
    } else {
        return {
            color: 'plus.main',
            background: '#1C3238',
            borderTop: '1px solid #50CD89',
            py: '10px',
            borderRadius: '0px 0px 5px 5px',
            boxShadow: '5px 5px 5px 1px rgba(0, 0, 0, 0.25)'
        };
    }
};

export const getHorizontalStyle = (data = '0', bgcolor = 'background.secondary') => {
    const string = String(data);
    if (string.includes('-') || string === 'SELL') {
        return {
            color: 'error.main',
            background: '#3A2434',
            borderLeft: '1px solid #F1416C',
            borderRadius: '0px 5px 5px 0px'
        };
    } else if (string === '0.00' || string === '0.00 %' || string === '0.00%') {
        return {
            color: 'text.primary',
            bgcolor: bgcolor,
            borderLeft: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '0px 5px 5px 0px'
        };
    } else {
        return {
            color: 'plus.main',
            background: '#1C3238',
            borderLeft: '1px solid #50CD89',
            borderRadius: '0px 5px 5px 0px'
        };
    }
};

export const getBrokersKey = (page, filters) => {
    const string = qs.stringify({ current_page: page, item_per_page: 5, ...filters });
    return `/customers-broker-list?${string}`;
};

export const Stastics = ({ name = '', value = '0.00', bgcolor = 'background.default' }) => {
    return (
        <Grid boxShadow={1} bgcolor={bgcolor} borderRadius="5px">
            <Typography
                py="12px"
                fontWeight={700}
                borderRadius="5px 5px 0px 0px"
                textTransform="capitalize"
                textAlign="center"
                variant="body2"
            >
                {name}
            </Typography>
            <Typography textAlign="center" sx={getStyle(String(value))}>
                {String(value).includes('%') ? value : rupeeFormat(value)}
            </Typography>
        </Grid>
    );
};

export const LoadingSwitch = ({ onChange, checked, disabled }) => {
    const [loading, setLoading] = useState(false);
    const change = async (e, v) => {
        setLoading(true);
        await onChange(e, v);
        setLoading(false);
    };
    return loading ? (
        <CircularProgress size={15} />
    ) : (
        <Switch disabled={disabled} size="small" color="plus" checked={checked} onChange={change} />
    );
};
