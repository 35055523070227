import { Button, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Algosuccess, Profile } from '../files/icon';
import { useSelector, useDispatch } from 'react-redux';
import SwitchEdited from '../components/common/SwitchEdited';
import { changeMode } from '../store/reducer';
import Profilecomp from './Profile';
import { usePermission } from '../components/common';
import { clearAllFilters } from '../store/filter';

const Header = () => {
    const navigate = useNavigate();
    const [anhcorEl, setAnchorEl] = useState({ trade: null, transaction: null, more: null });
    const [anhcorEl1, setAnchorEl1] = useState({});
    const mode = useSelector((state) => state.reducer.mode);
    const role = useSelector((state) => state?.reducer?.data?.role?.role_name === 'Admin');
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const {
        customers_view,
        broker_view,
        strategies_view,
        orderbook_view,
        open_position_view,
        tradebook_view,
        transaction_subscriptions_view,
        transaction_trade_view,
        transaction_referral_view,
        transaction_withdraw_view,
        transaction_gst_view,
        transaction_pnl_view,
        transaction_invoice_view,
        transaction_user_pnl_view,
        subscriptions_view,
        setting_view,
        users_view,
        banner_view,
        marketing_group_view,
        marketing_promocodes_view,
        ticker_view,
        user_login_view,
        closed_position_view
    } = usePermission([
        'orderbook-view',
        'customers-view',
        'broker-view',
        'strategies-view',
        'open-position-view',
        'tradebook-view',
        'transaction-subscriptions-view',
        'transaction-trade-view',
        'transaction-referral-view',
        'transaction-withdraw-view',
        'transaction-gst-view',
        'transaction-pnl-view',
        'transaction-invoice-view',
        'transaction-user-pnl-view',
        'subscriptions-view',
        'setting-view',
        'users-view',
        'banner-view',
        'marketing-group-view',
        'marketing-promocodes-view',
        'ticker-view',
        'user-login-view',
        'closed-position-view'
    ]);

    const nevigation = (path) => {
        navigate(path);
        setAnchorEl({ trade: null, transaction: null, more: null });
        setAnchorEl1({});
    };

    return (
        <>
            <Grid item xs="auto">
                <IconButton onClick={() => navigate('/')}>
                    <Algosuccess />
                </IconButton>
            </Grid>
            <Grid item xs></Grid>
            {customers_view && (
                <Grid item xs="auto">
                    <Button variant="header" sx={{ fontSize: '15px' }} onClick={() => navigate('/customers')}>
                        Customers
                    </Button>
                </Grid>
            )}
            {broker_view && (
                <Grid item xs="auto">
                    <Button variant="header" sx={{ fontSize: '15px' }} onClick={() => navigate('/broker')}>
                        Broker
                    </Button>
                </Grid>
            )}
            {strategies_view && (
                <Grid item xs="auto">
                    <Button variant="header" sx={{ fontSize: '15px' }} onClick={() => navigate('/strategies')}>
                        Strategies
                    </Button>
                </Grid>
            )}
            {(orderbook_view || open_position_view || tradebook_view || closed_position_view) && (
                <Grid item xs="auto">
                    <Button
                        id="basic-button"
                        variant="header"
                        aria-controls={Boolean(anhcorEl.trade) ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anhcorEl.trade) ? 'true' : undefined}
                        onClick={(e) =>
                            setAnchorEl(
                                Boolean(anhcorEl.trade)
                                    ? { trade: null, transaction: null, more: null }
                                    : { trade: e.currentTarget, transaction: null, more: null }
                            )
                        }
                        sx={{ fontSize: '15px' }}
                    >
                        Trade
                    </Button>
                    <Menu
                        id="basic-menu"
                        open={Boolean(anhcorEl.trade)}
                        anchorEl={anhcorEl.trade}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ trade: null, transaction: null, more: null })}
                        PaperProps={{ sx: { width: 'min-content', bgcolor: 'secondary.main' } }}
                    >
                        {orderbook_view && <MenuItem onClick={() => nevigation('/Trade/Orders')}>Orders</MenuItem>}
                        {open_position_view && <MenuItem onClick={() => nevigation('/Trade/Open')}>Open</MenuItem>}
                        {closed_position_view && <MenuItem onClick={() => nevigation('/Trade/Closed')}>Closed</MenuItem>}
                        {tradebook_view && <MenuItem onClick={() => nevigation('/Trade/TradeHistory')}>Trade History</MenuItem>}
                    </Menu>
                </Grid>
            )}
            {(transaction_subscriptions_view ||
                transaction_trade_view ||
                transaction_referral_view ||
                transaction_withdraw_view ||
                transaction_gst_view ||
                transaction_pnl_view ||
                transaction_invoice_view ||
                transaction_user_pnl_view ||
                user_login_view) && (
                <Grid item xs="auto">
                    <Button
                        id="basic-button"
                        variant="header"
                        aria-controls={Boolean(anhcorEl.transaction) ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anhcorEl.transaction) ? 'true' : undefined}
                        onClick={(e) =>
                            setAnchorEl(
                                Boolean(anhcorEl.transaction)
                                    ? { trade: null, transaction: null, more: null }
                                    : { trade: null, transaction: e.currentTarget, more: null }
                            )
                        }
                        sx={{ fontSize: '15px' }}
                    >
                        Transaction
                    </Button>
                    <Menu
                        id="basic-menu"
                        open={Boolean(anhcorEl.transaction)}
                        anchorEl={anhcorEl.transaction}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ trade: null, transaction: null, more: null })}
                        PaperProps={{ sx: { width: '135px', bgcolor: 'secondary.main' } }}
                    >
                        {transaction_subscriptions_view && (
                            <MenuItem onClick={() => nevigation('/Transaction/Subscriptions')}>Subscriptions</MenuItem>
                        )}
                        {transaction_trade_view && <MenuItem onClick={() => nevigation('/Transaction/Trades')}>Trade</MenuItem>}
                        {transaction_referral_view && <MenuItem onClick={() => nevigation('/Transaction/Referrals')}>Referrals</MenuItem>}
                        {transaction_withdraw_view && <MenuItem onClick={() => nevigation('/Transaction/Withdraw')}>Withdraw</MenuItem>}
                        {transaction_gst_view && <MenuItem onClick={() => nevigation('/Transaction/GST')}>GST</MenuItem>}
                        {/* <MenuItem onClick={() => nevigation('/Transaction/TDS')}>TDS</MenuItem> */}
                        {transaction_pnl_view && <MenuItem onClick={() => nevigation('/Transaction/ProfitLoss')}>Profit/Loss</MenuItem>}
                        {transaction_invoice_view && <MenuItem onClick={() => nevigation('/Transaction/Invoice')}>Invoice</MenuItem>}
                        {transaction_user_pnl_view && <MenuItem onClick={() => nevigation('/Transaction/Userp&l')}>User P&L</MenuItem>}
                        {user_login_view && <MenuItem onClick={() => nevigation('/Transaction/UsersLogin')}>Users Login</MenuItem>}
                    </Menu>
                </Grid>
            )}
            {(subscriptions_view ||
                setting_view ||
                users_view ||
                banner_view ||
                marketing_group_view ||
                marketing_promocodes_view ||
                ticker_view) && (
                <Grid item xs="auto">
                    <Button
                        id="basic-button"
                        variant="header"
                        aria-controls={Boolean(anhcorEl.more) ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anhcorEl.more) ? 'true' : undefined}
                        onClick={(e) =>
                            setAnchorEl(
                                Boolean(anhcorEl.transaction)
                                    ? { trade: null, transaction: null, more: null }
                                    : { trade: null, transaction: null, more: e.currentTarget }
                            )
                        }
                        sx={{ fontSize: '15px' }}
                    >
                        More
                    </Button>
                    <Menu
                        id="basic-menu"
                        open={Boolean(anhcorEl.more)}
                        anchorEl={anhcorEl.more}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ trade: null, transaction: null, more: null })}
                        PaperProps={{ sx: { width: '135px', bgcolor: 'secondary.main' } }}
                    >
                        {subscriptions_view && <MenuItem onClick={() => nevigation('/subscription')}>Subscriptions</MenuItem>}
                        {setting_view && <MenuItem onClick={() => nevigation('/settings')}>Settings</MenuItem>}
                        {users_view && role && <MenuItem onClick={() => nevigation('/Users')}>Users</MenuItem>}
                        {(marketing_promocodes_view || marketing_group_view) && (
                            <MenuItem
                                id="basic-button"
                                variant="header"
                                aria-controls={Boolean(anhcorEl1?.marketing) ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(anhcorEl1?.marketing) ? 'true' : undefined}
                                onClick={(e) => setAnchorEl1(Boolean(anhcorEl1?.marketing) ? {} : { marketing: e.currentTarget })}
                                sx={{ fontSize: '15px' }}
                            >
                                Marketing
                            </MenuItem>
                        )}
                        {banner_view && <MenuItem onClick={() => nevigation('/More/Banner')}>Banner</MenuItem>}
                        {ticker_view && <MenuItem onClick={() => nevigation('/More/Ticker')}>Ticker</MenuItem>}
                        <Menu
                            id="basic-menu"
                            open={Boolean(anhcorEl1.marketing)}
                            anchorEl={anhcorEl1.marketing}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            onClose={() => setAnchorEl1({ trade: null, transaction: null, more: null })}
                            PaperProps={{ sx: { width: '135px', bgcolor: 'secondary.main' } }}
                        >
                            {marketing_group_view && <MenuItem onClick={() => nevigation('/Marketing/Groups')}>Groups</MenuItem>}
                            {marketing_promocodes_view && (
                                <MenuItem onClick={() => nevigation('/Marketing/PromoCodes')}>Promo Codes</MenuItem>
                            )}
                            {/* <MenuItem onClick={() => nevigation('/Marketing/SMSMarketing')}>SMS Marketing</MenuItem> */}
                        </Menu>
                    </Menu>
                </Grid>
            )}
            <Grid item xs></Grid>
            <Grid item xs="auto">
                <Grid container width="180px" justifyContent="flex-end">
                    {[
                        '/customers/details',
                        '/Trade/Orders',
                        '/Trade/Open',
                        '/Trade/TradeHistory',
                        '/Transaction/Userp&l',
                        '/',
                        '/Trade/Closed'
                    ].includes(pathname) && (
                        <>
                            <Typography color={mode === 'live' ? '' : 'error.main'} fontWeight={mode === 'live' ? 400 : 700}>
                                Paper
                            </Typography>
                            <SwitchEdited
                                color="plus"
                                checked={mode === 'live'}
                                onChange={() => {
                                    dispatch(clearAllFilters());
                                    setTimeout(() => dispatch(changeMode()), 0);
                                }}
                            />
                            <Typography color={mode === 'live' ? 'plus.main' : ''} fontWeight={mode === 'live' ? 700 : 400}>
                                Live
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid item xs="auto">
                <IconButton
                    aria-controls={Boolean(anhcorEl?.profile) ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anhcorEl?.profile) ? 'true' : undefined}
                    onClick={(e) =>
                        setAnchorEl(
                            Boolean(anhcorEl?.profile)
                                ? { trade: null, transaction: null, more: null }
                                : { trade: null, transaction: null, more: null, profile: e.currentTarget }
                        )
                    }
                >
                    <Profile />
                </IconButton>
                <Menu
                    id="basic-menu"
                    open={Boolean(anhcorEl?.profile)}
                    anchorEl={anhcorEl?.profile}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    onClose={() => setAnchorEl({ trade: null, transaction: null, more: null })}
                    PaperProps={{
                        sx: {
                            width: 'min-content',
                            minWidth: '230px',
                            px: '20px',
                            py: '5px',
                            position: 'relative'
                        }
                    }}
                >
                    <Profilecomp />
                </Menu>
            </Grid>
        </>
    );
};
export default Header;
