import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchFilters, usePagination, usePermission } from '../components/common';
import Search from '../components/common/Search';
import { getKeyStyles } from '../components/Customers/api';
import AddGroup from '../components/more/Group/AddGroup';
import PlanDelete from '../components/more/subscription/PlanDelete';
import { Delete, Edit } from '../files/icon';
import { applyGroupFilter, clearGroupFilter } from '../store/filter';

const Group = () => {
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.group);
    const [anhcorEl, setAnchorEl] = useState({});
    const { data: groups, totalRecords, isLoading, paginationProp, mutate } = usePagination('/group-list', Filter);
    const [add, setAdd] = useState();
    const [deleteGroup, setDeleteGroup] = useState();
    const { marketing_group_delete, marketing_group_edit } = usePermission(['marketing-group-edit', 'marketing-group-delete']);

    const setFilter = (data) => {
        dispatch(applyGroupFilter(data));
    };

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                columnGap="12px"
                container
                justifyContent="flex-start"
                p={'12px'}
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Groups({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'group') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearGroupFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="status"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { date: null, status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        id="status"
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        {['Activate', 'Deactivate', ''].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.status === mode}
                                onClick={() => {
                                    setFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {marketing_group_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setAdd(true)}>
                            Add Group
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 53px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Creation Date </TableCell>
                            <TableCell>Users</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="left">Objective</TableCell>
                            {(marketing_group_edit || marketing_group_delete) && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups && groups?.length > 0 ? (
                            groups?.map((group) => (
                                <TableRow key={group.id}>
                                    <TableCell align="left">{group.name}</TableCell>
                                    <TableCell>{group.creation_date}</TableCell>
                                    <TableCell>{group.users.length}</TableCell>
                                    <TableCell>{getKeyStyles(group.status)}</TableCell>
                                    <TableCell align="left">{group.objective}</TableCell>
                                    {(marketing_group_edit || marketing_group_delete) && (
                                        <TableCell>
                                            {marketing_group_edit && (
                                                <IconButton sx={{ mr: marketing_group_delete ? '5px' : '' }} onClick={() => setAdd(group)}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {marketing_group_delete && (
                                                <IconButton onClick={() => setDeleteGroup({ group_id: group.id })}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>
            {add && <AddGroup data={add} onClose={() => setAdd(null)} mutate={mutate} />}
            {deleteGroup && (
                <PlanDelete data={deleteGroup} onClose={() => setDeleteGroup(null)} mutate={mutate} url="/group-delete" name="Group" />
            )}
        </>
    );
};

export default Group;
