import { CircularProgress, Dialog, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import BarChartNew from '../common/BarChartNew';
import { CustomList } from './api';

const ParformanceModal = ({ strategyId, setStrategyID }) => {
    const {
        state: { user_id }
    } = useLocation();
    const [age, setAge] = useState({});
    const { data, error } = useSWR(['/customers-strategies-performance', { ...strategyId, user_id, ...age }]);

    return (
        <Dialog open={Boolean(strategyId)} maxWidth="sm" PaperProps={{ sx: { padding: 0, py: 2 } }} onClose={() => setStrategyID(null)}>
            {!data && !error && (
                <Grid container>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Typography variant="h6" px={3}>
                        Daily Performance
                    </Typography>
                    <Grid px={3} pt={2}>
                        <BarChartNew
                            data={data?.chart_data?.chart_data}
                            setMonth={(month) => setAge((prev) => ({ ...prev, month }))}
                            setYear={(year) => setAge((prev) => ({ ...prev, year }))}
                            month={age?.month}
                            year={age?.year}
                        />
                    </Grid>
                    <Typography variant="h6" px={3} sx={{ mt: 2 }}>
                        Performance
                    </Typography>
                    <Grid container justifyContent="space-evenly" alignItems="flex-start">
                        <CustomList object={data?.data?.performance} lg={4.7} />
                    </Grid>
                    <Typography variant="h6" px={3} sx={{ mt: 2 }}>
                        Returns
                    </Typography>
                    <Grid container justifyContent="space-evenly">
                        <CustomList object={data?.data?.returns} lg={4.7} />
                    </Grid>
                </>
            )}
        </Dialog>
    );
};

export default ParformanceModal;
