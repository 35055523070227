import { Dialog, Typography, Grid, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import Api from '../../../api';
import { CustomList } from '../../Customers/api';
import { Details as Dtail } from '../../../files/icon';
import Button from '../../common/Button';
import { usePermission } from '../../common';

const Details = ({ invoice_id, setId }) => {
    const { data, error } = useSWR(['/gst-detail', { invoice_id }]);
    const { transaction_invoice_edit } = usePermission(['transaction-invoice-edit']);

    const onDownload = async (data) => {
        try {
            const response = await Api.post(
                '/download-invoices',
                { invoice_ids: data },
                {
                    responseType: 'arraybuffer'
                }
            );

            const date = new Date();

            const outputFilename = `Invoices ${date.toLocaleString('en-IN')}.${data?.length < 2 ? 'pdf' : 'zip'}`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <Dialog open={Boolean(invoice_id)} onClose={() => setId(null)} PaperProps={{ sx: { padding: 0, pt: 3, pb: 3, maxWidth: '800px' } }}>
            {!data && !error && (
                <Grid container>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Grid container justifyContent="space-between" px={3}>
                        <Grid item xs="auto">
                            <Typography fontSize="120%">{data.customer_name}</Typography>
                        </Grid>
                        {transaction_invoice_edit && (
                            <Grid item xs="auto">
                                <Button onClick={() => onDownload([invoice_id])} startIcon={<Dtail />}>
                                    Invoice
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {data?.status ? (
                        <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                            <CustomList object={data?.data} />
                        </Grid>
                    ) : (
                        <Grid container>No data</Grid>
                    )}
                    <Grid container alignItems="flex-start">
                        <Grid item xs={6}>
                            <Typography fontSize="120%" pt={2} px={3}>
                                Billing Detail
                            </Typography>
                            {Object.keys(data?.billing_data).length > 0 ? (
                                <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                                    <CustomList object={data?.billing_data} columns={1} />
                                </Grid>
                            ) : (
                                <Grid container>No data</Grid>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography fontSize="120%" pt={2} px="15px">
                                Bank Detail
                            </Typography>
                            {Object.keys(data?.bank_details).length > 0 ? (
                                <Grid container justifyContent="space-evenly" alignItems="flex-start" pt={1}>
                                    <CustomList object={data?.bank_details} columns={1} pl="15px" lg={5} />
                                </Grid>
                            ) : (
                                <Grid container>No data</Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </Dialog>
    );
};
export default Details;
