import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { usePermission } from '../components/common';
import { getKeyStyles } from '../components/Customers/api';
import AddPlanDialog from '../components/more/subscription/AddPlanDialog';
import PlanDelete from '../components/more/subscription/PlanDelete';
import { Delete, Edit, Default1 } from '../files/icon';
import { applyMoreSubscriptionFilter } from '../store/filter';

const Subrcription = () => {
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.moreSubscription);
    const [anhcorEl, setAnchorEl] = useState({});
    const { data, error, mutate } = useSWR(['/membership-plan-listing', Filter]);
    const [plan, setPlan] = useState();
    const [deletePlan, setDeletePlan] = useState();
    const { subscriptions_delete, subscriptions_edit } = usePermission(['subscriptions-edit', 'subscriptions-delete']);

    const setFilter = (data) => {
        dispatch(applyMoreSubscriptionFilter(data));
    };

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                gap="12px"
                container
                justifyContent="space-around"
                px="12px"
                py="8px"
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography variant="h6">Subscriptions({data?.membership_plans?.length})</Typography>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="status"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { date: null, status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        id="status"
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        {[
                            { name: '', value: '' },
                            { name: 'Active', value: true },
                            { name: 'Deactive', value: false }
                        ].map((mode) => (
                            <MenuItem
                                key={mode.name}
                                disabled={Filter?.status === mode.value}
                                onClick={() => {
                                    setFilter({ status: mode.value });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode.name === '' ? 'All' : mode.name.replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {subscriptions_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '1px' }} onClick={() => setPlan(true)}>
                            Add Plan
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 50px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>iOS Price</TableCell>
                            <TableCell>Profit Share</TableCell>
                            <TableCell>Status</TableCell>
                            {(subscriptions_edit || subscriptions_delete) && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.membership_plans?.length > 0 ? (
                            data?.membership_plans?.map((membership) => (
                                <TableRow key={membership.id}>
                                    <TableCell align="left">
                                        {membership.name}{' '}
                                        {membership?.is_default ? (
                                            <Box component="span" position="absolute" pl="10px">
                                                <Default1 />
                                            </Box>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell>{membership.duration}</TableCell>
                                    <TableCell>{membership.price}</TableCell>
                                    <TableCell>{membership.ios_price}</TableCell>
                                    <TableCell>{Number(membership.profit_share) > 0 ? `${membership.profit_share}%` : ''}</TableCell>
                                    <TableCell>{getKeyStyles(membership.status)}</TableCell>
                                    {(subscriptions_edit || subscriptions_delete) && (
                                        <TableCell>
                                            {subscriptions_edit && (
                                                <IconButton sx={{ mr: '10px' }} onClick={() => setPlan(membership)}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {subscriptions_delete && (
                                                <IconButton onClick={() => setDeletePlan({ plan_id: membership.id })}>
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : !data && !error ? (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {plan && <AddPlanDialog data={plan} onClose={() => setPlan(null)} mutate={mutate} />}
            {deletePlan && (
                <PlanDelete
                    data={deletePlan}
                    mutate={mutate}
                    onClose={() => setDeletePlan(null)}
                    url="/membership-plan-delete"
                    name="Plan"
                />
            )}
        </>
    );
};

export default Subrcription;
