import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    Pagination,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetcher } from '../api';
import { matchFilters, usePagination, usePermission } from '../components/common';
import Search from '../components/common/Search';
import { getColor } from '../components/Customers/api';
import { getFilters, getOrderKey } from '../components/Trade/api';
import BrokerFilter from '../components/Trade/BrokerFilter';
import DateFilter from '../components/Trade/DateFilter';
import StatusEditDialog from '../components/Trade/StatusEditDialog';
import StatusFilter from '../components/Trade/StatusFilter';
import StrategyFilter from '../components/Trade/StrategyFilter';
import { Edit, Reorder } from '../files/icon';
import { applyOrderbookFilter, clearOrderbookFilter } from '../store/filter';

const ReorderComp = ({ order_id, mutate, user_id }) => {
    const [loading, setLoading] = useState(false);

    return loading ? (
        <CircularProgress size={20} />
    ) : (
        <IconButton
            onClick={async () => {
                setLoading(true);
                const response = await fetcher('/reorder', { order_id, user_id });
                if (response?.status) {
                    mutate();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }}
        >
            <Reorder />
        </IconButton>
    );
};

const today = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month1 = date.getMonth() + 1;
    const month = month1 < 10 ? `0${month1}` : month1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${day}-${month}-${year}`;
};

const Orderbook = () => {
    const mode = useSelector((state) => state.reducer.mode);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.orderbook);
    const [orderId, setOrderId] = useState(null);
    const [reorderAll, setReorderAll] = useState(false);
    const [anhcorEl, setAnchorEl] = useState({ date: null, Strategy: null });
    const { data: orderBook, totalRecords, isLoading, paginationProp, mutate } = usePagination('/orders-book', getFilters(Filter));
    const { orderbook_edit } = usePermission(['orderbook-edit']);

    const setFilter = (data) => {
        dispatch(applyOrderbookFilter(data));
    };

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                columnGap="12px"
                container
                justifyContent="flex-start"
                p={'12px'}
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Orders({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'orderbook') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearOrderbookFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                {orderbook_edit && (
                    <Grid item xs="auto">
                        <Switch size="small" checked={reorderAll} onChange={() => setReorderAll((prev) => !prev)} />
                    </Grid>
                )}
                {mode === 'live' && (
                    <Grid item xs="auto">
                        <Button
                            id="status"
                            aria-haspopup="true"
                            onClick={(e) => setAnchorEl(Boolean(anhcorEl?.broker) ? {} : { date: null, broker: e.currentTarget })}
                            sx={{ py: '1px' }}
                        >
                            Broker
                        </Button>
                        <Menu
                            id="status"
                            open={Boolean(anhcorEl?.broker)}
                            anchorEl={anhcorEl?.broker}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            onClose={() => setAnchorEl({})}
                            PaperProps={{ sx: { width: 'min-content' } }}
                        >
                            <BrokerFilter setAnchorEl={setAnchorEl} setFilter={setFilter} Filter={Filter} />
                        </Menu>
                    </Grid>
                )}
                <Grid item xs="auto">
                    <Button
                        id="status"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { date: null, status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        id="status"
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        <StatusFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Strategy"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.Strategy) ? {} : { date: null, Strategy: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Strategy
                    </Button>
                    <Menu
                        id="Strategy"
                        open={Boolean(anhcorEl?.Strategy)}
                        anchorEl={anhcorEl?.Strategy}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null })}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        <StrategyFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(Boolean(anhcorEl.date) ? { date: null, Strategy: null } : { date: e.currentTarget, Strategy: null })
                        }
                        sx={{ py: '1px' }}
                    >
                        Date/Time
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null })}
                        PaperProps={{ sx: { width: '400px' } }}
                    >
                        <DateFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 53px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ zIndex: 2 }}>
                                Name
                            </TableCell>
                            <TableCell>Date/Time</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="left">Strategy</TableCell>
                            <TableCell align="left">Order ID</TableCell>
                            <TableCell align="left">Symbol</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Qty(Lots)</TableCell>
                            <TableCell>Avg. Price</TableCell>
                            {mode === 'live' && <TableCell>Broker</TableCell>}
                            {orderbook_edit && <TableCell sx={{ zIndex: 2 }}>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderBook && orderBook?.length > 0 ? (
                            orderBook?.map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() => {
                                                // dispatch(applyFilter({ orderBook: Filter }));
                                                navigate('/customers/details', {
                                                    state: { user_id: order.user_id, customer_name: order.customer_phone }
                                                });
                                            }}
                                        >
                                            {order.customer}
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        <span style={{ display: 'block' }}>{order.date_time?.split(' ')[0]}</span>
                                        <Typography component="span" variant="caption" color="#878C96">
                                            {order.date_time?.split(' ')[1]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {order?.status === 'rejected' ? (
                                            <Tooltip title={order.reason}>{getOrderKey(order.status)}</Tooltip>
                                        ) : (
                                            getOrderKey(order.status)
                                        )}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Tooltip title={order.strategy_name}>
                                            <span>{order.strategy_code}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="left">{order.order_id}</TableCell>
                                    <TableCell align="left" sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        <span style={{ display: 'block' }}>{order.title}</span>
                                        <Typography component="span" variant="caption" color="#878C96">
                                            {order.subtitle}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(order.action) }}>{order.action}</TableCell>
                                    <TableCell>{`${order.quantity}(${order.quantity / order.lotsize})`}</TableCell>
                                    <TableCell>{order.averageprice}</TableCell>
                                    {mode === 'live' && (
                                        <TableCell align="left">
                                            <Avatar
                                                src={order.broker_logo}
                                                variant="square"
                                                sx={{ display: 'block', margin: '0 auto', height: '28px', width: '28px' }}
                                            />
                                        </TableCell>
                                    )}
                                    {orderbook_edit && (
                                        <TableCell>
                                            {((order.orderstatus === 'rejected' && today() === order.date_time?.split(' ')[0]) ||
                                                reorderAll) && <ReorderComp mutate={mutate} order_id={order.id} user_id={order.user_id} />}
                                            <IconButton onClick={() => setOrderId(order.id)} sx={{ ml: '10px' }}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={14} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>
            {orderId && <StatusEditDialog order_book_id={orderId} onClose={() => setOrderId(null)} mutate={mutate} />}
        </>
    );
};

export default Orderbook;
