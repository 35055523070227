import {
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileEdit, ProfileEmail, ProfileMan, ProfileName } from '../files/icon';
import { logout } from '../store/reducer';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Api, { fetcher } from '../api';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import Button from '../components/common/Button';
import { publicIpv4 } from 'public-ip';

const Profile = () => {
    const reducer = useSelector((state) => state.reducer.data);
    const { data, error, mutate } = useSWR(['/get-profile', { user_id: reducer._id }]);
    const dispatch = useDispatch();
    const [show, setShow] = useState('profile');
    const schema = yup.object().shape({
        first_name: yup.string().required('This field is required'),
        last_name: yup.string().required('This field is required'),
        email: yup.string().email().required('This field is required')
    });

    const passwordSchema = yup.object().shape({
        old_password: yup.string().required('password is required'),
        new_password: yup
            .string()
            .required('password is required')
            .min(8, 'min 8 character')
            .matches(
                /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                'Your password must contain at least one Uppercase, Lowercase, Digit & Special Characters.'
            ),
        confirmPassword: yup
            .string()
            .required('Password does not match')
            .oneOf([yup.ref('new_password'), null], 'Password does not match')
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: data?.data?.first_name,
            last_name: data?.data?.last_name,
            email: data?.data?.email
        }
    });

    const {
        register: passRegister,
        handleSubmit: passHandleSubmit,
        formState: { errors: passErrors }
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(passwordSchema)
    });

    useEffect(() => {
        reset({ first_name: data?.data?.first_name, last_name: data?.data?.last_name, email: data?.data?.email });
    }, [data, reset]);

    const saveProfile = async (data) => {
        const response = await Api.post('/update-profile', { ...data, user_id: reducer._id });

        if (response.data.status) {
            mutate();
            setShow('profile');
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
    };

    const changePassword = async (data) => {
        const response = await Api.post('/change-password', { ...data, user_id: reducer._id });

        if (response.data.status) {
            mutate();
            setShow('profile');
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <>
            {!data && !error && (
                <Grid container>
                    <CircularProgress />
                </Grid>
            )}
            {data && show === 'profile' && (
                <>
                    <Typography textAlign="center" fontWeight={700}>
                        Profile
                    </Typography>
                    <List disablePadding>
                        <ListItem disableGutters disablePadding divider>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <ProfileName />
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Typography variant="body2" fontSize="75%" color="text.secondary">
                                    Name
                                </Typography>
                                <Typography fontSize="95%">{`${data?.data?.first_name} ${data?.data?.last_name}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem disableGutters disablePadding divider>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <ProfileEmail />
                            </ListItemIcon>
                            <ListItemText disableTypography>
                                <Typography variant="body2" fontSize="75%" color="text.secondary">
                                    Email
                                </Typography>
                                <Typography fontSize="95%">{data?.data?.email}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                    <Button
                        variant="header"
                        onClick={() => setShow('changepassword')}
                        sx={{ color: 'primary.main', mt: '10px', mb: '5px' }}
                    >
                        Change Password
                    </Button>
                    <Button
                        sx={{ borderRadius: '2px', py: '4px', fontWeight: 700 }}
                        onClick={async () => {
                            const response = await fetcher('/admin-logout', {
                                email: reducer?.email,
                                ip: await publicIpv4(),
                                token: reducer?.verify_token
                            });
                            if (response.status) {
                                dispatch(logout());
                                setTimeout(() => {
                                    window.location.replace(
                                        window.location.hostname.includes('algosuccess')
                                            ? 'https://algosuccess.xyz/1707-coretus/signin'
                                            : 'http://localhost:3000/1707-coretus/signin'
                                    );
                                }, 0);
                            } else {
                                toast.error(response.message);
                            }
                        }}
                    >
                        Logout
                    </Button>
                    <IconButton onClick={() => setShow('editProfile')} sx={{ position: 'absolute', top: 5, right: -10 }}>
                        <ProfileEdit />
                    </IconButton>
                </>
            )}
            {show === 'editProfile' && (
                <>
                    <Typography textAlign="center" fontWeight={700}>
                        Edit
                    </Typography>
                    <Typography variant="body2" fontSize="80%" color="text.secondary" mt="5px">
                        First Name
                    </Typography>
                    <Input
                        {...register('first_name')}
                        startAdornment={
                            <InputAdornment position="start">
                                <ProfileMan />
                            </InputAdornment>
                        }
                        sx={{ border: 'none' }}
                    />
                    <Divider />
                    <Typography variant="body2" fontSize="80%" color="error">
                        {errors?.first_name?.message}
                    </Typography>
                    <Typography variant="body2" fontSize="80%" color="text.secondary" mt="5px">
                        Last Name
                    </Typography>
                    <Input
                        {...register('last_name')}
                        startAdornment={
                            <InputAdornment position="start">
                                <ProfileMan />
                            </InputAdornment>
                        }
                        sx={{ border: 'none' }}
                    />
                    <Divider />
                    <Typography variant="body2" fontSize="80%" color="error">
                        {errors?.last_name?.message}
                    </Typography>
                    <Typography variant="body2" fontSize="80%" color="text.secondary" mt="5px">
                        Email
                    </Typography>
                    <Input
                        {...register('email')}
                        startAdornment={
                            <InputAdornment position="start">
                                <ProfileMan />
                            </InputAdornment>
                        }
                        sx={{ border: 'none' }}
                    />
                    <Divider />
                    <Typography variant="body2" fontSize="80%" color="error">
                        {errors?.email?.message}
                    </Typography>
                    <Button sx={{ borderRadius: '2px', py: '4px', mt: '20px', fontWeight: 700 }} onClick={handleSubmit(saveProfile)}>
                        Save
                    </Button>
                </>
            )}
            {show === 'changepassword' && (
                <>
                    <Typography textAlign="center" fontWeight={700}>
                        Change Password
                    </Typography>
                    <Typography variant="body2" fontSize="80%" color="text.secondary" mt="5px">
                        Current Password
                    </Typography>
                    <Input
                        {...passRegister('old_password')}
                        startAdornment={
                            <InputAdornment position="start">
                                <ProfileMan />
                            </InputAdornment>
                        }
                        type="password"
                        sx={{ border: 'none' }}
                    />
                    <Divider />
                    <Typography variant="body2" fontSize="80%" color="error">
                        {passErrors?.old_password?.message}
                    </Typography>
                    <Typography variant="body2" fontSize="80%" color="text.secondary" mt="5px">
                        New Password
                    </Typography>
                    <Input
                        {...passRegister('new_password')}
                        startAdornment={
                            <InputAdornment position="start">
                                <ProfileMan />
                            </InputAdornment>
                        }
                        type="password"
                        sx={{ border: 'none' }}
                    />
                    <Divider />
                    <Typography variant="body2" fontSize="80%" color="error">
                        {passErrors?.new_password?.message}
                    </Typography>
                    <Typography variant="body2" fontSize="80%" color="text.secondary" mt="5px">
                        Confirm Password
                    </Typography>
                    <Input
                        {...passRegister('confirmPassword')}
                        startAdornment={
                            <InputAdornment position="start">
                                <ProfileMan />
                            </InputAdornment>
                        }
                        type="password"
                        sx={{ border: 'none' }}
                    />
                    <Divider />
                    <Typography variant="body2" fontSize="80%" color="error">
                        {passErrors?.confirmPassword?.message}
                    </Typography>
                    <Button sx={{ borderRadius: '2px', py: '4px', mt: '20px', fontWeight: 700 }} onClick={passHandleSubmit(changePassword)}>
                        Save
                    </Button>
                </>
            )}
        </>
    );
};
export default Profile;
