import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Dialog, Grid, IconButton, Input, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import * as yup from 'yup';
import { fetcher } from '../../api';
import { Eye } from '../../files/icon';
import AsyncForm from '../common/AsyncForm';
import { update } from '../../store/reducer';
import qs from 'query-string';

const ConnectBroker = ({ broker, onClose, is_o_auth, mutate }) => {
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    const [show, setShow] = useState();
    const dispatch = useDispatch();
    const schema = yup.object().shape(
        broker?.data?.broker_query_params?.reduce(
            (acc, cv) => ({
                ...acc,
                ...(cv.textfield && {
                    [cv.key_name]: yup.string().when('bh', {
                        is: () => cv.textfield.is_required,
                        then: yup.string().required(`${cv.textfield.field_name} is required`)
                    })
                })
            }),
            {}
        )
    );
    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: broker?.data?.broker_query_params?.reduce(
            (acc, cv) => ({
                ...acc,
                ...(cv.textfield && {
                    [cv.key_name]: cv.key_value
                })
            }),
            {}
        )
    });

    const connect = async (values) => {
        if (is_o_auth) {
            const query = broker.data.broker_query_params.reduce(
                (acc, key) => ({
                    ...acc,
                    ...(key.is_need_to_send_in_webview_url && {
                        [key.key_name]: values[key.key_name] && values[key.key_name].length > 0 ? values[key.key_name] : key.key_value
                    })
                }),
                { broker_id: broker.data.id, user_id }
            );

            setTimeout(() => {
                dispatch(update({ broker_id: query }));
            }, 0);

            const urlData = qs.parseUrl(broker.data.redirect_url);

            const link = document.createElement('a');
            link.href = `${urlData.url}?${qs.stringify({ ...urlData.query, ...query })}`;
            link.click();
        } else {
            const response = await fetcher('/socket-update', { broker_id: broker.data.id, ...values });

            if (response.status) {
                onClose();
                mutate();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }
    };

    return (
        <AsyncForm onSubmit={handleSubmit(connect)} mt="5px" buttonText="login">
            {broker?.data?.broker_query_params.map((input) => {
                if (Boolean(input.textfield)) {
                    return (
                        <Grid key={input.key_name} height="75px">
                            <Typography>{input.textfield.field_name}</Typography>
                            <Input
                                {...register(input.key_name)}
                                placeholder={input?.textfield?.hint}
                                type={
                                    input?.textfield?.issecured
                                        ? show?.name === input.key_name && show?.show
                                            ? input?.textfield?.inputtype
                                            : 'password'
                                        : input?.textfield?.inputtype
                                }
                                error={Boolean(errors?.[input.key_name]?.message)}
                                {...(input.textfield.issecured && {
                                    endAdornment: (
                                        <IconButton onClick={() => setShow((prev) => ({ name: input.key_name, show: !prev?.show }))}>
                                            <Eye />
                                        </IconButton>
                                    )
                                })}
                            />
                            <Typography color="error.main">{errors?.[input.key_name]?.message}</Typography>
                        </Grid>
                    );
                } else {
                    return '';
                }
            })}
        </AsyncForm>
    );
};

const SocketEdit = ({ data, onClose, mutate, brokers }) => {
    const schema = yup.object().shape({ broker: yup.object().required() });
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    const { control, formState, watch } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(schema),
        defaultValues: { broker: brokers?.data.find((broker) => broker.id === data.broker_id) }
    });
    const { data: broker } = useSWR(watch('broker')?.name && ['/socket-broker-detail-oauth', { broker_id: watch('broker').id, user_id }]);

    const getBrokerHeight = (brokerName) => {
        if (brokerName === 'Angel One' || brokerName === 'Zerodha') {
            return '430px';
        }
        if (brokerName === 'Kotak Securities') {
            return '350px';
        }
        return '360px';
    };

    return (
        <Dialog
            open={Boolean(data)}
            onClose={onClose}
            maxWidth="xs"
            PaperProps={{ sx: { maxWidth: '300px', minWidth: '350px', minHeight: broker ? getBrokerHeight(watch('broker').name) : '' } }}
        >
            <Typography textAlign="center" variant="h6" pb={1}>
                {typeof data === 'object' ? 'Edit' : 'Add'} Socket
            </Typography>
            <Grid height="78px" px={2}>
                <Typography>Broker</Typography>
                <Controller
                    control={control}
                    name={'broker'}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField error={Boolean(formState?.errors?.broker?.message)} {...params} fullWidth />
                            )}
                            onChange={(e, v) => {
                                onChange(v);
                            }}
                            onBlur={onBlur}
                            value={value}
                            options={brokers?.data || []}
                            getOptionLabel={(option) => option.name}
                            loading={!brokers}
                        />
                    )}
                />
                <Typography color="error" mb="1rem">
                    {formState?.errors?.broker?.message}
                </Typography>
                {watch('broker')?.name && broker && (
                    <ConnectBroker broker={broker} onClose={onClose} is_o_auth={watch('broker')?.is_o_auth} mutate={mutate} />
                )}
            </Grid>
        </Dialog>
    );
};
export default SocketEdit;
