import {
    CircularProgress,
    Grid,
    Menu,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Export } from '../../files/icon';
import Api from '../../api';
import Button from '../common/Button';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../common';
import DateFilter from '../Trade/DateFilter';
import Search from '../common/Search';
import { getFilters } from '../Trade/api';
import { toast } from 'react-toastify';
import { getColor } from '../Customers/api';
import { useDispatch, useSelector } from 'react-redux';
import { applyUserpnlFilter, clearUserpnlFilter } from '../../store/filter';

const Userpnl = () => {
    const navigate = useNavigate();
    const { transaction_user_pnl_edit } = usePermission(['transaction-user-pnl-edit']);
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.userpnl);
    const [anhcorEl, setAnchorEl] = useState({});
    const { data: membership, totalRecords, isLoading, paginationProp, rest } = usePagination('/user-pnl-data', getFilters(Filter));

    const onExport = async () => {
        try {
            const response = await Api.post('/user-pnl-data-export', getFilters(Filter), { responseType: 'arraybuffer' });

            const date = new Date();

            const outputFilename = `User P&L ${date.toLocaleString('en-IN')}.xlsx`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const setFilter = (data) => {
        dispatch(applyUserpnlFilter(data));
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Typography fontSize={'120%'}>User P&L ({totalRecords || 0})</Typography>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'userpnl') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearUserpnlFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(Boolean(anhcorEl?.date) ? {} : { date: e.currentTarget, payment: null, membership: null })
                        }
                        sx={{ py: '1px' }}
                    >
                        Date
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null, membership: null })}
                        PaperProps={{ sx: { width: '280px' } }}
                    >
                        <DateFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} customFilters={false} />
                    </Menu>
                </Grid>
                {transaction_user_pnl_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={onExport}>
                            <Export />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 49px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ width: '20%' }}>
                                Name
                            </TableCell>
                            <TableCell sx={{ width: '10%' }}>ROI</TableCell>
                            <TableCell>Lifetime</TableCell>
                            <TableCell>Custom</TableCell>
                            <TableCell>This Month</TableCell>
                            <TableCell>This Week</TableCell>
                            <TableCell>Prev. Day</TableCell>
                            <TableCell>Today</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {membership && membership?.length > 0 ? (
                            membership?.map((order, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" sx={{ py: '15px' }}>
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: order.user_id, customer_name: order.customer_phone }
                                                })
                                            }
                                        >
                                            {order.customer_name}
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(order.roi) }}>{order.roi}</TableCell>
                                    <TableCell sx={{ color: getColor(order.lifetime_pnl) }}>{rupeeFormat(order.lifetime_pnl)}</TableCell>
                                    <TableCell sx={{ color: getColor(order.custom_pnl) }}>{rupeeFormat(order.custom_pnl)}</TableCell>
                                    <TableCell sx={{ color: getColor(order.this_month_pnl) }}>
                                        {rupeeFormat(order.this_month_pnl)}
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(order.this_week_pnl) }}>{rupeeFormat(order.this_week_pnl)}</TableCell>
                                    <TableCell sx={{ color: getColor(order.yesterday_pnl) }}>{rupeeFormat(order.yesterday_pnl)}</TableCell>
                                    <TableCell sx={{ color: getColor(order.today_pnl) }}>{rupeeFormat(order.today_pnl)}</TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={13}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={13} sx={{ py: '12px' }}>
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2} sx={{ padding: '0 16px' }} width="25%">
                                {paginationProp.count > 1 && (
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        bgcolor="background.default"
                                        sx={{ borderRadius: '0 0 5px 5px' }}
                                    >
                                        <Pagination
                                            {...paginationProp}
                                            showFirstButton={false}
                                            showLastButton={false}
                                            color="primary"
                                            onChange={(e, v) => setFilter({ current_page: v })}
                                        />
                                    </Grid>
                                )}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '5px 0px 0px 5px',
                                    borderRight: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_lifetime_pnl || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_lifetime_pnl || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_custom_pnl || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_custom_pnl || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_this_month_pnl || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_this_month_pnl || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_this_week_pnl || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_this_week_pnl || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_yesterday_pnl || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_yesterday_pnl || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '0px 5px 5px 0px',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_today_pnl || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_today_pnl || 0)}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default Userpnl;
