import {
    Autocomplete,
    Avatar,
    Badge,
    Chip,
    Dialog,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Input,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Api from '../../api';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddField, Cross, ExitForm } from '../../files/icon';
import Button from '../common/Button';

const BrokersDialog = ({ broker, setBroker, mutate }) => {
    const schema = yup.object().shape({
        image: yup.mixed().test('image', 'please add image', (value) => {
            return value.length > 0;
        }),
        status: yup.boolean().required(),
        o_auth: yup.boolean().required(),
        name: yup.string().required(),
        redirection_url: yup.string().when('o_auth', {
            is: (o_auth) => o_auth,
            then: yup.string().required(),
            otherwise: yup.string()
        }),
        demat_referral_link: yup.string(),
        description: yup.string(),
        guide_open_account_link: yup.string().required('Help URL is required'),
        host_url: yup.string(),
        brokerage: yup.string().required(),
        show_popup: yup.boolean().required(),
        // extra_fields: yup.array().of(
        //     yup.object().shape({
        //         key_name: yup.string().required('key name is required'),
        //         key_value: yup.string().required('key value is required'),
        //         placeholder: yup.string().required('key value is required'),
        //         validation: yup.array().of(yup.string()).required('validation is required').min(1, 'validation is required'),
        //         field_type: yup.string().required('Field type is required')
        //     })
        // ),
        extra_fields: yup.array().when('show_popup', {
            is: (show_popup) => show_popup,
            then: yup.array().of(
                yup.object().shape({
                    key_name: yup.string().required('key name is required'),
                    key_value: yup.string().required('key value is required'),
                    placeholder: yup.string().required('key value is required'),
                    validation: yup.array().of(yup.string()).required('validation is required').min(1, 'validation is required'),
                    field_type: yup.string().required('Field type is required')
                })
            ),
            otherwise: yup.array()
        })
    });
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            show_popup: false,
            ...broker,
            extra_fields:
                broker?.extra_fields?.length > 0
                    ? broker?.extra_fields
                    : [
                          {
                              key_name: '',
                              key_value: '',
                              placeholder: '',
                              validation: [],
                              field_type: ''
                          }
                      ]
        }
    });

    const { fields, ...rest } = useFieldArray({
        control,
        name: 'extra_fields'
    });
    const addBroker = async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if (key === 'image' && data['image'] && typeof data['image'] === 'object' && data['image'].length > 0) {
                formData.append('image', new Blob(data['image']), data['image'][0].name);
            } else if (key === 'extra_fields' && data['extra_fields']) {
                formData.append('extra_fields', JSON.stringify(data['extra_fields']));
            } else {
                formData.append(key, data[key]);
            }
        });

        if (broker['id']) {
            formData.append('broker_id', broker['id']);
        }
        const response = await Api.post('/add-broker', formData);
        if (response.data.status) {
            toast(response.data.message);
            mutate();
            setBroker(null);
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <Dialog open={Boolean(broker)} onClose={() => setBroker(null)}>
            <Typography variant="h6" textAlign="center" pb="10px">
                {Boolean(broker.id) ? 'Edit' : 'Add'} Broker Details
            </Typography>
            <Grid container alignItems="flex-start" justifyContent="flex-start" columnSpacing="15px" px="25px">
                <Grid item xs={6}>
                    <Typography variant="body2">Name</Typography>
                    <Input error={Boolean(errors?.name?.message)} {...register('name')} />
                    <Typography color="error" variant="body2">
                        {errors?.name?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Logo</Typography>
                    <Input error={Boolean(errors?.image?.message)} {...register('image')} type="file" />
                    {watch('image')?.length > 0 && (
                        <Badge
                            badgeContent={
                                <IconButton sx={{ padding: 0 }} onClick={() => setValue('image', {})}>
                                    <Cross />
                                </IconButton>
                            }
                        >
                            <Avatar
                                src={typeof watch('image') === 'object' ? URL.createObjectURL(new Blob(watch('image'))) : watch('image')}
                                alt="select a image"
                                sx={{ my: 1 }}
                            />
                        </Badge>
                    )}

                    <Typography color="error" variant="body2">
                        {errors?.image?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                onChange={(e, v) => onChange(v)}
                                renderInput={(params) => <TextField {...params} error={Boolean(errors?.status?.message)} />}
                                onBlur={onBlur}
                                value={value}
                                options={[true, false]}
                                getOptionLabel={(option) => (option ? 'Yes' : 'No')}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Demat Referral Link</Typography>
                    <Input error={Boolean(errors?.demat_referral_link?.message)} {...register('demat_referral_link')} />
                    <Typography color="error" variant="body2">
                        {errors?.demat_referral_link?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="140px">
                    <Typography variant="body2">is oAuth</Typography>
                    <Controller
                        control={control}
                        name="o_auth"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                onChange={(e, v) => {
                                    clearErrors();
                                    onChange(v);
                                }}
                                onBlur={onBlur}
                                renderInput={(params) => <TextField {...params} error={Boolean(errors?.o_auth?.message)} />}
                                value={value}
                                options={[true, false]}
                                getOptionLabel={(option) => (option ? 'Yes' : 'No')}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.o_auth?.message}
                    </Typography>
                    {watch('o_auth') && (
                        <>
                            <Typography variant="body2" pt={'15px'}>
                                Redirection Link
                            </Typography>
                            <Input error={Boolean(errors?.redirection_url?.message)} {...register('redirection_url')} />
                            <Typography color="error" variant="body2">
                                {errors?.redirection_url?.message}
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid item xs={6} minHeight="140px">
                    <Typography variant="body2">Description</Typography>
                    <Input error={Boolean(errors?.description?.message)} {...register('description')} multiline minRows={4} />
                    <Typography color="error" variant="body2">
                        {errors?.description?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Host URL</Typography>
                    <Input error={Boolean(errors?.host_url?.message)} {...register('host_url')} />
                    <Typography color="error" variant="body2">
                        {errors?.host_url?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Help URL</Typography>
                    <Input error={Boolean(errors?.guide_open_account_link?.message)} {...register('guide_open_account_link')} />
                    <Typography color="error" variant="body2">
                        {errors?.guide_open_account_link?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Brokerage</Typography>
                    <Input type="number" error={Boolean(errors?.brokerage?.message)} {...register('brokerage')} />
                    <Typography color="error" variant="body2">
                        {errors?.brokerage?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography textTransform="capitalize" variant="body2">
                        Show Popup
                    </Typography>
                    <Controller
                        control={control}
                        name={'show_popup'}
                        defaultValue={'No'}
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
                            </RadioGroup>
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.is_send?.message}
                    </Typography>
                </Grid>
            </Grid>
            <Typography textAlign="center" variant="h6" pb="5px">
                Login Field
            </Typography>
            {fields.map((field, index, arr) => {
                return (
                    <>
                        <Grid container px="25px" columnSpacing="15px" pt={index > 0 ? '10px' : '0px'} pb="7px" key={index}>
                            <Grid item xs={6} minHeight="70px">
                                <Typography variant="body2">Key Name</Typography>
                                <Input
                                    {...register(`extra_fields.${index}.key_name`)}
                                    placeholder="Key Name"
                                    error={Boolean(errors?.extra_fields?.[index]?.key_name?.message)}
                                />
                                <Typography color="error" variant="body2">
                                    {errors?.extra_fields?.[index]?.key_name?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} minHeight="70px">
                                <Typography variant="body2">Key Value</Typography>
                                <Input
                                    {...register(`extra_fields.${index}.key_value`)}
                                    error={Boolean(errors?.extra_fields?.[index]?.key_value?.message)}
                                    placeholder="Key Value"
                                />
                                <Typography color="error" variant="body2">
                                    {errors?.extra_fields?.[index]?.key_value?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} minHeight="70px">
                                <Typography variant="body2">Field Type</Typography>
                                <Controller
                                    control={control}
                                    name={`extra_fields.${index}.field_type`}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Autocomplete
                                            onChange={(e, v) => onChange(v)}
                                            onBlur={onBlur}
                                            renderInput={(params) => (
                                                <TextField
                                                    error={Boolean(errors?.extra_fields?.[index]?.field_type?.message)}
                                                    {...params}
                                                    placeholder="Field Type"
                                                />
                                            )}
                                            value={value}
                                            options={['text', 'password', 'email', 'checkbox', 'select', 'radio']}
                                        />
                                    )}
                                />
                                <Typography color="error" variant="body2">
                                    {errors?.extra_fields?.[index]?.field_type?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} minHeight="70px">
                                <Typography variant="body2">Validation</Typography>
                                <Controller
                                    control={control}
                                    name={`extra_fields.${index}.validation`}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Autocomplete
                                            multiple
                                            value={value}
                                            onChange={(e, v) => {
                                                onChange(v);
                                            }}
                                            onBlur={onBlur}
                                            options={['issecured', 'is_required', 'is_space_allowed', 'is_need_to_send_in_webview_url']}
                                            getOptionLabel={(option) => option.replaceAll('_', ' ')}
                                            renderTags={(tagValue, getTagProps) =>
                                                tagValue.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    error={Boolean(errors?.extra_fields?.[index]?.validation?.message)}
                                                    {...params}
                                                    placeholder="Validation"
                                                />
                                            )}
                                            clearIcon={null}
                                        />
                                    )}
                                />
                                <Typography color="error" variant="body2">
                                    {errors?.extra_fields?.[index]?.validation?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs minHeight="70px">
                                <Typography variant="body2">Placeholder</Typography>
                                <Input
                                    {...register(`extra_fields.${index}.placeholder`)}
                                    error={Boolean(errors?.extra_fields?.[index]?.placeholder?.message)}
                                    placeholder="Placeholder"
                                />
                                <Typography color="error" variant="body2">
                                    {errors?.extra_fields?.[index]?.placeholder?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                {fields.length === index + 1 || watch('extra_fields').length === 1 ? (
                                    <IconButton
                                        onClick={() =>
                                            rest.append({
                                                key_name: '',
                                                key_value: '',
                                                placeholder: '',
                                                validation: [],
                                                field_type: ''
                                            })
                                        }
                                    >
                                        <AddField />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => rest.remove(index)}>
                                        <ExitForm />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                        {index < arr.length - 1 && <Divider />}
                    </>
                );
            })}
            <Button sx={{ width: '10%', ml: '45%', mt: 3 }} onClick={handleSubmit(addBroker)}>
                Update
            </Button>
        </Dialog>
    );
};

export default BrokersDialog;
