import { Breadcrumbs, Button, Grid, IconButton } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';

import { useState } from 'react';
import { useEffect } from 'react';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import { Home } from '../files/icon';

const paths = ['/customers/details', '/strategies/details'];
const disabledPath = ['Trade', 'Transaction'];

const Main = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [height, setHeight] = useState(document.documentElement.clientHeight);

    const debounceFunc = debounce(() => setHeight(document.documentElement.clientHeight), 500);

    const callback = useCallback(debounceFunc, [debounceFunc]);

    useEffect(() => {
        window.addEventListener('resize', callback);
        return () => {
            window.removeEventListener('resize', callback);
        };
    }, [callback]);

    const breadcrumbs = () => {
        const array = pathname.split('/');

        if (pathname === '/') {
            return;
        }

        return array.map((path, index) => {
            if (path === '') {
                return (
                    <IconButton key={index} onClick={() => navigate('/')} size="small" sx={{ padding: 0, pb: '4px' }}>
                        <Home />
                    </IconButton>
                );
            }
            return (
                <Button
                    key={index}
                    variant="header"
                    sx={{ color: (theme) => theme.palette.primary.main, textTransform: 'capitalize' }}
                    onClick={() => navigate(array.slice(0, index + 1).join('/'))}
                    disabled={index + 1 === array.length || disabledPath.some((key) => key === path)}
                >
                    {path}
                </Button>
            );
        });
    };

    return (
        <>
            <Grid height={`${height}px`}>
                <Grid height="60px" bgcolor="secondary.main" container justifyContent="space-between" px="12px" columnGap="16px">
                    <Header />
                </Grid>
                <Grid pt="3px" px="12px">
                    {!paths.includes(pathname) && <Breadcrumbs aria-label="breadcrumb">{breadcrumbs()}</Breadcrumbs>}
                </Grid>
                <Grid
                    height={`calc(${height}px - 60px - ${pathname === '/' || paths.some((path) => pathname === path) ? 3 : 28}px )`}
                    pt="3px"
                    pb="12px"
                    px="12px"
                    sx={{ overflow: 'auto' }}
                >
                    <Outlet />
                </Grid>
            </Grid>
        </>
    );
};

export default Main;
