import {
    Avatar,
    CircularProgress,
    Grid,
    Menu,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { matchFilters, rupeeFormat, usePagination } from '../components/common';
import Search from '../components/common/Search';
import { getColor } from '../components/Customers/api';
import { getFilters } from '../components/Trade/api';
import StrategyFilter from '../components/Trade/StrategyFilter';
import { useDispatch, useSelector } from 'react-redux';
import BrokerFilter from '../components/Trade/BrokerFilter';
import Button from '../components/common/Button';
import { applyClosedFilter, clearClosedFilter } from '../store/filter';

const Closed = () => {
    const navigate = useNavigate();
    const mode = useSelector((state) => state.reducer.mode);
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.closed);
    const [anhcorEl, setAnchorEl] = useState({ date: null, Strategy: null });
    const { data: allTrades, rest, totalRecords, isLoading, paginationProp } = usePagination('/close-list', getFilters(Filter));

    const setFilter = (data) => {
        dispatch(applyClosedFilter(data));
    };

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                container
                justifyContent="space-between"
                columnGap="10px"
                p="10px"
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Closed({totalRecords})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'closed') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearClosedFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                {mode === 'live' && (
                    <Grid item xs="auto">
                        <Button
                            id="status"
                            aria-haspopup="true"
                            onClick={(e) => setAnchorEl(Boolean(anhcorEl?.broker) ? {} : { date: null, broker: e.currentTarget })}
                            sx={{ py: '1px' }}
                        >
                            Broker
                        </Button>
                        <Menu
                            id="status"
                            open={Boolean(anhcorEl?.broker)}
                            anchorEl={anhcorEl?.broker}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            onClose={() => setAnchorEl({})}
                            PaperProps={{ sx: { width: 'min-content' } }}
                        >
                            <BrokerFilter setAnchorEl={setAnchorEl} setFilter={setFilter} Filter={Filter} />
                        </Menu>
                    </Grid>
                )}
                <Grid item xs="auto">
                    <Button
                        id="Strategy"
                        aria-haspopup="true"
                        onClick={(e) =>
                            setAnchorEl(
                                Boolean(anhcorEl?.Strategy) ? { date: null, Strategy: null } : { date: null, Strategy: e.currentTarget }
                            )
                        }
                        sx={{ py: '1px' }}
                    >
                        Strategy
                    </Button>
                    <Menu
                        id="Strategy"
                        open={Boolean(anhcorEl?.Strategy)}
                        anchorEl={anhcorEl?.Strategy}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({ date: null, Strategy: null })}
                        PaperProps={{ sx: { width: '205px' } }}
                    >
                        <StrategyFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 49px - 46px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{ zIndex: 2 }}>
                                Name
                            </TableCell>
                            <TableCell>Entry</TableCell>
                            <TableCell>Exit</TableCell>
                            <TableCell align="left">Strategy</TableCell>
                            <TableCell align="left">Symbol</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Buy</TableCell>
                            <TableCell>Sell</TableCell>
                            <TableCell>P&L</TableCell>
                            {mode === 'live' && <TableCell align="left">Broker</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allTrades && allTrades?.length > 0 ? (
                            allTrades?.map((order) => (
                                <TableRow key={order.id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: order.user_id, customer_name: order.customer_phone }
                                                })
                                            }
                                        >
                                            {order.customer}
                                        </Button>
                                    </TableCell>
                                    <TableCell sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        <span style={{ display: 'block' }}>{order.date_time?.split(' ')[0]}</span>
                                        <Typography component="span" variant="caption" color="#878C96">
                                            {order.date_time?.split(' ')[1]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        <span style={{ display: 'block' }}>{order.exit_date?.split(' ')[0]}</span>
                                        <Typography component="span" variant="caption" color="#878C96">
                                            {order.exit_date?.split(' ')[1]}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Tooltip title={order.strategy_name}>
                                            <span>{order.strategy_code}</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell align="left" sx={{ paddingBottom: '2px', paddingTop: '2px' }}>
                                        <span style={{ display: 'block' }}>{order.symbol_title}</span>
                                        <Typography component="span" variant="caption" color="#878C96">
                                            {order.symbol_sub_title}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(order.action) }}>{order.action}</TableCell>
                                    <TableCell>{`${order.quantity}(${order.quantity / order.lotsize})`}</TableCell>
                                    <TableCell>{rupeeFormat(order.avg_buy_price)}</TableCell>
                                    <TableCell>{rupeeFormat(order.avg_sell_price)}</TableCell>
                                    <TableCell sx={{ color: getColor(order.profit_loss) }}>{rupeeFormat(order.profit_loss)}</TableCell>
                                    {mode === 'live' && (
                                        <TableCell align="left">
                                            <Avatar
                                                src={order.broker_logo}
                                                variant="square"
                                                sx={{ display: 'block', margin: '0 auto', height: '28px', width: '28px' }}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={11}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container bgcolor="background.default" sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}>
                {paginationProp.count > 1 && (
                    <Grid item xs="auto">
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => setFilter({ current_page: v })} />
                    </Grid>
                )}
                <Grid item xs></Grid>
                <Grid
                    item
                    xs="auto"
                    bgcolor="background.default"
                    sx={{
                        border: (theme) => `1px solid ${theme.palette.primary.main}`,
                        borderRadius: '5px',
                        px: '12px'
                    }}
                >
                    <Typography textAlign="center" py="3px">
                        Realised P/L:
                        <Typography component="span" pl={'12px'} color={getColor(rest?.sum || '0.00')}>
                            {rupeeFormat(rest?.sum || '0.00')}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default Closed;
