import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Dialog, Grid, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Api from '../../../api';
import Button from '../../common/Button';

const EditPayment = ({ data, onClose, mutate }) => {
    const schema = yup.object().shape({
        payment_date: yup.string().required(),
        payment_mode: yup.string().required().nullable(),
        payment_status: yup.string().required().nullable(),
        payment: yup.string().required(),
        due_date: yup.string()
    });
    const { register, control, formState, handleSubmit } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: { ...data }
    });

    const submit = async (formData) => {
        const { payment_date, due_date, payment, ...rest } = formData;
        const response = await Api.post('edit-trading-transaction', {
            ...rest,
            payment: Number(payment).toFixed(2),
            payment_date: payment_date.replaceAll('/', '-'),
            due_date: due_date.replaceAll('/', '-')
        });

        if (response.data.status) {
            mutate();
            toast.success(response.data.message);
            onClose();
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { maxWidth: '400px', minWidth: '400px' } }}>
            <Typography textAlign="center" fontSize="120%">
                Edit
            </Typography>
            <Grid height="78px" px={2}>
                <Typography>Payment Date</Typography>
                <Controller
                    control={control}
                    name={'payment_date'}
                    render={({ field: { onChange, name, value } }) => (
                        <>
                            <DatePicker
                                multiple={false}
                                name={name}
                                value={value}
                                format="DD/MM/YYYY HH:mm:ss"
                                onChange={(date) => {
                                    onChange({ target: { name: 'payment_date', value: date.format() } });
                                }}
                                containerStyle={{ width: '100%', background: 'transparent' }}
                                style={{
                                    width: '100%',
                                    height: '35px',
                                    border: formState?.errors?.payment_date?.message
                                        ? '0.5px solid #EA5455'
                                        : '2px solid rgba(115, 103, 240, 0.5)',
                                    background: 'rgba(115, 103, 240, 0.06)',
                                    color: '#ffffff',
                                    fontFamily: 'Givonic',
                                    fontSize: '100%'
                                }}
                                className="custom-calendar"
                                mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                    const props = { style: { color: '#d0d2d6' } };
                                    if (isSameDate(today, date)) {
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#283046',
                                            border: '2px solid rgba(115, 103, 240, 0.5)',
                                            background: 'rgba(115, 103, 240, 0.06)'
                                        };
                                    }
                                    if (isSameDate(date, selectedDate))
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#7367F0'
                                        };
                                    return props;
                                }}
                                calendarPosition="bottom-center"
                                plugins={[<TimePicker position="right" />]}
                            />
                        </>
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.payment_date?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Due Date</Typography>
                <Controller
                    control={control}
                    name={'due_date'}
                    render={({ field: { onChange, name, value } }) => (
                        <>
                            <DatePicker
                                multiple={false}
                                name={name}
                                value={value}
                                format="DD/MM/YYYY HH:mm:ss"
                                onChange={(date) => {
                                    onChange({ target: { name: name, value: date.format() } });
                                }}
                                containerStyle={{ width: '100%', background: 'transparent' }}
                                style={{
                                    width: '100%',
                                    height: '35px',

                                    border: formState?.errors?.payment_date?.message
                                        ? '0.5px solid #EA5455'
                                        : '2px solid rgba(115, 103, 240, 0.5)',
                                    background: 'rgba(115, 103, 240, 0.06)',
                                    color: '#ffffff',
                                    fontFamily: 'Givonic',
                                    fontSize: '100%'
                                }}
                                className="custom-calendar"
                                mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                    const props = { style: { color: '#d0d2d6' } };
                                    if (isSameDate(today, date)) {
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#283046',
                                            border: '2px solid rgba(115, 103, 240, 0.5)',
                                            background: 'rgba(115, 103, 240, 0.06)'
                                        };
                                    }
                                    if (isSameDate(date, selectedDate))
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#7367F0'
                                        };
                                    return props;
                                }}
                                calendarPosition="bottom-center"
                                plugins={[<TimePicker position="right" />]}
                            />
                        </>
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.due_date?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Payment</Typography>
                <TextField error={Boolean(formState?.errors?.payment?.message)} type="number" {...register('payment')} fullWidth />
                <Typography variant="body2" color="error">
                    {formState?.errors?.payment?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Payment Mode</Typography>
                <Controller
                    control={control}
                    name={'payment_mode'}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField error={Boolean(formState?.errors?.payment_mode?.message)} {...params} fullWidth />
                            )}
                            onChange={(e, v) => onChange(v)}
                            onBlur={onBlur}
                            value={value}
                            options={['Apple Pay', 'UPI', 'Net Banking', 'Debit Card', 'Credit Card', 'Paytm Wallet', 'Bank transfer']}
                        />
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.payment_mode?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Payment Status</Typography>
                <Controller
                    control={control}
                    name={'payment_status'}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField error={Boolean(formState?.errors?.payment_status?.message)} {...params} fullWidth />
                            )}
                            onChange={(e, v) => onChange(v)}
                            onBlur={onBlur}
                            value={value}
                            options={['paid', 'unpaid']}
                        />
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.payment_status?.message}
                </Typography>
            </Grid>
            <Grid container>
                <Button onClick={handleSubmit(submit)} sx={{ width: '40%', margin: '10px auto 0 auto', display: 'block' }}>
                    SAVE
                </Button>
            </Grid>
        </Dialog>
    );
};
export default EditPayment;
