import {
    CircularProgress,
    Grid,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CarryOn1, Export, Paid1, Unpaid1 } from '../../../files/icon';
import Api from '../../../api';
import Details from './Details';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../../common';
import Search from '../../common/Search';
import DateFilter from '../../Trade/DateFilter';
import { getColor } from '../../Customers/api';
import { getFilters } from '../../Trade/api';
import { useDispatch, useSelector } from 'react-redux';
import { applyTradeFilter, clearTradeFilter } from '../../../store/filter';

export const getKey = (key) => {
    if (key === 'paid') {
        return <Paid1 />;
    }
    if (key === 'unpaid') {
        return <Unpaid1 />;
    }
    if (key === 'Carry On') {
        return <CarryOn1 />;
    }
};

const TradeList = () => {
    const navigate = useNavigate();
    const { transaction_trade_edit } = usePermission(['transaction-trade-edit']);
    const dispatch = useDispatch();
    const Filter = useSelector((state) => state.filter.trading);
    const [trade_history_id, setId] = useState(null);
    const [anhcorEl, setAnchorEl] = useState({});
    const { data: tradeList, totalRecords, isLoading, paginationProp, rest } = usePagination('/trade-history-list', getFilters(Filter));

    const onExport = async () => {
        try {
            const response = await Api.post('/trade-history-export', getFilters(Filter), { responseType: 'arraybuffer' });

            const date = new Date();

            const outputFilename = `Tradedata ${date.toLocaleString('en-IN')}.xlsx`;

            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const setFilter = (data) => {
        dispatch(applyTradeFilter(data));
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Typography fontSize={'120%'}>Trade({totalRecords || 0})</Typography>
                <Grid item xs="auto">
                    <Search setFilter={setFilter} filter={Filter?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(Filter, 'trading') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearTradeFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.payment) ? {} : { payment: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Payment
                    </Button>
                    <Menu
                        open={Boolean(anhcorEl?.payment)}
                        anchorEl={anhcorEl?.payment}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '100px' } }}
                    >
                        {['', 'Positive', 'Negative'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.payment === mode}
                                onClick={() => {
                                    setFilter({ payment: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button aria-haspopup="true" onClick={(e) => setFilter({ date_time: 'Last Week' })} sx={{ py: '1px' }}>
                        Last Week
                    </Button>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.date) ? {} : { date: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Date
                    </Button>
                    <Menu
                        open={Boolean(anhcorEl?.date)}
                        anchorEl={anhcorEl?.date}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '400px' } }}
                    >
                        <DateFilter setFilter={setFilter} Filter={Filter} setAnchorEl={setAnchorEl} />
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '100px' } }}
                    >
                        {['', 'paid', 'unpaid', 'carry on'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={Filter?.status === mode}
                                onClick={() => {
                                    setFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'all' : mode}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {transaction_trade_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '1px', minWidth: '15px', px: 0 }} onClick={onExport}>
                            <Export />
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: 'calc(100% - 49px)' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Week</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Carry P/L</TableCell>
                            <TableCell>Current P/L</TableCell>
                            <TableCell>Brokerage</TableCell>
                            <TableCell>Gross P/L</TableCell>
                            <TableCell>Profit Share</TableCell>
                            <TableCell>GST</TableCell>
                            <TableCell>Payment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ marginBottom: '12px' }}>
                        {tradeList && tradeList?.length > 0 ? (
                            tradeList?.map((trade) => (
                                <TableRow key={trade.id}>
                                    <TableCell align="left" sx={{ py: '2px' }}>
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            sx={{ display: 'block' }}
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: trade.user_id, customer_name: trade.customer_phone }
                                                })
                                            }
                                        >
                                            {trade.customer}
                                        </Button>
                                        <Typography variant="caption" color="#878C96">
                                            {trade.expiry_date}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" sx={{ py: '2px' }}>
                                        <Button
                                            sx={{ display: 'block' }}
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() => setId(trade.id)}
                                        >
                                            {trade.week_name}
                                        </Button>
                                        <Typography variant="caption" color="#878C96">
                                            {trade.week_date}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{getKey(trade.payment_status)}</TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.last_week_pnl)) }}>
                                        {rupeeFormat(trade.last_week_pnl)}
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.this_week_pnl)) }}>
                                        {rupeeFormat(trade.this_week_pnl)}
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.brokerage)) }}>
                                        {rupeeFormat(trade.brokerage)}
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.net_profitloss)) }}>
                                        {rupeeFormat(trade.net_profitloss)}
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.profit_share)) }}>
                                        {rupeeFormat(trade.profit_share)}
                                    </TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.gst)) }}>{rupeeFormat(trade.gst)}</TableCell>
                                    <TableCell sx={{ color: getColor(rupeeFormat(trade.payment)) }}>{rupeeFormat(trade.payment)}</TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={10}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={10}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter sx={{ position: 'sticky', bottom: 0, left: 0 }}>
                        <TableRow>
                            <TableCell colSpan={3} sx={{ padding: '0 16px' }}>
                                {paginationProp.count > 1 && (
                                    <Grid
                                        container
                                        justifyContent="flex-start"
                                        bgcolor="background.default"
                                        sx={{ borderRadius: '0 0 5px 5px' }}
                                    >
                                        <Pagination
                                            {...paginationProp}
                                            color="primary"
                                            onChange={(e, v) => setFilter({ current_page: v })}
                                        />
                                    </Grid>
                                )}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '5px 0px 0px 5px',
                                    borderRight: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_last_week_profitloss || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_last_week_profitloss || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_this_week_pnl || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_this_week_pnl || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(
                                        rupeeFormat(rest?.total_brokerage === '0.00' ? '0.00' : `-${rest?.total_brokerage || 0}`)
                                    )
                                }}
                            >
                                {rupeeFormat(rest?.total_brokerage === '0.00' ? '0.00' : `-${rest?.total_brokerage || 0}`)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_gross_profitloss || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_gross_profitloss || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_profit_share || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_profit_share || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRight: 'none',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_gst || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_gst || 0)}
                            </TableCell>
                            <TableCell
                                sx={{
                                    padding: '8px',
                                    background: (theme) => theme.palette.secondary.main,
                                    border: '2px solid rgba(115, 103, 240, 0.5)',
                                    borderRadius: '0px 5px 5px 0px',
                                    borderLeft: 'none',
                                    fontSize: '85%',
                                    color: getColor(rupeeFormat(rest?.total_payment || 0))
                                }}
                            >
                                {rupeeFormat(rest?.total_payment || 0)}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            {trade_history_id && <Details trade_history_id={trade_history_id} setId={setId} />}
        </>
    );
};

export default TradeList;
