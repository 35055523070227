import { combineReducers, configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';
import filter from './filter';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

const persistConfig = {
    key: 'root',
    version: 1,
    storage
};

const combined = combineReducers({ reducer, filter });

const persistedReducer = persistReducer(persistConfig, combined);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});

const persistor = persistStore(store);

export { store, persistor };
