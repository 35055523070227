import axios from 'axios';
import { toast } from 'react-toastify';

import { store } from '../store';
import { logout } from '../store/reducer';

export const baseUrl = window.location.host === 'algosuccess.xyz' ? 'https://www.algosuccess.in/api' : 'http://192.168.0.19:8001/api';
// export const baseUrl = 'https://www.algosuccess.in/api';

const Api = axios.create({
    baseURL: baseUrl
});

Api.interceptors.request.use((req) => {
    const _token = store.getState().reducer.data?.verify_token;
    const mode = store.getState().reducer.mode;
    if (_token) {
        req.headers['token'] = _token;
        req.headers['mode'] = mode;
    }

    return req;
});

Api.interceptors.response.use(
    (response) => {
        return response;
    },
    (err) => {
        if (
            err.response?.data?.message ===
                'You are not authorized to make the request. The authorization credentials provided for the request are invalid.' ||
            err.message === 'bad auth : Authentication failed.'
        ) {
            const promise = new Promise((resolve) => {
                store.dispatch(logout());
                resolve();
            });
            promise.then(() => {
                window.location.replace(
                    window.location.hostname.includes('algosuccess')
                        ? 'https://algosuccess.xyz/1707-coretus/signin'
                        : 'http://192.168.0.37:3000/1707-coretus/signin'
                );
            });
        } else {
            toast.error(err?.message);
            return err;
        }
    }
);

export default Api;

export const fetcher = async (url, data) => {
    try {
        const response = await Api.post(url, data);
        return response.data;
    } catch (error) {
        return error;
    }
};

export const swrFetcher = async (data1) => {
    const [url, data] = typeof data1 === 'string' ? [data1] : data1;

    try {
        const response = await Api.post(url, data);
        return response.data;
    } catch (error) {
        return error;
    }
};
