//react
import useSWR from 'swr';
import AsyncForm from '../common/AsyncForm';
import { fetcher } from '../../api';
import { toast } from 'react-toastify';

//mui
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

//form
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//icon
import { ReactComponent as Eye } from '../../files/icon/Eye.svg';
import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { ExitForm } from '../../files/icon';

const ConnectBrokerDialog = ({ broker, onClose, connectedListMutation, user_id }) => {
    const [brokerID, setBrokerId] = useState(broker?.id || null);
    const { data: brokerList, error: brokerListError } = useSWR(['/broker-list', { user_id }]);
    const { data } = useSWR(brokerID && ['broker-detail-of-normal-brokers', { broker_id: brokerID, user_id }]);
    const [show, setShow] = useState();

    const schema = yup.object().shape({
        ...data?.data?.broker_query_params?.reduce(
            (acc, cv) => ({
                ...acc,
                ...(cv.textfield && {
                    [cv.key_name]: yup.string().when('bh', {
                        is: () => cv.textfield.is_required,
                        then: yup.string().required(`${cv.textfield.field_name} is required`)
                    })
                })
            }),
            {}
        ),
        broker: yup.object().required().nullable()
    });

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control,
        watch,
        setValue
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            ...data?.data?.broker_query_params?.reduce(
                (acc, cv) => ({
                    ...acc,
                    ...(cv.textfield && {
                        [cv.key_name]: cv.key_value
                    })
                }),
                {}
            ),
            ...(typeof broker === 'object' ? { broker: broker } : { broker: {} })
        }
    });

    useEffect(() => {
        if (data) {
            reset(
                data?.data?.broker_query_params?.reduce(
                    (acc, cv) => ({
                        ...acc,
                        ...(cv.textfield && {
                            [cv.key_name]: cv.key_value
                        })
                    }),
                    { broker: watch('broker') }
                )
            );
        }
    }, [data, reset, watch]);

    useEffect(() => {
        if (typeof broker === 'boolean' && brokerList) {
            setValue(
                'broker',
                brokerList?.data?.find((broker) => broker.name === 'Angel One')
            );
            setBrokerId(brokerList?.data?.find((broker) => broker.name === 'Angel One')?.id);
        }
    }, [brokerList, broker, setValue]);

    const connect = async (values) => {
        const { broker, ...rest } = values;
        const response = await fetcher('/user-add-broker', { broker_id: broker.id, ...rest, user_id });

        if (response.status) {
            onClose();
            connectedListMutation();
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(broker)} PaperProps={{ sx: { minWidth: '350px', width: '350px', position: 'relative' } }}>
            <Typography variant="h6" textAlign="center" pb="5px">
                {typeof broker === 'object' ? 'Edit' : 'Add'} Brokers
            </Typography>
            <IconButton onClick={onClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
                <ExitForm />
            </IconButton>
            <AsyncForm onSubmit={handleSubmit(connect)} mt="5px" buttonText="login">
                <Grid minHeight="70px">
                    <Typography variant="body2">Broker</Typography>
                    <Controller
                        control={control}
                        name="broker"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                value={value}
                                onChange={(e, v) => {
                                    onChange(v);
                                    setBrokerId(v.id);
                                }}
                                onBlur={onBlur}
                                options={brokerList?.data?.filter((broker) => !broker.is_o_auth) || []}
                                getOptionLabel={(option) => option.name || ''}
                                getOptionDisabled={(option) => option.is_selected}
                                renderInput={(params) => <TextField {...params} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                loading={!brokerList && !brokerListError}
                                readOnly={typeof broker === 'object'}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.broker?.message}
                    </Typography>
                </Grid>
                {data ? (
                    <>
                        {data?.data?.broker_query_params.map((input) => {
                            if (Boolean(input.textfield)) {
                                return (
                                    <Grid key={input.key_name} height="75px">
                                        <Typography variant="body2">{input.textfield.field_name}</Typography>
                                        <Input
                                            {...register(input.key_name)}
                                            placeholder={input?.textfield?.hint}
                                            type={
                                                input?.textfield?.issecured
                                                    ? show?.name === input.key_name && show?.show
                                                        ? input?.textfield?.inputtype
                                                        : 'password'
                                                    : input?.textfield?.inputtype
                                            }
                                            error={Boolean(errors?.[input.key_name]?.message)}
                                            {...(input.textfield.issecured && {
                                                endAdornment: (
                                                    <IconButton
                                                        onClick={() => setShow((prev) => ({ name: input.key_name, show: !prev?.show }))}
                                                    >
                                                        <Eye />
                                                    </IconButton>
                                                )
                                            })}
                                        />
                                        <Typography variant="body2" color="error.main">
                                            {errors?.[input.key_name]?.message}
                                        </Typography>
                                    </Grid>
                                );
                            } else {
                                return '';
                            }
                        })}
                    </>
                ) : brokerID ? (
                    <Grid container>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Typography textAlign="center">No broker selected</Typography>
                )}
            </AsyncForm>
        </Dialog>
    );
};
export default ConnectBrokerDialog;
