import { Autocomplete, Dialog, Grid, Input, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AsyncForm from '../common/AsyncForm';
import { fetcher } from '../../api';
import { toast } from 'react-toastify';

const PaymentGatewayEdit = ({ data, onClose, mutate }) => {
    const keys = {
        Name: 'payment_gateway_name',
        Code: 'code',
        Credit_Card: 'is_creditcard',
        Debit_Card: 'is_debit_card',
        Net_Banking: 'is_netbanking',
        UPI: 'is_upi',
        Wallet: 'is_wallet',
        Channel_ID_for_website: 'channel_id_for_website',
        Mode: 'payment_gateway_mode',
        Merchant_ID: 'merchant_id',
        Merchant_Key: 'merchant_key',
        Website: 'website',
        Industry_Type: 'industry_type',
        Channel_ID_for_mobile: 'channel_id_for_mobile_app',
        Status: 'status'
    };

    const schema = yup
        .object()
        .shape(
            Object.keys(keys).reduce(
                (acc, cv) => ({ ...acc, [keys[cv]]: yup.string().required(`${cv.replaceAll('_', ' ')} is required`) }),
                {}
            )
        );

    const {
        register,
        formState: { errors },
        handleSubmit,
        control
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: typeof data === 'boolean' ? {} : Object.values(keys).reduce((acc, cv) => ({ ...acc, [cv]: String(data[cv]) }), {})
    });

    const submit = async (send) => {
        const response = await fetcher('/payment-gateway-update', send);

        if (response.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose}>
            <Typography textAlign="center" variant="h6" pb={2}>
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Payment Gateway
            </Typography>
            <AsyncForm onSubmit={handleSubmit(submit)} gridProps={{ container: true, justifyContent: 'space-between', px: 3 }}>
                {Object.keys(keys).map((key) => {
                    if (['Credit_Card', 'Debit_Card', 'Net_Banking', 'UPI', 'Wallet', 'Mode', 'Status'].includes(key)) {
                        return (
                            <Grid item height="78px" xs={5.8} key={key}>
                                <Typography>{key.replaceAll('_', ' ')}</Typography>
                                <Controller
                                    control={control}
                                    name={keys[key]}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Autocomplete
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            onChange={(e, v) => onChange(v)}
                                            onBlur={onBlur}
                                            value={value}
                                            options={key === 'Mode' ? ['Stage', 'Live', ''] : ['true', 'false', '']}
                                        />
                                    )}
                                />
                                <Typography variant="body2" color="error">
                                    {errors?.[keys[key]]?.message}
                                </Typography>
                            </Grid>
                        );
                    } else {
                        return (
                            <Grid item height="78px" xs={5.8} key={key}>
                                <Typography>{key.replaceAll('_', ' ')}</Typography>
                                <Input {...register(keys[key])} disabled={key === 'Code' && typeof data === 'object'} />
                                <Typography variant="body2" color="error">
                                    {errors?.[keys[key]]?.message}
                                </Typography>
                            </Grid>
                        );
                    }
                })}
            </AsyncForm>
        </Dialog>
    );
};
export default PaymentGatewayEdit;
