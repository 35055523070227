import { Autocomplete, Dialog, Grid, Input, TextField, Typography } from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';

const AddPlanDialog = ({ data, onClose, mutate }) => {
    const keys = ['name', 'duration', 'price', 'ios_price', 'profit_share', 'status', 'is_default'];

    const schema = yup.object().shape({
        ...keys.reduce((acc, cv) => ({ ...acc, [cv]: yup.string().required(`${cv.replaceAll('_', ' ')} is required`) }), {}),
        status: yup.boolean().required('status is required')
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: keys.reduce((acc, cv) => ({ ...acc, [cv]: data[cv] }), {})
    });

    const submit = async (input) => {
        const response = await fetcher('/membership-plan-add-update', {
            ...input,
            ...(typeof data === 'object' && { plan_id: data?.id })
        });

        if (response?.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose}>
            <Typography textAlign="center" variant="h6">
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Subscriptions
            </Typography>
            <AsyncForm
                onSubmit={handleSubmit(submit)}
                gridProps={{ container: true, columnSpacing: '25px', px: 3, alignItems: 'flex-start', justifyContent: 'flex-start' }}
            >
                {keys.map((key) => {
                    if (key === 'status' || key === 'is_default') {
                        return (
                            <Grid item xs={6} key={key} minHeight="70px">
                                <Typography textTransform="capitalize">{key.replaceAll('_', ' ')}</Typography>
                                <Controller
                                    control={control}
                                    name={key}
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <Autocomplete
                                            renderInput={(params) => (
                                                <TextField error={Boolean(errors?.[key]?.message)} {...params} fullWidth />
                                            )}
                                            onChange={(e, v) => onChange(v)}
                                            onBlur={onBlur}
                                            getOptionLabel={(option) => String(option)}
                                            value={value}
                                            options={[true, false]}
                                        />
                                    )}
                                />
                                <Typography variant="body2" color="error">
                                    {errors?.[key]?.message}
                                </Typography>
                            </Grid>
                        );
                    } else {
                        return (
                            <Grid item xs={6} key={key} minHeight="70px">
                                <Typography textTransform="capitalize">{key.replaceAll('_', ' ')}</Typography>
                                <Input
                                    {...register(key)}
                                    inputProps={{ step: '0.01' }}
                                    type={['duration', 'price', 'ios_price', 'profit_share'].includes(key) ? 'number' : ' text'}
                                />
                                <Typography variant="body2" color="error">
                                    {errors?.[key]?.message}
                                </Typography>
                            </Grid>
                        );
                    }
                })}
            </AsyncForm>
        </Dialog>
    );
};
export default AddPlanDialog;
