import { applyFilters } from '../components/Customers/api';
import {
    Grid,
    Typography,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    CircularProgress,
    Button,
    Pagination,
    Menu,
    MenuItem
} from '@mui/material';
import { FilterIcon } from '../files/icon';
import { useState } from 'react';
import { getKeyStyles } from '../components/Customers/api';
import { useNavigate } from 'react-router-dom';
import CustomersFilterDialog from '../components/Customers/CustomersFilterDialog';
import { matchFilters, usePagination } from '../components/common';
import Search from '../components/common/Search';
import { useDispatch, useSelector } from 'react-redux';
import { applyCustomersFilter, clearCustomersFilter } from '../store/filter';

const Customers = () => {
    const navigate = useNavigate();
    const [filterDialog, setFilterDialog] = useState(false);
    const filters = useSelector((state) => state.filter.customers);
    const dispatch = useDispatch();
    const [anhcorEl, setAnchorEl] = useState(null);

    const applyFilter = (data) => {
        dispatch(applyCustomersFilter(data));
    };

    const {
        data: customersList,
        totalRecords,
        isLoading,
        isValidating,
        paginationProp
    } = usePagination('/customers-list', applyFilters(filters));
    return (
        <>
            <Grid
                bgcolor="secondary.main"
                container
                justifyContent="space-between"
                columnGap="10px"
                p="10px"
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Customers ({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={applyFilter} filter={filters?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(filters, 'customers') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearCustomersFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.Subscription) ? {} : { Subscription: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Subscription
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.Subscription)}
                        anchorEl={anhcorEl?.Subscription}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '115px' } }}
                    >
                        {['', 'Active', 'Expired', 'Upcoming'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={filters?.subscription === mode}
                                onClick={() => {
                                    dispatch(applyCustomersFilter({ subscription: mode }));
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'all' : mode}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        id="Date/Time"
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.status) ? {} : { status: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Overdue
                    </Button>
                    <Menu
                        id="Date/Time"
                        open={Boolean(anhcorEl?.status)}
                        anchorEl={anhcorEl?.status}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: '100px' } }}
                    >
                        {['', 'Yes', 'No'].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={filters?.user_over_due === mode}
                                onClick={() => {
                                    dispatch(applyCustomersFilter({ user_over_due: mode }));
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'all' : mode}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>

                <Grid item xs="auto" container>
                    {isValidating ? (
                        <CircularProgress size={20} />
                    ) : (
                        <IconButton onClick={() => setFilterDialog(true)}>
                            <FilterIcon />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 49px )` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Registration Date</TableCell>
                            <TableCell>Last Active Date</TableCell>
                            <TableCell>Subscription Expiry</TableCell>
                            <TableCell>Subscription</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customersList?.length > 0 ? (
                            customersList?.map((customer) => (
                                <TableRow key={customer.id}>
                                    <TableCell align="left" sx={{ width: '60px' }}>
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: customer.id, customer_name: customer.phone }
                                                })
                                            }
                                        >
                                            {customer.name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{customer.phone}</TableCell>
                                    <TableCell>{customer.registration_date}</TableCell>
                                    <TableCell>{customer.last_active_time}</TableCell>
                                    <TableCell>{customer.expiry_date}</TableCell>
                                    <TableCell>{getKeyStyles(customer.subscription_status)}</TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    No data found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid container justifyContent="flex-start" sx={{ borderRadius: '0 0 5px 5px', py: '6px' }}>
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => applyFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>

            {filterDialog && (
                <CustomersFilterDialog
                    open={filterDialog}
                    onCLose={() => setFilterDialog(false)}
                    setFilters={applyFilter}
                    filters={filters}
                />
            )}
        </>
    );
};

export default Customers;
