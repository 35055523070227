import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'auth',
    initialState: {},
    reducers: {
        login: (state, action) => ({ ...action.payload, mode: 'live' }),
        logout: (state, action) => null,
        mode: (state, action) => {
            setTimeout(() => {
                window.location.reload(true);
            }, 0);
            return { ...state, mode: state?.mode === 'live' ? 'paper' : 'live' };
        },
        update: (state, action) => {
            return { ...state, ...action.payload };
        }
    }
});

export const { login, logout, mode: changeMode, update } = slice.actions;
export default slice.reducer;
