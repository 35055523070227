import { CircularProgress, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { XAxis, Tooltip, Bar, BarChart } from 'recharts';
// import { Cell, Legend, Pie, PieChart, ResponsiveContainer, XAxis, Tooltip, Bar, BarChart } from 'recharts';
import useSWR from 'swr';
import { ReactComponent as ChartItems } from '../../files/icon/ChartItems.svg';
import { rupeeFormat } from '../common';

// const getPieData = (data) => {
//     const input = { ...data };
//     delete input.message;
//     delete input.status;
//     return Object.entries(input).map((key) => ({ name: key[0], value: key[1] }));
// };

// const colors = ['#3BA3D2', '#EFB119'];

const getLineChartData = (subscription = [], trading = [], prev_data) => {
    return trading
        .map((trade) => trade.date)
        .concat(subscription.map((sub) => sub.date))
        .filter((date, index, arr) => date && arr.indexOf(date) === index)
        .sort((a, b) => {
            const [firstD, firstM, firstY] = a.split('-');
            const [secondD, secondM, secondY] = b.split('-');
            return new Date(firstY, firstM - 1, firstD) - new Date(secondY, secondM - 1, secondD);
        })
        .map((date) => ({
            date: date,
            Trading:
                trading.find((a) => {
                    const [firstD, firstM, firstY] = a.date.split('-');
                    const [secondD, secondM, secondY] = date.split('-');
                    return new Date(firstY, firstM - 1, firstD).toDateString() === new Date(secondY, secondM - 1, secondD).toDateString();
                })?.price || 0,
            Subscription:
                subscription.find((a) => {
                    const [firstD, firstM, firstY] = a.date.split('-');
                    const [secondD, secondM, secondY] = date.split('-');
                    return new Date(firstY, firstM - 1, firstD).toDateString() === new Date(secondY, secondM - 1, secondD).toDateString();
                })?.price || 0
        }))
        .reverse();
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Grid bgcolor="secondary.main" px="10px" pb="7px" pt="7px" borderRadius="5px" boxShadow={5}>
                <Typography variant="body2">{label}</Typography>
                {payload.map((item) => (
                    <Typography variant="body2" color={item.color} key={item.name}>
                        {item.name} :{' '}
                        <Typography variant="body2" component="span" color={item.color}>
                            {rupeeFormat(item.value)}
                        </Typography>
                    </Typography>
                ))}
            </Grid>
        );
    }

    return null;
};

const Revenue = () => {
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    const [Filter, setFilter] = useState({ month: 'Last 30 Days', year: String(new Date().getFullYear()) });
    const { data: chart } = useSWR(['/chart-list', { user_id, year: Filter.year }], { revalidateIfStale: false });
    // const { data } = useSWR('/revenue-pie-chart-data');
    const { data: lineChart, error: lineChartError } = useSWR(['/revenue-line-chart', Filter]);

    const line = lineChart ? getLineChartData(lineChart?.subscriptions_data, lineChart?.trading_data, lineChart?.prev_data) : [];

    return (
        <>
            <Grid item xs="auto">
                <Typography variant="h6">Revenue</Typography>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto"></Grid>
            <Grid item xs={12}>
                {/* <Grid item xs={3}>
                    <Grid bgcolor="background.default" px="10px" borderRadius="5px" height="100%">
                        {data ? (
                            <ResponsiveContainer aspect={1}>
                                <PieChart width={400} height={400}>
                                    <Pie
                                        data={getPieData(data)}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={'80%'}
                                        innerRadius={'60%'}
                                        fill="#8884d8"
                                        label
                                        labelLine
                                        paddingAngle={2}
                                    >
                                        {getPieData(data).map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} stroke="transparent" />
                                        ))}
                                    </Pie>
                                    <Legend align="left" />
                                </PieChart>
                            </ResponsiveContainer>
                        ) : (
                            <CircularProgress />
                        )}
                    </Grid>
                </Grid> */}
                <Grid bgcolor="background.default" px="10px" borderRadius="5px" container alignItems="flex-start" pt="10px" gap="10px">
                    <Grid item xs="auto">
                        <Select
                            variant="standard"
                            value={Filter?.month}
                            onChange={(e, v) => {
                                setFilter((prev) => ({ ...prev, month: e.target.value }));
                            }}
                            fullWidth={false}
                            sx={{ border: 'none', bgcolor: 'secondary.main' }}
                        >
                            {chart ? (
                                chart?.month_data?.concat('All Time')?.map((month) => (
                                    <MenuItem key={month} value={month}>
                                        {month}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value={'Last 30 Days'}>Last 30 Days</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <Select
                            variant="standard"
                            value={Filter?.year}
                            onChange={(e, v) => {
                                setFilter((prev) => ({ ...prev, year: e.target.value }));
                            }}
                            fullWidth={false}
                            sx={{ border: 'none', bgcolor: 'secondary.main' }}
                        >
                            {chart ? (
                                chart?.year_data?.map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value={String(new Date().getFullYear())}>{String(new Date().getFullYear())}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item xs="auto">
                        <Typography variant="body2" color="#28C700">
                            <span style={{ paddingRight: '5px' }}>
                                <ChartItems fill="#28C700" />
                            </span>
                            Trading
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography variant="body2" color="#C77700">
                            <span style={{ paddingRight: '5px' }}>
                                <ChartItems fill="#C77700" />
                            </span>
                            Subscriptions
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {lineChart && line.length > 0 ? (
                            <ScrollContainer vertical={false}>
                                <BarChart data={line} maxBarSize={18} height={200} width={line.length * 40}>
                                    <XAxis
                                        dataKey="date"
                                        tickFormatter={(prev) => {
                                            return prev?.substring(0, 2) || '';
                                        }}
                                        tickSize={4}
                                        tick={{ fontSize: '11px', stroke: '#ffffffcc', strokeWidth: 0.1 }}
                                        tickLine={false}
                                    />
                                    <Tooltip cursor={{ fill: 'rgba(242, 242, 242, 0.1)' }} content={<CustomTooltip />} />
                                    <Bar dot={false} activeDot={false} dataKey="Trading" fill="#28C700" />
                                    <Bar dot={false} activeDot={false} dataKey="Subscription" fill="#C77700" />
                                </BarChart>
                            </ScrollContainer>
                        ) : !lineChart && !lineChartError ? (
                            <Grid container>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Typography textAlign="center" variant="h5">
                                No data
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default Revenue;
