//react
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

//mui
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

//form
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useSWR from 'swr';
import { fetcher } from '../../api';
import AsyncForm from '../common/AsyncForm';
import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { ExitForm } from '../../files/icon';

const ExecuteStrategy = ({ data, onClose, mutate, user_id }) => {
    const mode = useSelector((state) => state.reducer.mode);
    const { data: connectedBroker, error: brokerError } = useSWR(['/connected-broker', { user_id }], { revalidateIfStale: false });
    const { data: strategylist, error } = useSWR(['/get-strategy-list', { user_id }]);
    const [loading, setLoading] = useState(false);
    const schema = yup.object().shape({
        ...(mode === 'live' && {
            tagValue: yup.array().required('Please select broker').min(1, 'Please select broker')
        }),
        lot_size: yup
            .number()
            .typeError('Please enter Lot Multiplier')
            .required()
            .min(1)
            .test('Max lot', 'Lot Multiplier is less than Max. Lotsize', (val, context) => {
                if (context?.parent?.strategy?.max_allowed_lot_size === 0) {
                    return true;
                }
                return val <= context?.parent?.strategy?.max_allowed_lot_size;
            }),
        strategy: yup.object().required('Strategy is required').nullable()
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues:
            typeof data === 'object'
                ? {
                      ...(mode === 'live' && { tagValue: data.broker || [] }),
                      lot_size: data?.lotsize,
                      strategy: data
                  }
                : {}
    });

    const onSave = async (input) => {
        setLoading(true);
        const { tagValue, lot_size, strategy: str } = input;
        const response = await fetcher('/execute-strategy', {
            lot_size: String(Number(lot_size).toFixed(2)),
            trade_type: mode,
            strategy_id: typeof data === 'object' ? data.id : str.id,
            user_id,
            add_or_edit: typeof data === 'object' ? 'edit' : 'add',
            ...(mode === 'live' && { broker_id: tagValue.map((tag) => tag.id).toString() })
        });

        if (response?.status) {
            mutate();
            onClose();
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (strategylist) {
            setValue(
                'strategy',
                strategylist?.data?.find((str) => str.name === data.name)
            );
        }
    }, [strategylist, data?.name, setValue]);

    return (
        <Dialog open={Boolean(data)} PaperProps={{ sx: { minWidth: '350px', width: '350px', position: 'relative' } }}>
            <Typography variant="h6" textAlign="center" pb="10px">
                {typeof data === 'object' ? 'Edit' : 'Add'} Strategies
            </Typography>
            <IconButton onClick={onClose} disabled={loading} sx={{ position: 'absolute', top: 10, right: 10 }}>
                <ExitForm />
            </IconButton>
            <AsyncForm onSubmit={handleSubmit(onSave)} buttonText="Execute" mt="15px">
                <Grid minHeight="70px">
                    <Typography variant="body2">Strategy</Typography>
                    <Controller
                        control={control}
                        name="strategy"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                value={value}
                                onChange={(e, v) => {
                                    onChange(v);
                                }}
                                disabled={typeof data === 'object'}
                                onBlur={onBlur}
                                options={strategylist?.data || []}
                                getOptionLabel={(option) => option.name}
                                getOptionDisabled={(option) => option.is_selected}
                                renderInput={(params) => <TextField {...params} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                loading={!strategylist && !error}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.strategy?.message}
                    </Typography>
                </Grid>
                {mode === 'live' && (
                    <Grid minHeight="75px">
                        <Typography variant="body2">Select Broker Account</Typography>
                        <Controller
                            control={control}
                            name="tagValue"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <Autocomplete
                                    multiple
                                    value={value}
                                    onChange={(e, v) => {
                                        onChange(v);
                                    }}
                                    onBlur={onBlur}
                                    options={connectedBroker?.data?.filter((broker) => broker.status && broker.is_login) || []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                avatar={<Avatar src={option.logo} alt="" />}
                                                label={option.name}
                                                {...getTagProps({ index })}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => <TextField {...params} />}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    loading={!connectedBroker && !brokerError}
                                />
                            )}
                        />
                        <Typography variant="body2" color="error">
                            {errors?.tagValue?.message}
                        </Typography>
                    </Grid>
                )}
                <Grid container justifyContent="space-between">
                    <Grid item xs="auto">
                        <Typography color="text.secondary" variant="body2">
                            Lotsize Multiplier
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography color="text.secondary" variant="body2">
                            Max. Lotsize Multiplier : {watch('strategy')?.max_allowed_lot_size || 0}
                        </Typography>
                    </Grid>
                </Grid>
                <TextField {...register('lot_size')} type="number" fullWidth />
                <Typography variant="body2" color="text.secondary">
                    Total Trade Qty ({watch('lot_size') || 0} x {watch('strategy')?.standard_lot_quantity || 0}) ={' '}
                    {(watch('lot_size') || 0) * (watch('strategy')?.standard_lot_quantity || 0)}
                </Typography>
                <Typography variant="body2" color="error">
                    {errors?.lot_size?.message}
                </Typography>
            </AsyncForm>
        </Dialog>
    );
};
export default ExecuteStrategy;
