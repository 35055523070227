import { Dialog, Grid, Input, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Api from '../../api';
import Button from '../common/Button';

const CloseP0sition = ({ data, onClose, mutate }) => {
    const schema = yup.object().shape({
        ...(!data?.broker && { price: yup.string().required(), datetime: yup.string().required() }),
        qty: yup.string().required()
    });

    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            qty: data?.qty,
            ...(!data?.broker && { datetime: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`, price: data?.price })
        }
    });

    const updateStatus = async (input) => {
        const { datetime, ...rest } = input;
        const response = await Api.post(data?.broker ? 'close-opened-position-broker' : 'close-opened-position', {
            ...rest,
            ...(!data?.broker && { datetime: datetime.replaceAll('/', '-') }),
            trade_book_id: data?.trade_book_id
        });

        if (response.data.status) {
            toast(response.data.message);
            mutate();
            onClose();
        } else {
            toast(response.data.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px', px: '40px' } }}>
            <Typography textAlign="center" variant="h6">
                {data?.broker ? 'Close Broker Position' : 'Close Position'}
            </Typography>
            {!data?.broker && (
                <>
                    <Grid minHeight="80px" mt="10px">
                        <Typography>Date/Time</Typography>
                        <Controller
                            control={control}
                            name={'datetime'}
                            render={({ field: { onChange, name, value } }) => (
                                <>
                                    <DatePicker
                                        multiple={false}
                                        name={name}
                                        value={value}
                                        format="DD/MM/YYYY HH:mm:ss"
                                        onChange={(date) => {
                                            onChange({ target: { name: name, value: date.format() } });
                                        }}
                                        containerStyle={{ width: '100%', background: 'transparent' }}
                                        style={{
                                            width: '100%',
                                            height: '35px',
                                            border: '2px solid rgba(115, 103, 240, 0.5)',
                                            background: 'rgba(115, 103, 240, 0.06)',
                                            color: '#ffffff',
                                            fontFamily: 'Givonic',
                                            fontSize: '100%'
                                        }}
                                        className="custom-calendar"
                                        mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                            const props = { style: { color: '#d0d2d6' } };
                                            if (isSameDate(today, date)) {
                                                props.style = {
                                                    ...props.style,
                                                    backgroundColor: '#283046',
                                                    border: '2px solid rgba(115, 103, 240, 0.5)'
                                                };
                                            }
                                            if (isSameDate(date, selectedDate))
                                                props.style = {
                                                    ...props.style,
                                                    backgroundColor: '#7367F0'
                                                };
                                            return props;
                                        }}
                                        calendarPosition="bottom-center"
                                        plugins={[<TimePicker position="right" />]}
                                    />
                                </>
                            )}
                        />
                        <Typography color="error.main" variant="body2">
                            {errors?.datetime?.message}
                        </Typography>
                    </Grid>
                    <Grid minHeight="80px">
                        <Typography>Price</Typography>
                        <Input type="number" {...register('price')} />
                        <Typography color="error.main" variant="body2">
                            {errors?.price?.message}
                        </Typography>
                    </Grid>
                </>
            )}
            <Grid minHeight="80px">
                <Typography>Qty.</Typography>
                <Input type="number" {...register('qty')} />
                <Typography color="error.main" variant="body2">
                    {errors?.qty?.message}
                </Typography>
            </Grid>
            <Button
                onClick={handleSubmit(updateStatus)}
                sx={{ display: 'block', width: 'min-content', margin: !data?.broker ? '20px auto' : '10px auto 0px' }}
            >
                Close
            </Button>
        </Dialog>
    );
};
export default CloseP0sition;
