import { Autocomplete, Dialog, Grid, Input, TextField, Typography } from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';
import useSWR from 'swr';

const AddTicker = ({ data, onClose, mutate, length }) => {
    const { data: brokerList, error: brokerListError } = useSWR(['/broker-list']);
    const schema = yup.object().shape({
        symbol_name: yup.string().required(),
        symbol_token: yup.string().required(),
        status: yup.boolean().required(),
        order: yup.string().required(),
        exchange: yup.string().required(),
        broker: yup.object().required()
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: typeof data === 'boolean' ? {} : data
    });

    const submit = async (input) => {
        const { broker, ...rest } = input;
        const response = await fetcher('/ticker-info-update', {
            ...rest,
            broker: broker.id
        });

        if (response?.status) {
            toast.success(response.message);
            await mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '350px', px: '40px' } }}>
            <Typography textAlign="center" variant="h6" pb="10px">
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Ticker
            </Typography>
            <AsyncForm onSubmit={handleSubmit(submit)} gridProps={{ container: true, columnSpacing: '25px' }}>
                <Grid item xs={6} minHeight="65px">
                    <Typography variant="body2">Symbol Name</Typography>
                    <Input error={Boolean(errors?.symbol_name?.message)} {...register('symbol_name')} />
                    <Typography color="error" variant="body2">
                        {errors?.symbol_name?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="65px">
                    <Typography variant="body2">Symbol Token</Typography>
                    <Input error={Boolean(errors?.symbol_token?.message)} {...register('symbol_token')} />
                    <Typography color="error" variant="body2">
                        {errors?.symbol_token?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="65px">
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={[true, false]}
                                getOptionLabel={(option) => (option ? 'Active' : 'Deactive')}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.status?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="65px">
                    <Typography variant="body2">order</Typography>
                    <Controller
                        control={control}
                        name="order"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={Array.from(Array(typeof data === 'object' ? length : length + 1).keys()).map((k) => String(k + 1))}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.order?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.order?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="65px">
                    <Typography variant="body2">Exchange</Typography>
                    <Input error={Boolean(errors?.exchange?.message)} {...register('exchange')} />
                    <Typography color="error" variant="body2">
                        {errors?.exchange?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="65px">
                    <Typography variant="body2">Broker</Typography>
                    <Controller
                        control={control}
                        name="broker"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                value={value}
                                onChange={(e, v) => {
                                    onChange(v);
                                }}
                                onBlur={onBlur}
                                options={brokerList?.data || []}
                                getOptionLabel={(option) => option.name || ''}
                                renderInput={(params) => <TextField {...params} error={Boolean(errors?.broker?.message)} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                loading={!brokerList && !brokerListError}
                                readOnly={typeof broker === 'object'}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.broker?.message}
                    </Typography>
                </Grid>
            </AsyncForm>
        </Dialog>
    );
};
export default AddTicker;
