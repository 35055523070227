import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import { forwardRef, useState } from 'react';

const AsyncForm = forwardRef(
    (
        {
            onSubmit = () => {},
            buttonText = 'Save',
            buttonSx = { margin: '0 auto', display: 'block' },
            children,
            buttonProps = { fullWidth: false },
            gridProps,
            mt = 2
        },
        ref
    ) => {
        const [Loading, setLoading] = useState(false);

        return (
            <form
                onSubmit={async (e, v) => {
                    e.preventDefault();
                    setLoading(true);
                    await onSubmit(e, v);
                    setLoading(false);
                }}
                ref={ref}
            >
                <Grid {...gridProps}>{children}</Grid>
                {Loading ? (
                    <Grid container>
                        <Fab color="primary" size="small" sx={{ mt: mt }}>
                            <CircularProgress size={22} />
                        </Fab>
                    </Grid>
                ) : (
                    <Button id="SubmitButton" sx={{ ...buttonSx, mt }} {...buttonProps} type="submit">
                        {buttonText}
                    </Button>
                )}
            </form>
        );
    }
);
export default AsyncForm;
