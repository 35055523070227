import { Autocomplete, Dialog, Grid, TextField, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import useSWR from 'swr';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPlans } from './api';
import Api from '../../api';
import { toast } from 'react-toastify';
import Button from '../common/Button';

const setExpiryDate = (startDate, addDays) => {
    if (startDate && addDays) {
        const [date, time] = startDate.split(' ');
        const [day, month, year] = date.split('/');
        const [hour, minutes, second] = time.split(':');
        const dateObj = new Date(year, month - 1, day, hour, minutes, second);
        dateObj.setDate(dateObj.getDate() + Number(addDays));
        return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
    } else {
        return '';
    }
};

const AddSubscription = ({ data, onClose, mutate }) => {
    const schema = yup.object().shape({
        subscription_type: yup.string().required('Subscription type is required'),
        price: yup.string().required('price is required'),
        payment_mode: yup.string().required('Payment mode is required'),
        payment_date: yup.string().required('payment date is required'),
        start_date: yup.string().required('start date is required'),
        expiry_date: yup.string().required('expiry date is required')
    });
    const { register, control, handleSubmit, formState, setValue, clearErrors, watch } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            price: '',
            payment_date: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
            start_date: data.start_date,
            expiry_date: ''
        }
    });
    const { data: plans, error: planError } = useSWR('/premium-membership-plans', getPlans, { revalidateIfStale: false });

    const submit = async (formdata) => {
        const { payment_date, start_date, expiry_date } = formdata;
        const response = await Api.post('add-subscription', {
            user_id: data.id,
            ...formdata,
            payment_date: payment_date.replaceAll('/', '-'),
            start_date: start_date.replaceAll('/', '-'),
            expiry_date: expiry_date.replaceAll('/', '-')
        });

        if (response.data.status) {
            toast.success(response.data.message);
            onClose();
            mutate();
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose} PaperProps={{ sx: { minWidth: '400px', width: '400px' } }}>
            <Typography textAlign="center" fontSize="120%">
                Add Subscription
            </Typography>
            <Grid height="78px" px={2}>
                <Typography>Payment Date</Typography>
                <Controller
                    control={control}
                    name={'payment_date'}
                    render={({ field: { onChange, name, value } }) => (
                        <>
                            <DatePicker
                                multiple={false}
                                name={name}
                                value={value}
                                format="DD/MM/YYYY HH:mm:ss"
                                onChange={(date) => {
                                    onChange({ target: { name: 'payment_date', value: date.format() } });
                                }}
                                containerStyle={{ width: '100%', background: 'transparent' }}
                                style={{
                                    width: '100%',
                                    height: '35px',
                                    border: formState?.errors?.payment_date?.message
                                        ? '0.5px solid #EA5455'
                                        : '2px solid rgba(115, 103, 240, 0.5)',
                                    background: 'rgba(115, 103, 240, 0.06)',
                                    color: '#ffffff',
                                    fontFamily: 'Givonic',
                                    fontSize: '100%'
                                }}
                                className="custom-calendar"
                                mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                    const props = { style: { color: '#d0d2d6' } };
                                    if (isSameDate(today, date)) {
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#283046',
                                            border: '2px solid rgba(115, 103, 240, 0.5)'
                                        };
                                    }
                                    if (isSameDate(date, selectedDate))
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#7367F0'
                                        };
                                    return props;
                                }}
                                calendarPosition="bottom-center"
                                plugins={[<TimePicker position="right" />]}
                            />
                        </>
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.payment_date?.message}
                </Typography>
            </Grid>

            <Grid height="78px" px={2}>
                <Typography>Subscription Type</Typography>
                <Controller
                    control={control}
                    name={'subscription_type'}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField error={Boolean(formState?.errors?.subscription_type?.message)} {...params} fullWidth />
                            )}
                            onChange={(e, v) => {
                                onChange(v);
                                setValue(
                                    'price',
                                    plans?.data?.filter((plan) => v === plan?.title)[0]?.discounted_price *
                                        Math.floor(plans?.data?.filter((plan) => v === plan?.title)[0]?.duration / 30) || ''
                                );
                                clearErrors('price');
                                setValue(
                                    'expiry_date',
                                    setExpiryDate(watch('start_date'), plans?.data?.filter((plan) => v === plan?.title)[0]?.duration)
                                );
                            }}
                            onBlur={onBlur}
                            value={value}
                            options={plans?.data?.map((plan) => plan?.title) || []}
                            loading={!plans && !planError}
                        />
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.subscription_type?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Price</Typography>
                <TextField error={Boolean(formState?.errors?.price?.message)} type="number" {...register('price')} fullWidth />
                <Typography variant="body2" color="error">
                    {formState?.errors?.price?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Payment Mode</Typography>
                <Controller
                    control={control}
                    name={'payment_mode'}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField error={Boolean(formState?.errors?.payment_mode?.message)} {...params} fullWidth />
                            )}
                            onChange={(e, v) => onChange(v)}
                            onBlur={onBlur}
                            value={value}
                            options={['Apple Pay', 'UPI', 'Net Banking', 'Debit Card', 'Credit Card', 'Paytm Wallet', 'Bank transfer']}
                        />
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.payment_mode?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Start Date</Typography>
                <Controller
                    control={control}
                    name={'start_date'}
                    render={({ field: { onChange, name, value } }) => (
                        <>
                            <DatePicker
                                multiple={false}
                                name={name}
                                value={value}
                                format="DD/MM/YYYY HH:mm:ss"
                                onChange={(date) => {
                                    onChange({ target: { name: 'payment_date', value: date.format() } });
                                    setValue(
                                        'expiry_date',
                                        setExpiryDate(
                                            date.format(),
                                            plans?.data?.filter((plan) => watch('subscription_type') === plan?.title)[0]?.duration
                                        )
                                    );
                                }}
                                containerStyle={{ width: '100%', background: 'transparent' }}
                                style={{
                                    width: '100%',
                                    height: '35px',
                                    border: formState?.errors?.start_date?.message
                                        ? '0.5px solid #EA5455'
                                        : '2px solid rgba(115, 103, 240, 0.5)',
                                    background: 'rgba(115, 103, 240, 0.06)',
                                    color: '#ffffff',
                                    fontFamily: 'Givonic',
                                    fontSize: '100%'
                                }}
                                className="custom-calendar"
                                mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                    const props = { style: { color: '#d0d2d6' } };
                                    if (isSameDate(today, date)) {
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#283046',
                                            border: '2px solid rgba(115, 103, 240, 0.5)'
                                        };
                                    }
                                    if (isSameDate(date, selectedDate))
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#7367F0'
                                        };
                                    return props;
                                }}
                                calendarPosition="bottom-center"
                                plugins={[<TimePicker position="right" />]}
                            />
                        </>
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.start_date?.message}
                </Typography>
            </Grid>
            <Grid height="78px" px={2}>
                <Typography>Expiry Date</Typography>
                <Controller
                    control={control}
                    name={'expiry_date'}
                    render={({ field: { onChange, name, value } }) => (
                        <>
                            <DatePicker
                                multiple={false}
                                name={name}
                                value={value}
                                format="DD/MM/YYYY HH:mm:ss"
                                onChange={(date) => {
                                    onChange({ target: { name: 'payment_date', value: date.format() } });
                                }}
                                containerStyle={{ width: '100%', background: 'transparent' }}
                                style={{
                                    width: '100%',
                                    height: '35px',
                                    border: formState?.errors?.expiry_date?.message
                                        ? '0.5px solid #EA5455'
                                        : '2px solid rgba(115, 103, 240, 0.5)',
                                    background: 'rgba(115, 103, 240, 0.06)',
                                    color: '#ffffff',
                                    fontFamily: 'Givonic',
                                    fontSize: '100%'
                                }}
                                className="custom-calendar"
                                mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                    const props = { style: { color: '#d0d2d6' } };
                                    if (isSameDate(today, date)) {
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#283046',
                                            border: '2px solid rgba(115, 103, 240, 0.5)'
                                        };
                                    }
                                    if (isSameDate(date, selectedDate))
                                        props.style = {
                                            ...props.style,
                                            backgroundColor: '#7367F0'
                                        };
                                    return props;
                                }}
                                calendarPosition="bottom-center"
                                plugins={[<TimePicker position="right" />]}
                            />
                        </>
                    )}
                />
                <Typography variant="body2" color="error">
                    {formState?.errors?.expiry_date?.message}
                </Typography>
            </Grid>
            <Grid container>
                <Button onClick={handleSubmit(submit)} sx={{ width: '40%', margin: '10px auto 0 auto', display: 'block' }}>
                    Add
                </Button>
            </Grid>
        </Dialog>
    );
};
export default AddSubscription;
