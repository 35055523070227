import {
    Autocomplete,
    createFilterOptions,
    Dialog,
    FormControlLabel,
    Grid,
    Input,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { useEffect } from 'react';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.name} ${option.phone}`,
    ignoreAccents: true,
    ignoreCase: true,
    limit: 10,
    trim: true
});

const AddPromocodes = ({ data, onClose, mutate }) => {
    const { data: users } = useSWR('/strategy-users', { revalidateIfStale: false });
    const { data: groups } = useSWR('/all-groups', { revalidateIfStale: false });
    const schema = yup.object().shape(
        {
            name: yup.string().required(),
            code: yup.string().required(),
            schedule_from: yup.string().required(),
            schedule_to: yup.string().required(),
            discount_amount: yup.string().required(),
            is_percentage: yup.string().required(),
            max_discount: yup.string().when('is_percentage', {
                is: (value) => {
                    return value === '%';
                },
                then: yup.string().required()
            }),
            minimum_amount: yup.string().required(),
            type: yup.string(),
            status: yup.boolean().required(),
            groups: yup
                .array()
                .ensure()
                .when('users', {
                    is: (value) => value?.length > 0,
                    then: yup.array(),
                    otherwise: yup.array().required().min(1)
                }),
            users: yup
                .array()
                .ensure()
                .when('groups', {
                    is: (value) => value?.length > 0,
                    then: yup.array(),
                    otherwise: yup.array().required().min(1)
                }),
            is_reusable: yup.boolean().required()
        },
        [['users', 'groups']]
    );

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: typeof data === 'boolean' ? { is_percentage: '%', is_reusable: true } : data
    });

    useEffect(() => {
        if (data?.users?.length > 0 && users) {
            setValue('users', data?.users?.map((id) => users?.user_data?.find((user) => user.id === id)) || []);
        }
    }, [data, setValue, users]);

    const submit = async (input) => {
        const { users, schedule_from, schedule_to, is_percentage, groups, ...rest } = input;
        const response = await fetcher('/add-promocode', {
            ...rest,
            users: users?.map((user) => user.id),
            groups: groups.map((group) => group.id),
            is_percentage: is_percentage === '%' ? true : false,
            schedule_from: schedule_from?.replaceAll('/', '-'),
            schedule_to: schedule_to?.replaceAll('/', '-')
        });

        if (response?.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose}>
            <Typography textAlign="center" variant="h6">
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Promo Code
            </Typography>
            <AsyncForm
                onSubmit={handleSubmit(submit)}
                gridProps={{ container: true, columnSpacing: '25px', px: 3, alignItems: 'flex-start' }}
            >
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Name</Typography>
                    <Input error={Boolean(errors?.name?.message)} {...register('name')} />
                    <Typography color="error" variant="body2">
                        {errors?.name?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Code</Typography>
                    <Input disabled={typeof data === 'object'} error={Boolean(errors?.code?.message)} {...register('code')} />
                    <Typography color="error" variant="body2">
                        {errors?.code?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px" columnGap="10px" container wrap="nowrap" alignItems="flex-start">
                    <Grid item xs>
                        <Typography variant="body2">Discount</Typography>
                        <Input error={Boolean(errors?.discount_amount?.message)} {...register('discount_amount')} type="number" />
                        <Typography color="error" variant="body2">
                            {errors?.discount_amount?.message}
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography variant="body2" color="transparent">
                            dis
                        </Typography>
                        <Controller
                            control={control}
                            name="is_percentage"
                            render={({ field: { onChange, onBlur, name, value } }) => (
                                <Autocomplete
                                    onBlur={onBlur}
                                    onChange={(e, v) => onChange(v)}
                                    options={['%', '₹']}
                                    value={value}
                                    renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.status?.message)} />}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Start Date</Typography>
                    <Controller
                        control={control}
                        name="schedule_from"
                        render={({ field: { onChange, name, value } }) => (
                            <>
                                <DatePicker
                                    multiple={false}
                                    name={name}
                                    value={value}
                                    format="DD/MM/YYYY HH:mm:ss"
                                    onChange={(date) => {
                                        onChange({ target: { name: name, value: date.format() } });
                                    }}
                                    containerStyle={{ width: '100%', background: 'transparent' }}
                                    style={{
                                        width: '100%',
                                        height: '35px',
                                        border: errors?.schedule_from?.message ? '2px solid #EA5455' : '2px solid rgba(115, 103, 240, 0.5)',
                                        background: 'rgba(115, 103, 240, 0.06)',
                                        color: '#ffffff',
                                        fontFamily: 'Givonic',
                                        fontSize: '100%'
                                    }}
                                    className="custom-calendar"
                                    mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                        const props = { style: { color: '#d0d2d6' } };
                                        if (isSameDate(today, date)) {
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#283046',
                                                border: '2px solid rgba(115, 103, 240, 0.5)'
                                            };
                                        }
                                        if (isSameDate(date, selectedDate))
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#7367F0'
                                            };
                                        return props;
                                    }}
                                    calendarPosition="bottom-center"
                                    plugins={[<TimePicker position="right" />]}
                                />
                            </>
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.schedule_from?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    {watch('is_percentage') === '%' && (
                        <>
                            <Typography variant="body2">Max Discount</Typography>
                            <Input error={Boolean(errors?.max_discount?.message)} {...register('max_discount')} type="number" />
                            <Typography color="error" variant="body2">
                                {errors?.max_discount?.message}
                            </Typography>
                        </>
                    )}
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Expiry Date</Typography>
                    <Controller
                        control={control}
                        name="schedule_to"
                        render={({ field: { onChange, name, value } }) => (
                            <>
                                <DatePicker
                                    multiple={false}
                                    name={name}
                                    value={value}
                                    format="DD/MM/YYYY HH:mm:ss"
                                    onChange={(date) => {
                                        onChange({ target: { name: name, value: date.format() } });
                                    }}
                                    containerStyle={{ width: '100%', background: 'transparent' }}
                                    style={{
                                        width: '100%',
                                        height: '35px',
                                        border: errors?.schedule_to?.message ? '2px solid #EA5455' : '2px solid rgba(115, 103, 240, 0.5)',
                                        background: 'rgba(115, 103, 240, 0.06)',
                                        color: '#ffffff',
                                        fontFamily: 'Givonic',
                                        fontSize: '100%'
                                    }}
                                    className="custom-calendar"
                                    mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                        const props = { style: { color: '#d0d2d6' } };
                                        if (isSameDate(today, date)) {
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#283046',
                                                border: '2px solid rgba(115, 103, 240, 0.5)'
                                            };
                                        }
                                        if (isSameDate(date, selectedDate))
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#7367F0'
                                            };
                                        return props;
                                    }}
                                    calendarPosition="bottom-center"
                                    plugins={[<TimePicker position="right" />]}
                                />
                            </>
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.schedule_to?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Min Purchase Amount</Typography>
                    <Input error={Boolean(errors?.minimum_amount?.message)} {...register('minimum_amount')} type="number" />
                    <Typography color="error" variant="body2">
                        {errors?.minimum_amount?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={[true, false]}
                                getOptionLabel={(option) => (option ? 'Active' : 'Deactive')}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.status?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">Reusable</Typography>
                    <Controller
                        control={control}
                        name="is_reusable"
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
                            </RadioGroup>
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.is_reusable?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Type</Typography>
                    <Controller
                        control={control}
                        name="type"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={['trading', 'membership']}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.type?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.type?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Group</Typography>
                    <Controller
                        control={control}
                        name="groups"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                renderInput={(params) => <TextField {...params} fullWidth error={Boolean(errors?.groups?.message)} />}
                                multiple
                                onChange={(e, v) => onChange(v)}
                                onBlur={onBlur}
                                value={value}
                                filterOptions={filterOptions}
                                options={groups?.data || []}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.groups?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Users</Typography>
                    <Controller
                        control={control}
                        name="users"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                renderInput={(params) => <TextField {...params} fullWidth error={Boolean(errors?.users?.message)} />}
                                multiple
                                onChange={(e, v) => onChange(v)}
                                onBlur={onBlur}
                                value={value}
                                filterOptions={filterOptions}
                                options={users?.user_data || []}
                                getOptionLabel={(option) => option.name || ''}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.users?.message}
                    </Typography>
                </Grid>
            </AsyncForm>
        </Dialog>
    );
};
export default AddPromocodes;
