import { Avatar, Badge, Dialog, FormControlLabel, Grid, IconButton, Input, Radio, RadioGroup, Typography } from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';
import { Cross } from '../../../files/icon';

const AddBanner = ({ data, onClose, mutate }) => {
    const schema = yup.object().shape({
        title: yup.string().required(),
        link: yup.string().required(),
        image: yup.mixed().test('image', 'please add image', (value) => {
            return value.length > 0;
        }),
        is_video: yup.boolean().required(),
        status: yup.boolean().required(),
        show_in_dashboard: yup.boolean().required(),
        type: yup.string().required()
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setValue
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: typeof data === 'boolean' ? { type: 'Mobile', is_video: false, show_in_dashboard: true, status: true } : data
    });

    const submit = async (input) => {
        const formData = new FormData();

        Object.keys(input).forEach((key) => {
            if (key === 'image' && input['image'] && typeof input['image'] === 'object' && input['image'].length > 0) {
                formData.append('image', new Blob(input['image']), input['image'][0].name);
            } else {
                formData.append(key, input[key]);
            }
        });

        if (data['id']) {
            formData.append('slider_id', data['id']);
        }
        const response = await fetcher('/slider-add', formData);

        if (response?.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose}>
            <Typography textAlign="center" variant="h6">
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Banner
            </Typography>
            <AsyncForm
                onSubmit={handleSubmit(submit)}
                gridProps={{ container: true, columnSpacing: '25px', px: 3, alignItems: 'flex-start' }}
            >
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Title</Typography>
                    <Input error={Boolean(errors?.title?.message)} {...register('title')} />
                    <Typography color="error" variant="body2">
                        {errors?.title?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Link</Typography>
                    <Input error={Boolean(errors?.link?.message)} {...register('link')} />
                    <Typography color="error" variant="body2">
                        {errors?.link?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Logo</Typography>
                    <Input error={Boolean(errors?.image?.message)} {...register('image')} type="file" sx={{ mb: '7px' }} />
                    {watch('image')?.length > 0 && (
                        <Badge
                            badgeContent={
                                <IconButton sx={{ padding: 0 }} onClick={() => setValue('image', {})}>
                                    <Cross />
                                </IconButton>
                            }
                            componentsProps={{ badge: { sx: { top: 0, right: 0 } } }}
                        >
                            <Avatar
                                src={typeof watch('image') === 'object' ? URL.createObjectURL(new Blob(watch('image'))) : watch('image')}
                                alt="select a image"
                                variant="square"
                                sx={{ height: '100%', width: '200px' }}
                            />
                        </Badge>
                    )}

                    <Typography color="error" variant="body2">
                        {errors?.image?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">Type</Typography>
                    <Controller
                        control={control}
                        name="type"
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                <FormControlLabel value="Mobile" control={<Radio size="small" />} label="Mobile" />
                                <FormControlLabel value="web" control={<Radio size="small" />} label="Website" />
                            </RadioGroup>
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.type?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">Dashboard</Typography>
                    <Controller
                        control={control}
                        name="show_in_dashboard"
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
                            </RadioGroup>
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.show_in_dashboard?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">Video</Typography>
                    <Controller
                        control={control}
                        name="is_video"
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
                            </RadioGroup>
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.is_video?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field }) => (
                            <RadioGroup row {...field}>
                                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
                            </RadioGroup>
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
            </AsyncForm>
        </Dialog>
    );
};
export default AddBanner;
