import { CircularProgress, createTheme, Grid, responsiveFontSizes, TextField } from '@mui/material';
import { Dropdown2, Expand, NoImage } from './icon';

let Custmise = createTheme({
    palette: {
        primary: { main: '#7367f0' },
        secondary: { main: '#283046' },
        bg1: { main: '#343d55' },
        progress: { main: '#d0d2d6' },
        error: { main: '#EA5455' },
        plus: { main: '#28C76F' },
        background: {
            default: '#161d31',
            paper: '#161d31'
        },
        divider: 'rgba(115, 103, 240, 0.4)',
        text: { primary: '#d0d2d6', secondary: '#b4b7bd', disabled: '#676d7d' },
        action: {
            disabledBackground: 'rgba(115, 103, 240, 0.2)'
        }
    },
    shape: {
        borderRadius: 6
    },
    typography: {
        fontSize: 14,
        fontFamily: ['Givonic', '-apple - system', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'sans - serif'].join(',')
    }
});

let Theme = createTheme({
    ...Custmise,
    components: {
        MuiInput: {
            defaultProps: {
                fullWidth: true,
                disableUnderline: true,
                size: 'medium'
            },
            styleOverrides: {
                root: {
                    border: '2px solid rgba(115, 103, 240, 0.5)',
                    background: 'rgba(115, 103, 240, 0.06)',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    borderRadius: '5px',
                    '&.Mui-error': { border: `2px solid ${Custmise.palette.error.main}` }
                    // '&  ::file-selector-button': {
                    //     background: 'rgba(170, 170, 170, 0.2)',
                    //     border: 'none',
                    //     height: '30px',
                    //     color: Custmise.palette.text.primary
                    // },
                    // '& [type="file"]': {
                    //     padding: 0,
                    //     height: '28px'
                    // }
                }
            }
        },
        MuiGrid: {
            defaultProps: {
                justifyContent: 'center',
                alignItems: 'center'
            },
            styleOverrides: {
                root: {
                    '&::-webkit-scrollbar': {
                        width: '7px',
                        borderRadius: '100vmax',
                        margin: '20px 0'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: Custmise.palette.background.default,
                        borderRadius: '100vmax'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '5px',
                        backgroundColor: Custmise.palette.secondary.main,
                        borderRadius: '100vmax'
                    }
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableFocusRipple: true,
                disableTouchRipple: true,
                disableRipple: true,
                fullWidth: true
            },
            styleOverrides: {
                root: {
                    fontFamily: 'Givonic',
                    textTransform: 'none'
                },
                text: {
                    background: Custmise.palette.primary.main,
                    color: Custmise.palette.text.primary,
                    fontWeight: 500,
                    textTransform: 'none',
                    '&:hover': {
                        boxShadow: 'rgba(115, 103, 240, 0.7) 0px 0px 10px 1px',
                        background: Custmise.palette.primary.main,
                        color: Custmise.palette.text.primary
                    }
                },
                half: {
                    border: `1px solid ${Custmise.palette.primary.main}`,
                    color: Custmise.palette.primary.main,
                    background: 'transparent',
                    '&:hover': {
                        boxShadow: 'none',
                        background: 'transparent',
                        color: Custmise.palette.primary.main
                    }
                },
                header: {
                    textTransform: 'none',
                    padding: '0px',
                    minWidth: 'min-content',
                    '&.Mui-disabled': { color: Custmise.palette.primary.main }
                }
            }
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'progress'
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    transition: 'padding-left 0.2s',
                    '&.Mui-selected': {
                        background: Custmise.palette.primary.main,
                        borderRadius: '5px',
                        boxShadow: '0px 0px 10px 1px rgba(115, 103, 240, 0.7)'
                    },
                    '&.Mui-selected:hover': { background: Custmise.palette.primary.main, paddingLeft: '12%' },
                    '&:hover': {
                        paddingLeft: '12%'
                    }
                }
            }
        },
        MuiTableRow: {
            defaultProps: { hover: true },
            styleOverrides: {
                root: {
                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: 'rgba(115,103,240,0.2)'
                    },
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#1F273B'
                    }
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                zIndex: 1
            }
        },
        MuiTableCell: {
            defaultProps: { align: 'center' },
            styleOverrides: {
                body: {
                    borderBottom: 'none',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    color: '#B4B7BD',
                    whiteSpace: 'nowrap'
                },
                head: {
                    background: '#46468A',
                    borderBottom: 'none',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    whiteSpace: 'nowrap'
                },
                footer: {
                    borderBottom: 'none',
                    background: Custmise.palette.background.default,
                    left: 0,
                    bottom: 0.5,
                    zIndex: 2,
                    position: 'sticky'
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    margin: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '7px',
                        height: '7px',
                        background: 'transparent'
                    },
                    '&::-webkit-scrollbar-corner': {
                        backgroundColor: Custmise.palette.background.default
                    },
                    '&::-webkit-scrollbar-track': {
                        borderRadius: '100vmax',
                        backgroundColor: 'tranparent',
                        backgroundClip: 'padding-box',
                        border: '10px solid rgba(0, 0, 0, 0)',
                        height: '80%'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '5px',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        backgroundClip: 'padding-box',
                        border: '1px solid rgba(0, 0, 0, 0)',
                        borderRadius: '100vmax'
                    },
                    overflow: 'auto'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: Custmise.palette.secondary.main,
                    boxShadow: '0px 0px 10px 10px rgba(0, 0, 0, 0.25)',
                    minWidth: '700px',
                    padding: Custmise.spacing(3),
                    '&::-webkit-scrollbar': {
                        width: '7px',
                        borderRadius: '100vmax',
                        margin: '20px 0'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: Custmise.palette.secondary.main,
                        borderRadius: '100vmax'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '5px',
                        backgroundColor: Custmise.palette.background.default,
                        borderRadius: '100vmax'
                    }
                },
                root: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(1px)'
                }
            }
        },
        MuiTextField: {
            defaultProps: { variant: 'standard' }
        },
        MuiAutocomplete: {
            defaultProps: {
                renderInput: (params) => <TextField {...params} fullWidth />,
                size: 'small',
                popupIcon: <Dropdown2 />,
                clearIcon: null,
                loadingText: (
                    <Grid container>
                        <CircularProgress size={20} />
                    </Grid>
                )
            },
            styleOverrides: {
                endAdornment: { right: 7 },
                inputRoot: { paddingRight: '23px !important' }
            }
        },
        MuiAvatar: {
            defaultProps: { children: <NoImage /> },
            styleOverrides: {
                root: {
                    background: 'none'
                }
            }
        },
        MuiBadge: {
            defaultProps: { color: 'error', overlap: 'circular' },
            styleOverrides: {
                badge: {
                    minWidth: '14px',
                    height: '14px',
                    padding: 0
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: { minWidth: '25px', height: '25px' }
            }
        },
        MuiPagination: {
            defaultProps: {
                showFirstButton: true,
                showLastButton: true
            },
            styleOverrides: {
                root: {
                    background: Custmise.palette.secondary.main,
                    padding: '4px',
                    borderRadius: '100vmax'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    fill: 'white'
                }
            }
        },
        MuiBreadcrumbs: {
            defaultProps: {
                separator: '>'
            }
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                TransitionProps: { unmountOnExit: true }
            },
            styleOverrides: {
                root: {
                    background: 'transparent',
                    boxShadow: 'none'
                }
            }
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: <Expand />
            },
            styleOverrides: {
                root: {
                    background: '#46468A',
                    borderRadius: '2px',
                    height: '40px',
                    '& .Mui-expanded': {
                        maxHeight: '40px',
                        alignItems: 'center',
                        minHeight: '40px'
                    }
                },
                content: {
                    margin: '0px'
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: { borderRadius: '2px', padding: '0px' }
            }
        }
    }
});

Theme = responsiveFontSizes(Theme);

export default Theme;
