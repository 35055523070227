import { Grid, Typography, IconButton } from '@mui/material';
import Input from '@mui/material/Input';
import { Algosuccess, Eye, SystematicAlgoTrading } from '../files/icon';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { baseUrl } from '../api';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { login } from '../store/reducer';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AsyncForm from '../components/common/AsyncForm';
import { publicIpv4 } from 'public-ip';

const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup
        .string()
        .required()
        .required('password is required')
        .min(8, 'min 8 character')
        .matches(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
            'Your password must contain at least one Uppercase, Lowercase, Digit & Special Characters.'
        )
});

const SignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit', resolver: yupResolver(schema) });
    const submit = async () => {
        const ip = await publicIpv4();
        const response = await axios.post(`${baseUrl}/admin-login`, { ...watch(), ip });

        if (response.data.status) {
            toast.success(response.data.message);
            dispatch(login(response.data));
            navigate('/');
        } else {
            toast.error(response.data.message);
        }
    };

    useEffect(() => {
        document.getElementById('SubmitButton').focus();
    }, []);

    return (
        <Grid container justifyContent={{ md: 'space-between', xs: 'center' }} height={window.innerHeight} px="1%">
            <Grid item md={0.6} lg={0.4} xl={0.4} sx={{ display: { xs: 'none', md: 'block' } }}>
                <SystematicAlgoTrading />
            </Grid>
            <Grid item md={4.4 - 0.6} lg={4.4 - 0.4} xl={4.5 - 0.4} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
            <Grid
                item
                xs={10}
                sm={6}
                md={3.2}
                xl={3}
                bgcolor="secondary.main"
                sx={{ borderRadius: '5px', py: 5, px: 'max(4% , 50px)', boxShadow: '0px 0px 10px 2px rgba(0, 0, 0, 0.25)' }}
            >
                <Grid container>
                    <Algosuccess width="60%" />
                </Grid>
                <AsyncForm onSubmit={handleSubmit(submit)} buttonText="Login" mt={1}>
                    <Typography pt={3}>Enter Your Email ID</Typography>
                    <Grid height="58px">
                        <Input error={Boolean(errors?.email?.message)} {...register('email')} sx={{ height: '35px' }} />
                        <Typography color="error">{errors?.email?.message}</Typography>
                    </Grid>
                    <Typography>Enter Your Password</Typography>
                    <Grid minHeight="58px">
                        <Input
                            {...register('password')}
                            type={show ? 'text' : 'password'}
                            error={Boolean(errors?.password?.message)}
                            endAdornment={
                                <IconButton
                                    onClick={() => {
                                        setShow((prev) => !prev);
                                    }}
                                >
                                    <Eye />
                                </IconButton>
                            }
                            sx={{ height: '35px' }}
                        />
                        <Typography color="error">{errors?.password?.message}</Typography>
                    </Grid>
                    <Typography textAlign="end" pb={2}>
                        Forgot Password?
                    </Typography>
                </AsyncForm>
            </Grid>
            <Grid item md={4.4} xl={4.5} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>
        </Grid>
    );
};

export default SignIn;
