import {
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWRInfinite from 'swr/infinite';
import Api from '../../api';
import { Sorting } from '../../files/icon';
import { getCustomersRefferedKey, getKeyStyles } from './api';

const Referedusers = () => {
    const {
        state: { user_id }
    } = useLocation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState({});
    const { data, size, setSize, isLoading } = useSWRInfinite(
        (page) => getCustomersRefferedKey(page + 1, { user_id, ...filters }),
        Api.post
    );

    const referedUsers = data && data?.[0].data && [].concat(...data.map((pages) => pages.data.data));

    const loadmore = () => {
        if (size + 1 <= data[data?.length - 1]?.data?.pagination?.total_pages && !isLoadingMore) {
            setSize(size + 1);
        }
    };
    const scrollRef = useBottomScrollListener(() => loadmore(), {
        debounce: 200
    });
    const isLoadingMore = data?.length !== size && !isLoading;

    return (
        <>
            <Grid
                bgcolor="secondary.main"
                gap="12px"
                container
                justifyContent="space-around"
                p={'12px'}
                sx={{ borderRadius: '5px 5px 0 0' }}
            >
                <Grid item xs="auto">
                    <Typography variant="h6">Referral users({data?.[data?.length - 1]?.data?.pagination?.total_items || 0})</Typography>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto" bgcolor="background.default" sx={{ border: '1.5px solid #B4B7BD', borderRadius: '5px' }}>
                    <Typography textAlign="center" color="text.secondary" px="7px" py="5px">
                        Total Referral Earning:{' '}
                        <Typography component="span" color="text.primary">
                            {data?.[data?.length - 1]?.data?.total_user_earning}
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs="auto" bgcolor="background.default" sx={{ border: '1.5px solid #B4B7BD', borderRadius: '5px' }}>
                    <Typography textAlign="center" color="text.secondary" px="7px" py="5px">
                        Total Balance:{' '}
                        <Typography component="span" color="text.primary">
                            {data?.[data?.length - 1]?.data?.total_balance}
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
            <TableContainer ref={scrollRef} sx={{ maxHeight: '240px', mb: '30px' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Button
                                    fullWidth={false}
                                    variant="header"
                                    endIcon={<Sorting />}
                                    onClick={() =>
                                        setFilters((prev) => ({
                                            ...prev,
                                            sort_earning: '',
                                            sort_join_date: '',
                                            sort_name: filters?.sort_name === 'ASC' ? 'DESC' : 'ASC'
                                        }))
                                    }
                                >
                                    Name
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button
                                    variant="header"
                                    endIcon={<Sorting />}
                                    onClick={() =>
                                        setFilters((prev) => ({
                                            ...prev,
                                            sort_earning: '',
                                            sort_join_date: filters?.sort_join_date === 'ASC' ? 'DESC' : 'ASC',
                                            sort_name: ''
                                        }))
                                    }
                                >
                                    Join Date
                                </Button>
                            </TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>
                                <Button
                                    variant="header"
                                    endIcon={<Sorting />}
                                    onClick={() =>
                                        setFilters((prev) => ({
                                            ...prev,
                                            sort_earning: filters?.sort_earning === 'ASC' ? 'DESC' : 'ASC',
                                            sort_join_date: '',
                                            sort_name: ''
                                        }))
                                    }
                                >
                                    Earning
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referedUsers && referedUsers?.length > 0 ? (
                            referedUsers?.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell align="left">
                                        <Button
                                            fullWidth={false}
                                            variant="header"
                                            onClick={() =>
                                                navigate('/customers/details', {
                                                    state: { user_id: user.user_id, customer_name: user.customer_phone }
                                                })
                                            }
                                        >
                                            {user.user_name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{user.joining_date}</TableCell>
                                    <TableCell>{getKeyStyles(user.status)}</TableCell>
                                    <TableCell>{user.total_earning}</TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>No data found</TableCell>
                            </TableRow>
                        )}
                        {isLoadingMore && (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Referedusers;
