import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Typography } from '@mui/material';
import { useMemo } from 'react';
import AsyncForm from '../common/AsyncForm';
import Api from '../../api';
import { toast } from 'react-toastify';

const CompanyEdit = ({ data, onClose, mutate }) => {
    const keys = useMemo(
        () => [
            'company_name',
            'company_city',
            'company_state',
            'company_country',
            'company_cin',
            'company_gstin',
            'company_pan_no',
            'company_tan',
            'company_pincode',
            'company_address'
        ],
        []
    );
    const schema = yup.object().shape({
        ...keys.reduce(
            (acc, key) => ({
                ...acc,
                [key]: yup.string().required(`${key.replaceAll('company_', '').replaceAll('_no', '')} is required`)
            }),
            {}
        ),
        company_tan: yup.string()
    });

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            company_name: data?.legal_name,
            company_city: data?.city,
            company_state: data?.state,
            company_country: data?.country,
            company_cin: data?.CIN,
            company_gstin: data?.GSTIN,
            company_pan_no: data?.PAN,
            company_tan: data?.TAN,
            company_address: data?.address,
            company_pincode: data?.pincode
        }
    });

    const onSubmit = async (data) => {
        const response = await Api.post('/company-info-update', data);

        if (response.data.status) {
            toast.success(response.data.message);
            onClose();
            mutate();
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose}>
            <Typography textAlign="center" variant="h6" pb={2}>
                Edit Company Details
            </Typography>
            <AsyncForm onSubmit={handleSubmit(onSubmit)} gridProps={{ container: true, justifyContent: 'space-between', px: 3 }}>
                {keys.map((key) => {
                    return (
                        <Grid item xs={key === 'company_address' ? 12 : 5.5} minHeight="78px" key={key}>
                            <Typography
                                textTransform={
                                    ['company_cin', 'company_gstin', 'company_tan', 'company_pan_no'].includes(key)
                                        ? 'uppercase'
                                        : 'capitalize'
                                }
                            >
                                {key.replaceAll('company_', '').replaceAll('_no', '')}
                            </Typography>
                            <Input {...register(key)} {...(key === 'company_address' && { multiline: true, minRows: 2 })} />
                            <Typography variant="body2" color="error">
                                {errors?.[key]?.message}
                            </Typography>
                        </Grid>
                    );
                })}
            </AsyncForm>
        </Dialog>
    );
};
export default CompanyEdit;
