import { Breadcrumbs, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import BarChartNew from '../components/common/BarChartNew';
import { CustomList, getStyle } from '../components/Customers/api';
import { Home } from '../files/icon';

const StrategyDetails = () => {
    const user_id = useSelector((state) => state.reducer.data._id);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [age, setAge] = useState({ month: 'Last 30 Days', year: String(new Date().getFullYear()) });
    const { data, error } = useSWR(['/get-strategy-detail', state]);
    const { data: chart, error: chartError } = useSWR(['strategies-detail-pnl-chart', { user_id, ...state, ...age }]);

    return (
        <>
            {!data && !error && (
                <Grid container py={'16px'}>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Breadcrumbs sx={{ pb: '3px' }}>
                        <IconButton onClick={() => navigate('/')} size="small" sx={{ padding: 0, pb: '4px' }}>
                            <Home />
                        </IconButton>
                        <Button
                            variant="header"
                            sx={{ color: (theme) => theme.palette.primary.main }}
                            onClick={() => navigate('/strategies')}
                        >
                            Strategies
                        </Button>
                        <Button variant="header" sx={{ color: (theme) => theme.palette.primary.main }} disabled={true}>
                            {data?.data?.name}
                        </Button>
                    </Breadcrumbs>
                    <Grid
                        bgcolor="secondary.main"
                        mb={'25px'}
                        p={'12px'}
                        sx={{ borderRadius: '5px' }}
                        container
                        justifyContent="space-evenly"
                        boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                    >
                        <Grid item xs={12} pb={'12px'} container justifyContent="space-between">
                            <Typography variant="h6">Statistics - {data?.data?.name}</Typography>
                        </Grid>

                        <Grid item xs={12} md={6} px={'12px'} pb="12px">
                            <BarChartNew
                                data={chart?.data?.chart_data}
                                setMonth={(month) => setAge((prev) => ({ ...prev, month }))}
                                setYear={(year) => setAge((prev) => ({ ...prev, year }))}
                                loading={!chart && !chartError}
                                month={age?.month}
                                year={age?.year}
                            />
                        </Grid>
                        <Grid pr="12px" item container xs columns={3} spacing="12px" pb="12px">
                            <Grid item zeroMinWidth xs={1} sx={{ borderRadius: '5px' }}>
                                <Typography
                                    textAlign="center"
                                    color="text.secondary"
                                    borderRadius="5px"
                                    bgcolor="background.default"
                                    py={1.3}
                                >
                                    Today P&L
                                </Typography>
                                <Typography textAlign="center" sx={getStyle(data?.data?.statestics_data?.today_pnl)}>
                                    {data?.data?.statestics_data?.today_pnl}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                                <Typography
                                    textAlign="center"
                                    color="text.secondary"
                                    borderRadius="5px"
                                    bgcolor="background.default"
                                    py={1.3}
                                >
                                    Previous Day P&L
                                </Typography>
                                <Typography textAlign="center" sx={getStyle(data?.data?.statestics_data?.previous_day_pnl)}>
                                    {data?.data?.statestics_data?.previous_day_pnl}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                                <Typography
                                    textAlign="center"
                                    color="text.secondary"
                                    borderRadius="5px"
                                    bgcolor="background.default"
                                    py={1.3}
                                >
                                    This Week P/L
                                </Typography>
                                <Typography textAlign="center" sx={getStyle(data?.data?.statestics_data?.this_week_pnl)}>
                                    {data?.data?.statestics_data?.this_week_pnl}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                                <Typography
                                    textAlign="center"
                                    color="text.secondary"
                                    borderRadius="5px"
                                    bgcolor="background.default"
                                    py={1.3}
                                >
                                    This Month P/L
                                </Typography>
                                <Typography textAlign="center" sx={getStyle(data?.data?.statestics_data?.this_month_pnl)}>
                                    {data?.data?.statestics_data?.this_month_pnl}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                                <Typography
                                    textAlign="center"
                                    color="text.secondary"
                                    borderRadius="5px"
                                    bgcolor="background.default"
                                    py={1.3}
                                >
                                    Lifetime P/L
                                </Typography>
                                <Typography textAlign="center" sx={getStyle(data?.data?.statestics_data?.life_time_pnl)}>
                                    {data?.data?.statestics_data?.life_time_pnl}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                                <Typography
                                    textAlign="center"
                                    color="text.secondary"
                                    borderRadius="5px"
                                    bgcolor="background.default"
                                    py={1.3}
                                >
                                    ROI (All Time)
                                </Typography>
                                <Typography textAlign="center" sx={getStyle(data?.data?.statestics_data?.all_time_roi)}>
                                    {data?.data?.statestics_data?.all_time_roi}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        bgcolor="secondary.main"
                        my={'25px'}
                        sx={{ borderRadius: '5px' }}
                        container
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                    >
                        <Grid item xs={12} pt={'12px'} px={'12px'} container justifyContent="space-between">
                            <Typography variant="h6">Summary</Typography>
                        </Grid>
                        <CustomList object={data?.data?.performance} columns={3} />
                    </Grid>
                    <Grid
                        bgcolor="secondary.main"
                        mt={'25px'}
                        sx={{ borderRadius: '5px' }}
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                    >
                        <Grid item xs={12} pt={'12px'} px={'12px'} container justifyContent="space-between">
                            <Typography variant="h6">Returns</Typography>
                        </Grid>
                        <CustomList object={data?.data?.returns} columns={3} />
                    </Grid>
                </>
            )}
        </>
    );
};

export default StrategyDetails;
