import { MenuItem } from '@mui/material';
import useSWR from 'swr';

const BrokerFilter = ({ setFilter, Filter, setAnchorEl }) => {
    const { data: brokers } = useSWR('/get-broker-list', { revalidateIfStale: false });

    return brokers ? (
        <>
            {brokers?.data
                ?.filter((broker) => broker.status)
                ?.map((broker) => (
                    <MenuItem
                        divider={broker.name !== 'All'}
                        key={broker.id}
                        value={broker.name}
                        disabled={Filter?.broker_name === broker.name}
                        onClick={() => {
                            setFilter({ broker_name: broker.name });
                            setAnchorEl({});
                        }}
                        sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
                    >
                        {broker.name}
                    </MenuItem>
                ))}
            <MenuItem
                value={''}
                disabled={Filter?.broker_name === ''}
                onClick={() => {
                    setFilter({ broker_name: '' });
                    setAnchorEl({});
                }}
                sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
            >
                All
            </MenuItem>
        </>
    ) : (
        <MenuItem
            value={''}
            disabled={Filter?.broker_name === ''}
            onClick={() => {
                setFilter((prev) => ({ ...prev, broker_name: '' }));
                setAnchorEl({});
            }}
            sx={{ '&.Mui-disabled': { background: '#46468A', opacity: 1 } }}
        >
            All
        </MenuItem>
    );
};
export default BrokerFilter;
