import { CircularProgress, Grid, MenuItem, Select, Typography } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector } from 'react-redux';
import { Bar, BarChart, Cell, Tooltip, XAxis } from 'recharts';
import useSWR from 'swr';
import { barChartData, rupeeFormat } from '.';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <Grid bgcolor="secondary.main" px="10px" pb="7px" pt="7px" borderRadius="5px" boxShadow={5}>
                {payload.map((item) => (
                    <Typography variant="body2" color={item.color} key={item.name}>
                        {rupeeFormat(item.value)}
                    </Typography>
                ))}
            </Grid>
        );
    }

    return null;
};

const BarChartNew = ({
    data,
    width = 500,
    setYear = () => {},
    setMonth = () => {},
    loading = false,
    month = 'Last 30 Days',
    year = String(new Date().getFullYear()),
    bgcolor = 'background.default',
    height = '150px',
    selection = true
}) => {
    const user_id = useSelector((state) => state?.reducer?.data?._id);
    const { data: chart } = useSWR(['/chart-list', { user_id, year }], { revalidateIfStale: false });

    return (
        <Grid sx={{ width: '100%', borderRadius: '7px' }} px={'12px'} pt={'12px'} bgcolor={bgcolor}>
            {selection && (
                <Grid>
                    <Select
                        variant="standard"
                        value={month}
                        onChange={(e, v) => {
                            setMonth(e.target.value);
                        }}
                        fullWidth={false}
                        defaultValue={data?.default_data?.month || 'Last 30 Days'}
                        sx={{ border: 'none', background: '#283046' }}
                    >
                        {chart ? (
                            chart?.month_data?.map((month) => (
                                <MenuItem key={month} value={month}>
                                    {month}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem key={month} value={'Last 30 Days'}>
                                Last 30 Days
                            </MenuItem>
                        )}
                    </Select>
                    <Select
                        variant="standard"
                        value={year}
                        onChange={(e, v) => {
                            setYear(e.target.value);
                        }}
                        fullWidth={false}
                        defaultValue={data?.default_data?.year || '2022'}
                        sx={{ border: 'none', background: '#283046', ml: '16px' }}
                    >
                        {chart ? (
                            chart?.year_data?.map((year) => (
                                <MenuItem key={year} value={year}>
                                    {year}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem key={month} value={String(new Date().getFullYear())}>
                                {String(new Date().getFullYear())}
                            </MenuItem>
                        )}
                    </Select>
                </Grid>
            )}
            <Grid container={data?.length > 0 ? false : true}>
                {data?.length > 0 ? (
                    <ScrollContainer vertical={false}>
                        <BarChart
                            data={barChartData(data)}
                            maxBarSize={14}
                            height={Number(height.replaceAll('px', ''))}
                            width={barChartData(data).length * 30}
                        >
                            <Bar dataKey="value">
                                {barChartData(data).map((entry, index) => (
                                    <Cell fill={entry.color} key={`cell-${index}`} spacing={2} />
                                ))}
                            </Bar>
                            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(242, 242, 242, 0.1)' }} />
                            <XAxis
                                dataKey="date"
                                minTickGap={0}
                                tickSize={4}
                                tick={{ fontSize: '11px', stroke: '#ffffffcc', strokeWidth: 0.1 }}
                                tickLine={false}
                            />
                        </BarChart>
                    </ScrollContainer>
                ) : loading ? (
                    <CircularProgress />
                ) : (
                    <Typography textAlign="center" variant="h3" pb={1}>
                        No Data
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
export default BarChartNew;
