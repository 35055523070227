import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    customers: { search: '' },
    brokers: { status: true },
    strategy: { search: '', status: true },
    orderbook: { search: '', date_time: 'Today', broker_name: '', strategy_name: '', status: '' },
    open: { date_time: '', search: '', broker_name: '', strategy_name: '' },
    tradebook: { date_time: 'This Week', search: '', broker_name: '', strategy_name: '' },
    closed: { search: '', broker_name: '', strategy_name: '' },
    subscription: { search: '', date_time: '' },
    trading: { search: '', status: '', payment: '', date_time: 'This Week' },
    referral: { date_time: '', search: '', status: '', type: '' },
    withdraw: { date_time: '', status: '', search: '' },
    gst: { date_time: '', search: '', status: '', service_type: '' },
    profitloss: { date_time: '', search: '', status: '', type: '' },
    invoice: { search: '' },
    userpnl: { search: '', date_time: '' },
    userlogin: { status: '', broker: '', search: '' },
    moreSubscription: { status: '' },
    users: { search: '' },
    group: { status: '', search: '' },
    promocode: { status: '', search: '' }
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        applyCustomersFilter: (state, action) => ({ ...state, customers: { ...state?.customers, current_page: 1, ...action.payload } }),
        clearCustomersFilter: (state, action) => ({
            ...state,
            customers: { ...initialState.customers, current_page: 1, ...action?.payload }
        }),
        applyBrokersFilter: (state, action) => ({ ...state, brokers: { ...state?.brokers, current_page: 1, ...action.payload } }),
        applyStrategyFilter: (state, action) => ({ ...state, strategy: { ...state?.strategy, current_page: 1, ...action.payload } }),
        clearStrategyFilter: (state, action) => ({ ...state, strategy: initialState.strategy }),
        applyOrderbookFilter: (state, action) => ({ ...state, orderbook: { ...state?.orderbook, current_page: 1, ...action.payload } }),
        clearOrderbookFilter: (state, action) => ({
            ...state,
            orderbook: { ...initialState.orderbook, current_page: 1, ...action?.payload }
        }),
        applyOpenFilter: (state, action) => ({ ...state, open: { ...state?.open, current_page: 1, ...action.payload } }),
        clearOpenFilter: (state, action) => ({ ...state, open: { ...initialState.open, current_page: 1, ...action?.payload } }),
        applyTradebookFilter: (state, action) => ({ ...state, tradebook: { ...state?.tradebook, current_page: 1, ...action.payload } }),
        clearTradebookFilter: (state, action) => ({
            ...state,
            tradebook: { ...initialState.tradebook, current_page: 1, ...action?.payload }
        }),
        applyClosedFilter: (state, action) => ({ ...state, closed: { ...state?.closed, current_page: 1, ...action.payload } }),
        clearClosedFilter: (state, action) => ({ ...state, closed: { ...initialState.closed, current_page: 1, ...action?.payload } }),
        applySubscriptionFilter: (state, action) => ({
            ...state,
            subscription: { ...state?.subscription, current_page: 1, ...action.payload }
        }),
        clearSubscriptionFilter: (state, action) => ({
            ...state,
            subscription: { ...initialState.subscription, current_page: 1, ...action?.payload }
        }),
        applyTradeFilter: (state, action) => ({ ...state, trading: { ...state?.trading, current_page: 1, ...action.payload } }),
        clearTradeFilter: (state, action) => ({ ...state, trading: { ...initialState.trading, current_page: 1, ...action?.payload } }),
        applyReferralFilter: (state, action) => ({ ...state, referral: { ...state?.referral, current_page: 1, ...action.payload } }),
        clearReferralFilter: (state, action) => ({ ...state, referral: { ...initialState.referral, current_page: 1, ...action?.payload } }),
        applyWithdrawFilter: (state, action) => ({ ...state, withdraw: { ...state?.withdraw, current_page: 1, ...action.payload } }),
        clearWithdrawFilter: (state, action) => ({ ...state, withdraw: { ...initialState.withdraw, current_page: 1, ...action?.payload } }),
        applygstFilter: (state, action) => ({ ...state, gst: { ...state?.gst, current_page: 1, ...action.payload } }),
        cleargstFilter: (state, action) => ({ ...state, gst: { ...initialState.gst, current_page: 1, ...action?.payload } }),
        applyProfitlossFilter: (state, action) => ({ ...state, profitloss: { ...state?.profitloss, current_page: 1, ...action.payload } }),
        clearProfitlossFilter: (state, action) => ({
            ...state,
            profitloss: { ...initialState.profitloss, current_page: 1, ...action?.payload }
        }),
        applyInvoiceFilter: (state, action) => ({ ...state, invoice: { ...state?.invoice, current_page: 1, ...action.payload } }),
        clearInvoiceFilter: (state, action) => ({ ...state, invoice: { ...initialState.invoice, current_page: 1, ...action?.payload } }),
        applyUserpnlFilter: (state, action) => ({ ...state, userpnl: { ...state?.userpnl, current_page: 1, ...action.payload } }),
        clearUserpnlFilter: (state, action) => ({ ...state, userpnl: { ...initialState.userpnl, current_page: 1, ...action?.payload } }),
        applyUserloginFilter: (state, action) => ({ ...state, userlogin: { ...state?.userlogin, current_page: 1, ...action.payload } }),
        clearUserloginFilter: (state, action) => ({
            ...state,
            userlogin: { ...initialState.userlogin, current_page: 1, ...action?.payload }
        }),
        applyMoreSubscriptionFilter: (state, action) => ({
            ...state,
            moreSubscription: { ...state?.moreSubscription, ...action.payload }
        }),
        applyUsersFilter: (state, action) => ({ ...state, users: { ...state?.users, current_page: 1, ...action.payload } }),
        clearUsersFilter: (state, action) => ({
            ...state,
            users: { ...initialState.users, current_page: 1, ...action?.payload }
        }),
        applyGroupFilter: (state, action) => ({ ...state, group: { ...state?.group, current_page: 1, ...action.payload } }),
        clearGroupFilter: (state, action) => ({
            ...state,
            group: { ...initialState.group, current_page: 1, ...action?.payload }
        }),
        applyPromocodeFilter: (state, action) => ({ ...state, promocode: { ...state?.promocode, current_page: 1, ...action.payload } }),
        clearPromocodeFilter: (state, action) => ({
            ...state,
            promocode: { ...initialState.promocode, current_page: 1, ...action?.payload }
        }),
        clearAllFilters: () => {
            return initialState;
        }
    }
});

export const {
    applyCustomersFilter,
    clearCustomersFilter,
    applyBrokersFilter,
    applyStrategyFilter,
    clearStrategyFilter,
    applyOrderbookFilter,
    clearOrderbookFilter,
    applyOpenFilter,
    clearOpenFilter,
    applyTradebookFilter,
    clearTradebookFilter,
    applyClosedFilter,
    clearClosedFilter,
    applySubscriptionFilter,
    clearSubscriptionFilter,
    applyTradeFilter,
    clearTradeFilter,
    applyReferralFilter,
    clearReferralFilter,
    applyWithdrawFilter,
    clearWithdrawFilter,
    applygstFilter,
    cleargstFilter,
    applyProfitlossFilter,
    clearProfitlossFilter,
    applyInvoiceFilter,
    clearInvoiceFilter,
    applyUserpnlFilter,
    clearUserpnlFilter,
    applyUserloginFilter,
    clearUserloginFilter,
    applyMoreSubscriptionFilter,
    applyUsersFilter,
    clearUsersFilter,
    applyGroupFilter,
    clearGroupFilter,
    applyPromocodeFilter,
    clearPromocodeFilter,
    clearAllFilters
} = filterSlice.actions;
export default filterSlice.reducer;
