import { Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import useSWR from 'swr';
import BarChart1 from '../components/common/BarChart1';
import { getHorizontalStyle } from '../components/Customers/api';
import { StrategyGroup } from '../files/icon';
import { useNavigate } from 'react-router-dom';
import { rupeeFormat, usePermission } from '../components/common';
import BarChart2 from '../components/common/BarChart2';
import Revenue from '../components/Dashboard/Revenue';
// import Subscriptions from '../components/Dashboard/Subscriptions';
import Trading from '../components/Dashboard/Trading';

const Dashboard = () => {
    const {
        dashboard_top_profitable_strategies,
        dashboard_trading_chart,
        // dashboard_subscription_chart,
        dashboard_daily_pnl_chart,
        dashboard_revenue_chart
    } = usePermission([
        'dashboard-top-profitable-strategies',
        'dashboard-trading-chart',
        'dashboard-subscription-chart',
        'dashboard-daily-pnl-chart',
        'dashboard-revenue-chart'
    ]);
    const { data: strategyData } = useSWR(dashboard_top_profitable_strategies && '/top-profitable-strategies');
    const navigate = useNavigate();

    return (
        <>
            <Grid height="12px"></Grid>
            {dashboard_daily_pnl_chart && (
                <BarChart1
                    url="/daily-pnl-chart-data"
                    title="Daily P/L"
                    theme="#7367F0"
                    theme2="rgba(115, 103, 240, 0.12)"
                    applyToChart={false}
                />
            )}

            {/* {dashboard_subscription_chart && (
                    <Grid item xs={6}>
                        <Grid
                            bgcolor="secondary.main"
                            px="15px"
                            py="10px"
                            boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                            borderRadius="6px"
                            gap="10px"
                        >
                            <Subscriptions />
                        </Grid>
                    </Grid>
                )} */}

            <Grid container spacing="15px" pt="15px">
                {dashboard_revenue_chart && (
                    <Grid item xs={6}>
                        <Grid
                            bgcolor="secondary.main"
                            py="10px"
                            px="15px"
                            boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                            borderRadius="6px"
                        >
                            <Revenue />
                        </Grid>
                    </Grid>
                )}
                {dashboard_trading_chart && (
                    <Grid item xs={6}>
                        <Grid
                            bgcolor="secondary.main"
                            py="10px"
                            px="15px"
                            boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                            borderRadius="6px"
                        >
                            <Trading />
                        </Grid>
                    </Grid>
                )}
            </Grid>

            {dashboard_top_profitable_strategies && (
                <Grid
                    container
                    bgcolor="secondary.main"
                    mt="15px"
                    px="15px"
                    py="12px"
                    boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
                    borderRadius="6px"
                >
                    <Grid item xs="auto">
                        <Typography variant="h6">Top Profitable Strategies</Typography>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item xs="auto">
                        <Button
                            onClick={() => navigate('/strategies')}
                            variant="outlined"
                            sx={{
                                bgcolor: 'background.default',
                                py: '1px',
                                borderRadius: '2px'
                            }}
                        >
                            View All
                        </Button>
                    </Grid>
                    <Grid item xs={12} container spacing="15px" pt="10px" justifyContent="flex-start">
                        {strategyData && strategyData?.data.length > 0 ? (
                            <>
                                {strategyData?.data?.map((strategy) => {
                                    return (
                                        <Grid item xs={6} key={strategy.id}>
                                            <Grid bgcolor="background.default" borderRadius="5px">
                                                <Grid container borderRadius="5px" px="15px" columnGap="10px">
                                                    <Grid item xs="auto">
                                                        <Typography>{strategy.name}</Typography>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Typography textAlign="center">•</Typography>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Typography textAlign="center" variant="body2">
                                                            <span style={{ color: '#808695' }}>Code:</span> {strategy.code}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Typography textAlign="center">•</Typography>
                                                    </Grid>
                                                    <Grid item xs="auto">
                                                        <Typography textAlign="center" color="primary">
                                                            <StrategyGroup width="12" height="12" />
                                                            {'  '}
                                                            {strategy.group}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs></Grid>
                                                    <Grid item xs="auto">
                                                        <Button
                                                            variant="outlined"
                                                            sx={{
                                                                bgcolor: 'secondary.main',
                                                                py: '1px',
                                                                my: '7px',
                                                                borderRadius: '2px'
                                                            }}
                                                            onClick={() =>
                                                                navigate(`/strategies/details`, {
                                                                    state: { strategy_id: strategy.id }
                                                                })
                                                            }
                                                        >
                                                            Know More
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                                <Grid container spacing="10px" px="10px">
                                                    <Grid item md={6.5} lg={7.8}>
                                                        <BarChart2
                                                            selection={false}
                                                            data={strategy.chart_data}
                                                            height={130}
                                                            sx={{ overflow: 'hidden', borderRadius: '5px' }}
                                                            bgcolor="background.secondary"
                                                        />
                                                    </Grid>
                                                    <Grid item md={5.5} lg={4.2} container>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body2"
                                                                pl="7%"
                                                                py="4px"
                                                                bgcolor="secondary.main"
                                                                borderRadius="5px 0 0 5px"
                                                                my="4px"
                                                            >
                                                                Lifetime P&L
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body2"
                                                                py="4px"
                                                                textAlign="center"
                                                                sx={getHorizontalStyle(strategy.lifetime_pnl, 'secondary.main')}
                                                            >
                                                                {rupeeFormat(strategy.lifetime_pnl)}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body2"
                                                                pl="7%"
                                                                py="4px"
                                                                bgcolor="secondary.main"
                                                                borderRadius="5px 0 0 5px"
                                                                my="4px"
                                                            >
                                                                ROI (All Time)
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body2"
                                                                py="4px"
                                                                textAlign="center"
                                                                sx={getHorizontalStyle(strategy.roi, 'secondary.main')}
                                                            >
                                                                {strategy.roi}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body2"
                                                                pl="7%"
                                                                py="4px"
                                                                bgcolor="secondary.main"
                                                                borderRadius="5px 0 0 5px"
                                                                my="4px"
                                                            >
                                                                Min. Capital
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="body2"
                                                                py="4px"
                                                                textAlign="center"
                                                                sx={getHorizontalStyle(strategy.min_margin, 'secondary.main')}
                                                            >
                                                                {rupeeFormat(strategy.min_margin)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </>
                        ) : strategyData?.data.length === 0 ? (
                            'No data'
                        ) : (
                            <Grid container>
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default Dashboard;
