import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useLocation } from 'react-router-dom';
import useSWRInfinite from 'swr/infinite';
import Api, { fetcher } from '../../api';
import { getCustomersStrategyKey, LoadingSwitch } from './api';
import ParformanceModal from './ParformanceModal';
import { rupeeFormat, usePermission } from '../common';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ExecuteStrategy from './ExecuteStrategy';
import { Delete, Edit } from '../../files/icon';
import PlanDelete from '../more/subscription/PlanDelete';

const Strategies = () => {
    const {
        state: { user_id }
    } = useLocation();
    const mode = useSelector((state) => state.reducer.mode);
    const [strategyId, setStrategyId] = useState(null);
    const [strategy, setStrategy] = useState(null);
    const [deleteStrategy, setDeleteStrategy] = useState(null);
    const { data, size, setSize, isLoading, mutate } = useSWRInfinite((page) => getCustomersStrategyKey(page + 1, { user_id }), Api.post);

    const strategyList = data && data?.[0].data && [].concat(...data.map((pages) => pages.data.data));

    const loadmore = () => {
        if (size + 1 <= data[data?.length - 1]?.data?.pagination?.total_pages && !isLoadingMore) {
            setSize(size + 1);
        }
    };

    const { customers_edit, customers_delete } = usePermission(['customers-edit', 'customers-delete']);

    const scrollRef = useBottomScrollListener(() => loadmore(), {
        debounce: 200
    });

    const isLoadingMore = data?.length !== size && !isLoading;

    const updateStatus = async (data) => {
        const response = await fetcher('/user-strategy-update', data);

        if (response.status) {
            await mutate();
            toast.success(response.message);
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Grid boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)">
            <Grid bgcolor="secondary.main" container justifyContent="space-between" p={'12px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Grid item xs="auto">
                    <Typography variant="h6">Strategies({data?.[data?.length - 1]?.data?.pagination?.total_items || 0})</Typography>
                </Grid>
                <Grid item xs></Grid>
                {customers_edit && (
                    <Grid item xs="auto">
                        <Button
                            sx={{ py: '2px' }}
                            onClick={() => {
                                if (data?.[data?.length - 1]?.data?.expired_plan) {
                                    return toast.error('Membership is expired for this user');
                                }
                                if (data?.[data?.length - 1]?.data?.due_payment) {
                                    return toast.error('Due amount for this user');
                                }
                                setStrategy(true);
                            }}
                        >
                            Add
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer ref={scrollRef} sx={{ maxHeight: '240px', mb: '25px' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Code</TableCell>
                            <TableCell>ROI (%)</TableCell>
                            <TableCell>LifetimeP&L</TableCell>
                            <TableCell>Min Capital</TableCell>
                            <TableCell>Qty(Lots)</TableCell>
                            <TableCell>Status</TableCell>
                            {mode === 'live' && <TableCell>Broker</TableCell>}
                            <TableCell>Last Update time</TableCell>
                            {(customers_delete || customers_edit) && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && strategyList?.length > 0 ? (
                            strategyList?.map((strategy) => (
                                <TableRow key={strategy.id}>
                                    <TableCell align="left">
                                        <Button
                                            onClick={() => setStrategyId({ strategy_id: strategy.id, mode: strategy.trade_type })}
                                            fullWidth={false}
                                            variant="header"
                                        >
                                            {strategy?.name}{' '}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="left">{strategy.code}</TableCell>
                                    <TableCell>{strategy.roi}</TableCell>
                                    <TableCell>{rupeeFormat(strategy.lifetime_pnl)}</TableCell>
                                    <TableCell>{rupeeFormat(strategy.min_margin)}</TableCell>
                                    <TableCell>{strategy.qty}</TableCell>
                                    <TableCell>
                                        <LoadingSwitch
                                            checked={strategy.status === 'Active'}
                                            disabled={!customers_edit}
                                            onChange={(e) => {
                                                if (data?.[data?.length - 1]?.data?.expired_plan) {
                                                    return toast.error('Membership is expired for this user');
                                                }
                                                if (data?.[data?.length - 1]?.data?.due_payment) {
                                                    return toast.error('Due amount for this user');
                                                }
                                                updateStatus({
                                                    status: e.target.checked,
                                                    action: 'status',
                                                    strategy_id: strategy.id,
                                                    user_id
                                                });
                                            }}
                                        />
                                    </TableCell>
                                    {mode === 'live' && (
                                        <TableCell>
                                            {strategy?.broker?.map((broker, index) => (
                                                <Tooltip title={broker.name} key={broker.name}>
                                                    <Avatar
                                                        src={broker.logo}
                                                        variant="square"
                                                        sx={{
                                                            display: 'inline-block',
                                                            height: '28px',
                                                            width: '28px',
                                                            ml: index > 0 ? '10px' : '0px'
                                                        }}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </TableCell>
                                    )}
                                    <TableCell>{strategy.last_login}</TableCell>
                                    {(customers_delete || customers_edit) && (
                                        <TableCell>
                                            {customers_edit && (
                                                <IconButton onClick={() => setStrategy(strategy)}>
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            {customers_delete && (
                                                <IconButton
                                                    sx={{ ml: '5px' }}
                                                    onClick={() =>
                                                        setDeleteStrategy({ action: 'delete', strategy_id: strategy.id, user_id })
                                                    }
                                                >
                                                    <Delete />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={10}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={10}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {isLoadingMore && (
                    <Grid container>
                        <CircularProgress />
                    </Grid>
                )}
            </TableContainer>
            {strategyId && <ParformanceModal strategyId={strategyId} setStrategyID={setStrategyId} />}
            {strategy && <ExecuteStrategy data={strategy} onClose={() => setStrategy(null)} mutate={mutate} user_id={user_id} />}
            {deleteStrategy && (
                <PlanDelete
                    data={deleteStrategy}
                    onClose={() => setDeleteStrategy(null)}
                    mutate={mutate}
                    url="/user-strategy-update"
                    name="Strategy"
                />
            )}
        </Grid>
    );
};

export default Strategies;
