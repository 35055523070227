import { CircularProgress, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { getStyle } from './api';
import BarChartNew from '../common/BarChartNew';
import { useState } from 'react';
import { rupeeFormat } from '../common';

const Stastics = () => {
    const {
        state: { user_id }
    } = useLocation();
    const [age, setAge] = useState({});
    const { data, error } = useSWR(['/customers-statistics-detail', { user_id }]);
    const { data: chart, error: chartError } = useSWR(['/all-strategies-pnl-chart', { user_id, ...age }]);
    return (
        <Grid
            bgcolor="secondary.main"
            my={'25px'}
            p={'12px'}
            sx={{ borderRadius: '5px' }}
            container
            justifyContent="space-evenly"
            boxShadow="0px 5px 4px 1px rgba(0, 0, 0, 0.25)"
        >
            <Grid item xs={12} pb={'6px'} container justifyContent="space-between">
                <Typography variant="h6">Statistics</Typography>
            </Grid>
            {!data && !error && !chart && !chartError && (
                <Grid container pb={'16px'}>
                    <CircularProgress />
                </Grid>
            )}
            {data && (
                <>
                    <Grid item xs={12} md={6} px={'12px'} pb="12px">
                        <BarChartNew
                            data={chart?.data?.chart_data}
                            setMonth={(month) => setAge((prev) => ({ ...prev, month }))}
                            setYear={(year) => setAge((prev) => ({ ...prev, year }))}
                            loading={!chart && !chartError}
                            month={age?.month}
                            year={age?.year}
                        />
                    </Grid>
                    <Grid pr="12px" item container xs columns={3} spacing="12px" pb="12px">
                        <Grid item zeroMinWidth xs={1} sx={{ borderRadius: '5px' }}>
                            <Typography textAlign="center" color="text.secondary" borderRadius="5px" bgcolor="background.default" py={1.3}>
                                Today P&L
                            </Typography>
                            <Typography textAlign="center" sx={getStyle(data?.data?.today_pnl || '0.00')}>
                                {rupeeFormat(data?.data?.today_pnl || '0.00')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                            <Typography textAlign="center" color="text.secondary" borderRadius="5px" bgcolor="background.default" py={1.3}>
                                Previous Day P&L
                            </Typography>
                            <Typography textAlign="center" sx={getStyle(data?.data?.yesterday_pnl || '0.00')}>
                                {rupeeFormat(data?.data?.yesterday_pnl || '0.00')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                            <Typography textAlign="center" color="text.secondary" borderRadius="5px" bgcolor="background.default" py={1.3}>
                                This Week P/L
                            </Typography>
                            <Typography textAlign="center" sx={getStyle(data?.data?.this_week_pnl || '0.00')}>
                                {rupeeFormat(data?.data?.this_week_pnl || '0.00')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                            <Typography textAlign="center" color="text.secondary" borderRadius="5px" bgcolor="background.default" py={1.3}>
                                This Month P/L
                            </Typography>
                            <Typography textAlign="center" sx={getStyle(data?.data?.this_month_pnl || '0.00')}>
                                {rupeeFormat(data?.data?.this_month_pnl || '0.00')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                            <Typography textAlign="center" color="text.secondary" borderRadius="5px" bgcolor="background.default" py={1.3}>
                                Lifetime P/L
                            </Typography>
                            <Typography textAlign="center" sx={getStyle(data?.data?.lifetime_pnl || '0.00')}>
                                {rupeeFormat(data?.data?.lifetime_pnl || '0.00')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ borderRadius: '5px' }}>
                            <Typography textAlign="center" color="text.secondary" borderRadius="5px" bgcolor="background.default" py={1.3}>
                                ROI (All Time)
                            </Typography>
                            <Typography textAlign="center" sx={getStyle(data?.data?.roi_all_time || '0.00')}>
                                {rupeeFormat(data?.data?.roi_all_time || '0.00')}%
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default Stastics;
