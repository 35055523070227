import { Autocomplete, createFilterOptions, Dialog, Grid, Input, TextField, Typography } from '@mui/material';
import AsyncForm from '../../common/AsyncForm';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { fetcher } from '../../../api';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { getPlans } from '../../Customers/api';
import DatePicker from 'react-multi-date-picker';
import { useEffect } from 'react';

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.name} ${option.phone}`,
    ignoreAccents: true,
    ignoreCase: true,
    limit: 10,
    trim: true
});

const cityFilterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.cityName}`,
    ignoreAccents: true,
    ignoreCase: true,
    limit: 100,
    trim: true
});

const AddGroup = ({ data, onClose, mutate }) => {
    const { data: statelist, error: statelistError } = useSWR('/states-list', {
        revalidateOnFocus: false,
        revalidateIfStale: false
    });
    const { data: plans } = useSWR('/premium-membership-plans', getPlans, { revalidateIfStale: false });
    const { data: users } = useSWR('/strategy-users', { revalidateIfStale: false });
    const { data: citylist, error: citylistError } = useSWR('/city', {
        revalidateOnFocus: false,
        revalidateIfStale: false
    });
    const schema = yup.object().shape({
        name: yup.string().required(),
        trade_mode: yup.array().required(),
        last_login_before: yup.array(),
        new_users_from: yup.array(),
        membership: yup.array().required(),
        users: yup.array().required(),
        states: yup.array().required(),
        cities: yup.array().required(),
        objective: yup.string().required(),
        status: yup.string().required()
    });

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues:
            typeof data === 'boolean'
                ? {
                      users: [],
                      states: [],
                      cities: [],
                      membership: [],
                      trade_mode: []
                  }
                : {
                      name: data?.name,
                      objective: data?.objective,
                      status: data?.status,
                      membership: data?.membership,
                      trade_mode: data?.trade_mode,
                      new_users_from: data?.new_users_from,
                      last_login_before: data?.last_login_before,
                      users: [],
                      states: [],
                      cities: []
                  }
    });

    useEffect(() => {
        if (data?.users?.length > 0 && users) {
            setValue(
                'users',
                data?.['users'].map((id) => users?.user_data?.find((user) => user.id === id))
            );
        }
    }, [data, setValue, users]);

    useEffect(() => {
        if (data?.states?.length > 0 && statelist) {
            setValue(
                'states',
                data?.['states'].map((id) => statelist?.data?.find((state) => state.id === id))
            );
        }
    }, [data, setValue, statelist]);

    useEffect(() => {
        if (data?.cities?.length > 0 && citylist) {
            setValue(
                'cities',
                data?.['cities'].map((id) => citylist?.city?.find((city) => city.cityId === id))
            );
        }
    }, [data, setValue, citylist]);

    const states = watch('states');

    useEffect(() => {
        if (watch('states')) {
            setValue(
                'cities',
                watch('cities').filter((city) =>
                    watch('states')
                        .map((state) => state.id)
                        .includes(city.state_id)
                )
            );
        }
    }, [states, setValue, watch]);

    const submit = async (input) => {
        const { cities, last_login_before, new_users_from, states, users, membership, trade_mode, ...rest } = input;
        const response = await fetcher('/add-group', {
            cities: cities?.map((key) => key.cityId)?.join(),
            states: states?.map((key) => key.id)?.join(),
            membership: membership?.join(),
            trade_mode: trade_mode?.join(),
            users: users?.map((key) => key.id)?.join(),
            last_login_before: last_login_before?.map((date) => date?.replaceAll('/', '-')),
            new_users_from: new_users_from?.map((date) => date?.replaceAll('/', '-')),
            ...rest,
            ...(typeof data === 'object' && { group_id: data?.id })
        });

        if (response?.status) {
            toast.success(response.message);
            mutate();
            onClose();
        } else {
            toast.error(response.message);
        }
    };

    return (
        <Dialog open={Boolean(data)} onClose={onClose}>
            <Typography textAlign="center" variant="h6">
                {typeof data === 'boolean' ? 'Add' : 'Edit'} Group
            </Typography>
            <AsyncForm
                onSubmit={handleSubmit(submit)}
                gridProps={{ container: true, columnSpacing: '25px', px: 3, alignItems: 'flex-start' }}
            >
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Name</Typography>
                    <Input error={Boolean(errors?.name?.message)} {...register('name')} />
                    <Typography color="error" variant="body2">
                        {errors?.name?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Trade Mode</Typography>
                    <Controller
                        control={control}
                        name="trade_mode"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                multiple
                                onChange={(e, v) => onChange(v)}
                                options={['live', 'paper']}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.trade_mode?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.trade_mode?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Last Login Before</Typography>
                    <Controller
                        control={control}
                        name="last_login_before"
                        render={({ field: { onChange, name, value } }) => (
                            <>
                                <DatePicker
                                    range
                                    name={name}
                                    value={value}
                                    format="DD/MM/YYYY"
                                    onChange={(date) => {
                                        onChange({ target: { name: name, value: date.map((date) => date.format()) } });
                                    }}
                                    containerStyle={{ width: '100%', background: 'transparent' }}
                                    style={{
                                        width: '100%',
                                        height: '35px',
                                        border: errors?.last_login_before?.message
                                            ? '2px solid #EA5455'
                                            : '2px solid rgba(115, 103, 240, 0.5)',
                                        background: 'rgba(115, 103, 240, 0.06)',
                                        color: '#ffffff',
                                        fontFamily: 'Givonic',
                                        fontSize: '100%'
                                    }}
                                    className="custom-calendar"
                                    mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                        const props = { style: { color: '#d0d2d6' } };
                                        if (isSameDate(today, date)) {
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#283046',
                                                border: '2px solid rgba(115, 103, 240, 0.5)'
                                            };
                                        }
                                        if (isSameDate(date, selectedDate))
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#7367F0'
                                            };
                                        return props;
                                    }}
                                    calendarPosition="bottom-center"
                                />
                            </>
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.last_login_before?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">New Users From</Typography>
                    <Controller
                        control={control}
                        name="new_users_from"
                        render={({ field: { onChange, name, value } }) => (
                            <>
                                <DatePicker
                                    range
                                    name={name}
                                    value={value}
                                    format="DD/MM/YYYY"
                                    onChange={(date) => {
                                        onChange({ target: { name: name, value: date.map((date) => date.format()) } });
                                    }}
                                    containerStyle={{ width: '100%', background: 'transparent' }}
                                    style={{
                                        width: '100%',
                                        height: '35px',
                                        border: errors?.new_users_from?.message
                                            ? '2px solid #EA5455'
                                            : '2px solid rgba(115, 103, 240, 0.5)',
                                        background: 'rgba(115, 103, 240, 0.06)',
                                        color: '#ffffff',
                                        fontFamily: 'Givonic',
                                        fontSize: '100%'
                                    }}
                                    className="custom-calendar"
                                    mapDays={({ date, selectedDate, currentMonth, isSameDate, today }) => {
                                        const props = { style: { color: '#d0d2d6' } };
                                        if (isSameDate(today, date)) {
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#283046',
                                                border: '2px solid rgba(115, 103, 240, 0.5)'
                                            };
                                        }
                                        if (isSameDate(date, selectedDate))
                                            props.style = {
                                                ...props.style,
                                                backgroundColor: '#7367F0'
                                            };
                                        return props;
                                    }}
                                    calendarPosition="bottom-center"
                                />
                            </>
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.new_users_from?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Subscription</Typography>
                    <Controller
                        control={control}
                        name="membership"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                multiple
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={plans?.data?.map((plan) => plan?.title).concat('Free') || []}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.membership?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.membership?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Users</Typography>
                    <Controller
                        control={control}
                        name="users"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Autocomplete
                                renderInput={(params) => <TextField {...params} fullWidth error={Boolean(errors?.users?.message)} />}
                                multiple
                                onChange={(e, v) => onChange(v)}
                                onBlur={onBlur}
                                value={value}
                                filterOptions={filterOptions}
                                options={users?.user_data || []}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.users?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography textTransform="capitalize" variant="body2" color="text.secondary">
                        State
                    </Typography>
                    <Controller
                        control={control}
                        name="states"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                multiple
                                onChange={(e, v) => onChange(v)}
                                options={statelist?.data || []}
                                value={value}
                                getOptionLabel={(option) => option.state || ''}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.states?.message)} />}
                                loading={!statelist || !statelistError}
                                loadingText={<Typography>Loading...</Typography>}
                                isOptionEqualToValue={(option, value) => option.id === value.id || value === ''}
                                clearIcon={null}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.states?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography textTransform="capitalize" variant="body2" color="text.secondary">
                        City
                    </Typography>
                    <Controller
                        control={control}
                        name="cities"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                clearIcon={null}
                                filterOptions={cityFilterOptions}
                                loading={!citylist && !citylistError}
                                onBlur={onBlur}
                                multiple
                                onChange={(e, v) => onChange(v)}
                                options={
                                    citylist?.city?.filter((city) =>
                                        watch('states')
                                            .map((state) => state.id)
                                            .includes(city.state_id)
                                    ) || []
                                }
                                value={value}
                                getOptionLabel={(option) => option.cityName}
                                renderInput={(params) => <TextField {...params} error={Boolean(errors?.cities?.message)} />}
                                isOptionEqualToValue={(option, value) => option.cityId === value.cityId || value === ''}
                            />
                        )}
                    />
                    <Typography variant="body2" color="error">
                        {errors?.cities?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Objective</Typography>
                    <Input error={Boolean(errors?.objective?.message)} {...register('objective')} />
                    <Typography color="error" variant="body2">
                        {errors?.objective?.message}
                    </Typography>
                </Grid>
                <Grid item xs={6} minHeight="70px">
                    <Typography variant="body2">Status</Typography>
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, onBlur, name, value } }) => (
                            <Autocomplete
                                onBlur={onBlur}
                                onChange={(e, v) => onChange(v)}
                                options={['Active', 'Deactivate']}
                                value={value}
                                renderInput={(params) => <TextField {...params} name={name} error={Boolean(errors?.status?.message)} />}
                            />
                        )}
                    />
                    <Typography color="error" variant="body2">
                        {errors?.status?.message}
                    </Typography>
                </Grid>
            </AsyncForm>
        </Dialog>
    );
};
export default AddGroup;
