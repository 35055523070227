import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getKeyStyles } from '../components/Customers/api';
import StrategyAddDialog from '../components/strategies/StrategyAddDialog';
import { Edit, EyeFilled } from '../files/icon';
import { matchFilters, rupeeFormat, usePagination, usePermission } from '../components/common';
import Search from '../components/common/Search';
import { applyStrategyFilter, clearCustomersFilter, clearStrategyFilter } from '../store/filter';
import { useDispatch, useSelector } from 'react-redux';

const Strategies = () => {
    const navigate = useNavigate();
    const [strategyDetail, setStrategyDetail] = useState(null);
    const filters = useSelector((state) => state.filter.strategy);
    const dispatch = useDispatch();
    const [anhcorEl, setAnchorEl] = useState({});
    const { data, totalRecords, isLoading, paginationProp, mutate } = usePagination('/get-strategy', filters);
    const { strategies_edit } = usePermission(['strategies-edit']);

    const applyFilter = (data) => {
        dispatch(applyStrategyFilter(data));
    };

    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="12px" p={'10px'} sx={{ borderRadius: '5px 5px 0 0' }}>
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Strategies({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs="auto">
                    <Search setFilter={applyFilter} filter={filters?.search} />
                </Grid>
                <Grid item xs="auto">
                    {!matchFilters(filters, 'strategy') && (
                        <Button sx={{ py: '1px' }} onClick={() => dispatch(clearStrategyFilter())}>
                            Reset
                        </Button>
                    )}
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.payment) ? {} : { payment: e.currentTarget })}
                        sx={{ py: '1px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        open={Boolean(anhcorEl?.payment)}
                        anchorEl={anhcorEl?.payment}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        {[true, false, ''].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={filters?.status === mode}
                                onClick={() => {
                                    applyFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode ? 'Active' : 'Deactive'}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {strategies_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setStrategyDetail(true)}>
                            Add Strategy
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 52px - ${paginationProp.count > 1 ? 42 : 0}px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Code</TableCell>
                            <TableCell>Strategy type</TableCell>
                            <TableCell>Start day</TableCell>
                            <TableCell>Start time</TableCell>
                            <TableCell>Subscribers</TableCell>
                            <TableCell>ROI (%)</TableCell>
                            <TableCell>Lifetime P&L</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.length > 0 ? (
                            data?.map((strategy, index) => (
                                <TableRow key={strategy.id}>
                                    <TableCell align="left">{strategy.name}</TableCell>
                                    <TableCell align="left">{strategy.code}</TableCell>
                                    <TableCell>{strategy.strategy_type}</TableCell>
                                    <TableCell>{strategy.start_day}</TableCell>
                                    <TableCell>{strategy.start_time}</TableCell>
                                    <TableCell>
                                        <span
                                            onClick={() => {
                                                dispatch(clearCustomersFilter({ user_strategy: strategy.name }));
                                                navigate('/customers');
                                            }}
                                            style={{ cursor: 'pointer', width: '15px', display: 'inline-block' }}
                                        >
                                            {strategy.subscribers}
                                        </span>
                                    </TableCell>
                                    <TableCell>{strategy.roi}</TableCell>
                                    <TableCell>{rupeeFormat(strategy.life_time_pnl)}</TableCell>
                                    <TableCell>{getKeyStyles(strategy.status)}</TableCell>
                                    <TableCell>
                                        {strategies_edit && (
                                            <IconButton onClick={() => setStrategyDetail(strategy.id)}>
                                                <Edit />
                                            </IconButton>
                                        )}
                                        <Button
                                            fullWidth={false}
                                            onClick={() => navigate('/strategies/details', { state: { strategy_id: strategy.id } })}
                                            variant="header"
                                            sx={{ ml: '10px' }}
                                        >
                                            <EyeFilled />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={11}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid
                        container
                        justifyContent="flex-start"
                        bgcolor="background.default"
                        sx={{ borderRadius: '0 0 5px 5px', pt: '12px' }}
                    >
                        <Pagination
                            {...paginationProp}
                            color="primary"
                            onChange={(e, v) => applyFilter((prev) => ({ ...prev, current_page: v }))}
                        />
                    </Grid>
                )}
            </TableContainer>
            {strategyDetail && <StrategyAddDialog strategyDetail={strategyDetail} setStrategyDetail={setStrategyDetail} mutate={mutate} />}
        </>
    );
};

export default Strategies;
