import isEqual from 'lodash.isequal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { initialState } from '../../store/filter';

export const rupeeFormat = (number) => {
    return isNaN(number)
        ? number
        : Number(number)?.toLocaleString('en-IN', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
          });
};

export const usePagination = (url, filter) => {
    const [total_pages, setTotalPages] = useState(1);
    const { data, error, mutate, isValidating } = useSWR([url, { current_page: 1, item_per_page: 50, ...filter }]);

    const rest = data;

    useEffect(() => {
        if (data) {
            setTotalPages(data?.pagination?.total_pages);
        }
    }, [data]);

    return {
        data: data?.data,
        totalRecords: data?.pagination?.total_items || 0,
        paginationProp: { page: data?.pagination?.current_page || filter?.current_page || 1, count: total_pages },
        rest,
        isLoading: !data && !error,
        mutate,
        isValidating
    };
};

export const barChartData = (data = []) => {
    const chartData = data?.map((bar) => ({ date: bar[0], value: Math.abs(bar[1]), color: bar[1] > 0 ? '#49D617' : '#EA5455' }));

    return chartData.reverse();
};

export const usePermission = (input = []) => {
    const permission = useSelector((state) => state?.reducer?.data?.permissions);
    return input.reduce((acc, cv) => ({ ...acc, [cv.replaceAll('-', '_')]: permission?.map((pre) => pre.code).includes(cv) }), {});
};

export const matchFilters = (filter, key) => {
    const { current_page, item_per_page, ...rest } = filter;
    return isEqual(rest, initialState[key]);
};
