import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BrokersDialog from '../components/Brokers/BrokersDialog';
import { usePagination, usePermission } from '../components/common';
import { getKeyStyles } from '../components/Customers/api';
import { Edit } from '../files/icon';
import { applyBrokersFilter } from '../store/filter';

const Brokers = () => {
    const [broker, setBroker] = useState(null);
    const filters = useSelector((state) => state.filter.brokers);
    const dispatch = useDispatch();
    const [anhcorEl, setAnchorEl] = useState({});
    const { broker_edit } = usePermission(['broker-edit']);
    const { data, totalRecords, isLoading, paginationProp, mutate } = usePagination('/broker-listing', filters);

    const applyFilter = (data) => {
        dispatch(applyBrokersFilter(data));
    };
    return (
        <>
            <Grid bgcolor="secondary.main" container columnGap="10px" p="10px" sx={{ borderRadius: '5px 5px 0 0' }}>
                <Grid item xs="auto">
                    <Typography fontSize={'120%'}>Brokers({totalRecords || 0})</Typography>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs="auto">
                    <Button
                        aria-haspopup="true"
                        onClick={(e) => setAnchorEl(Boolean(anhcorEl?.payment) ? {} : { payment: e.currentTarget })}
                        sx={{ py: '2px' }}
                    >
                        Status
                    </Button>
                    <Menu
                        open={Boolean(anhcorEl?.payment)}
                        anchorEl={anhcorEl?.payment}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        onClose={() => setAnchorEl({})}
                        PaperProps={{ sx: { width: 'min-content' } }}
                    >
                        {[true, false, ''].map((mode) => (
                            <MenuItem
                                key={mode}
                                disabled={filters?.status === mode}
                                onClick={() => {
                                    applyFilter({ status: mode });
                                    setAnchorEl({});
                                }}
                                sx={{
                                    py: '8px',
                                    borderRadius: '0px',
                                    textTransform: 'capitalize',
                                    '&.Mui-disabled': { background: '#46468A', color: 'white', opacity: 1 }
                                }}
                            >
                                {mode === '' ? 'All' : mode ? 'Active' : 'Deactive'}
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
                {broker_edit && (
                    <Grid item xs="auto">
                        <Button sx={{ py: '2px' }} onClick={() => setBroker({ add: true })}>
                            Add Broker
                        </Button>
                    </Grid>
                )}
            </Grid>
            <TableContainer sx={{ height: `calc(100% - 49px - ${paginationProp.count > 1 ? 42 : 0}px)` }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ zIndex: 2 }}>Broker Logo</TableCell>
                            <TableCell>Brokerage</TableCell>
                            <TableCell>Broker Name</TableCell>
                            <TableCell>oauth</TableCell>
                            <TableCell>Broker Status</TableCell>
                            {broker_edit && <TableCell>Action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.length > 0 ? (
                            data?.map((broker) => (
                                <TableRow key={broker.id}>
                                    <TableCell>
                                        <Avatar src={broker.image} sx={{ display: 'block', margin: '0 auto' }} />
                                    </TableCell>
                                    <TableCell>{broker.brokerage}</TableCell>
                                    <TableCell>{broker.name}</TableCell>
                                    <TableCell>{broker.o_auth ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>{getKeyStyles(broker.status)}</TableCell>
                                    {broker_edit && (
                                        <TableCell>
                                            <IconButton onClick={() => setBroker(broker)}>
                                                <Edit />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                        ) : isLoading ? (
                            <TableRow>
                                <TableCell colSpan={9}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow>
                                <TableCell colSpan={9}>No data found</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                {paginationProp.count > 1 && (
                    <Grid container bgcolor="secondary.main" py="7px" sx={{ borderRadius: '0 0 5px 5px' }}>
                        <Pagination {...paginationProp} color="primary" onChange={(e, v) => applyFilter({ current_page: v })} />
                    </Grid>
                )}
            </TableContainer>

            {broker && <BrokersDialog broker={broker} setBroker={setBroker} mutate={mutate} />}
        </>
    );
};

export default Brokers;
